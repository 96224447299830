const oauthStateKey = 'oauth_state';

export const saveStateBeforeOauth = (context, linkToken) => {
  const state = {
    linkToken,
    path: window.location.pathname,
  };

  if (context.project) {
    state.project = context.project;
  }
  if (context.milestone) {
    state.milestone = context.milestone;
  }

  localStorage.setItem(oauthStateKey, JSON.stringify(state));
};

export const getStateAfterOauth = () => {
  const item = localStorage.getItem(oauthStateKey);
  localStorage.removeItem(oauthStateKey);
  return JSON.parse(item);
};
