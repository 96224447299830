import { loadStripe } from '@stripe/stripe-js';
import { env, keys } from '../constants';

const fetch = require('node-fetch');
const CryptoJS = require('crypto-js');

const URL = 'https://api.aloa.co';
const apiKey = 'k8Hr3dqsvS2hRMhO0OqHAzLcNY6XKPq3';

const BASE_ENDPOINT = env.stripeEndpoint;

const newCustomer = customerData =>
  new Promise((resolve, reject) => {
    makeRequest('/customers/new', 'POST', {}, customerData)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const getCustomer = customerId =>
  new Promise((resolve, reject) => {
    makeRequest('/customers', 'GET', { customerId }, {})
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const addPlaidSource = (customerId, accountId, plaidToken) =>
  new Promise((resolve, reject) => {
    const bodyParams = {
      accountId,
      plaidToken,
    };

    makeRequest(
      `/customers/${customerId}/addPlaidSource`,
      'POST',
      {},
      bodyParams
    )
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const addSource = (customerId, sourceId) =>
  new Promise((resolve, reject) => {
    const bodyParams = {
      sourceId,
    };

    makeRequest(`/customers/${customerId}/addSource`, 'POST', {}, bodyParams)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const verifySource = (customerId, sourceId, amounts) =>
  new Promise((resolve, reject) => {
    const bodyParams = {
      sourceId,
      amounts,
    };

    makeRequest(`/customers/${customerId}/verifySource`, 'POST', {}, bodyParams)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const removeSource = (customerId, sourceId) =>
  new Promise((resolve, reject) => {
    const bodyParams = {
      sourceId,
    };

    makeRequest(`/customers/${customerId}/removeSource`, 'POST', {}, bodyParams)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const newPayment = (customer, source, amount, currency, description) => {
  const chargeWithIntent = source.includes('pm_');

  if (!chargeWithIntent) {
    return newCharge(customer, source, amount, currency, description);
  }

  return newChargeWithPaymentIntent(
    customer,
    source,
    amount,
    currency,
    ['sepa_debit'],
    description
  );
};

const newCharge = (customer, source, amount, currency, description) =>
  new Promise((resolve, reject) => {
    const urlParams = [customer, source];

    if (amount > 0) {
      const bodyParams = {
        amount,
        currency,
        description,
      };

      makeRequest('/charges/new', 'POST', urlParams, bodyParams)
        .then(response => resolve(response))
        .catch(error => reject(error));
    } else {
      resolve({ message: 'Amount is 0 - no charge created' });
    }
  });

const newChargeWithPaymentIntent = async (
  customer,
  paymentMethodId,
  amount,
  currency,
  paymentMethodTypes,
  description
) => {
  const stripe = await loadStripe(keys.stripe);

  if (amount > 0) {
    const urlParams = [customer];
    const bodyParams = {
      amount,
      currency,
      description,
      paymentMethodTypes,
    };

    const paymentIntent = await makeRequest(
      '/intents/new',
      'POST',
      urlParams,
      bodyParams
    );

    const payment = await stripe.confirmSepaDebitPayment(
      paymentIntent.client_secret,
      {
        payment_method: paymentMethodId,
      }
    );

    return payment;
  }

  return { message: 'Amount is 0 - no charge created' };
};

const getSetupIntent = (customer, paymentMethodTypes) =>
  new Promise((resolve, reject) => {
    const urlParams = [customer];

    const bodyParams = {
      paymentMethodTypes,
    };

    makeRequest('/intents/setup', 'POST', urlParams, bodyParams)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const getPaymentMethod = async paymentMethodId =>
  makeRequest(`/paymentMethod/${paymentMethodId}`, 'GET', [], {});

/**
 * Method to add a SEPA payment method to a customer
 * @param {string} customer customerId adding the source
 * @param {{iban: object, accountholderName: string, email: string}} sepaInput input data to create payment method
 * @returns Payment method
 */
const addSepaSource = async (stripeInstance, customer, sepaInput) => {
  try {
    const setupIntent = await getSetupIntent(customer, ['sepa_debit']);

    const setupResult = await stripeInstance.confirmSepaDebitSetup(
      setupIntent.client_secret,
      {
        payment_method: {
          sepa_debit: sepaInput.iban,
          billing_details: {
            name: sepaInput.accountholderName,
            email: sepaInput.email,
          },
        },
      }
    );

    return getPaymentMethod(setupResult.setupIntent.payment_method);
  } catch (error) {
    throw error;
  }
};

const makeRequest = (endPoint, method, urlParams, bodyParams) =>
  new Promise((resolve, reject) => {
    const fetchParams = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        'Request-Nonce': 'true',
      },
    };

    fetch(`${URL + BASE_ENDPOINT}/token`, fetchParams)
      .then(response => response.json())
      .then(responseJson => {
        const { token } = responseJson;
        const { date } = responseJson;

        if (token) {
          let url = `${URL + BASE_ENDPOINT}/stripe${endPoint}`;

          if (urlParams.length > 0) {
            urlParams.forEach(param => {
              url = url.concat(`/${param}`);
            });
          }

          const signature = CryptoJS.AES.encrypt(
            JSON.stringify(bodyParams),
            apiKey + token.toString()
          );

          const fetchParams = {
            method,
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
              Token: token,
              Signature: signature.toString(),
              Date: date,
            },
          };

          if (method !== 'GET') {
            fetchParams.body = JSON.stringify(bodyParams);
          }

          fetch(url, fetchParams)
            .then(response => response.json())
            .then(responseJson => {
              handleStripeResponse(responseJson)
                .then(respone => resolve(respone))
                .catch(error => reject(error));
            })
            .catch(error => {
              reject(error);
            });
        } else {
          reject(Error('Error receiving token'));
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const handleStripeResponse = response =>
  new Promise((resolve, reject) => {
    if (!response.statusCode || response.statusCode === 200) {
      resolve(response);
    } else {
      console.error(`Error in stripe ${response.stack}`);
      reject(response);
    }
  });

export default {
  newCustomer,
  getCustomer,
  addPlaidSource,
  addSource,
  removeSource,
  newPayment,
  verifySource,
  addSepaSource,
};
