import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'antd';
import { Card } from '../Objects';
import { showUseCredits } from '../../actions/appDialog';
import logo from '../../images/logo2.png';
import { currencyFormat } from '../../utils';
import './ProjectPage.scss';

export default function AloaCreditCard({ currentUser, project }) {
  const dispatch = useDispatch();

  const handleUseAloaCredits = () => {
    dispatch(showUseCredits({ project }));
  };

  return (
    <Card>
      <Title>Your Aloa Credits</Title>
      <CreditCard>
        <LogoImage src={logo} />
        {currencyFormat(currentUser.credits, 2)}
      </CreditCard>
      <CreditText>
        <Button
          className="save-button"
          style={{ marginLeft: '-10px' }}
          onClick={handleUseAloaCredits}
        >
          Pay with Credits
        </Button>
      </CreditText>
      <ClearDiv />
    </Card>
  );
}

const Title = styled.div`
  font-family: Avenir Next;
  font-size: 17px;
  color: #232323;
  font-weight: 500;
`;

const CreditCard = styled.div`
  max-width: 100%;
  color: #000000;
  font-weight: 500;
  padding: 20px;
  margin-top: 15px;
  border: 0.5px solid;
  border-color: #e6e6e6;
  border-radius: 5px;
`;

const LogoImage = styled.img`
  vertical-align: bottom;
  height: 25px;
  margin-right: 7px;
  border-radius: 10%;
`;

const CreditText = styled.div`
  margin-top: 10px;
`;

const ClearDiv = styled.div`
  clear: both;
`;
