import React, { useState, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import VerifyForm from './VerifyForm';
import ccImg from '../../images/cc.svg';
import bankImg from '../../images/bank.svg';

const { confirm } = Modal;

// TODO (Done) - a ton of style bulking up this file - move to scss
export default function PaymentCard({
  method,
  verifySource,
  deletePaymentMethod,
  editPaymentMethods,
}) {
  const [showDelete, setShowDelete] = useState(editPaymentMethods);

  useEffect(() => {
    if (editPaymentMethods !== showDelete) {
      setShowDelete(editPaymentMethods);
    }
  }, [editPaymentMethods]);

  const showConfirm = paymentMethod => {
    const brand =
      paymentMethod.type === 'ach'
        ? paymentMethod.bank_inst
        : paymentMethod.brand;
    confirm({
      title: `Do you want to delete this payment method?`,
      content: `${brand} •••• ${paymentMethod.last4}`,
      onOk() {
        deletePaymentMethod(paymentMethod)
          .then(() => message.success('Deleted payment method'))
          .catch(error => message.error(error.message));
      },
      onCancel() {},
    });
  };

  return (
    <div
      className="payment-card"
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false || editPaymentMethods)}
    >
      {method.type === 'card' && <img className="cc-img" src={ccImg} />}

      {method.type === 'ach' && <img className="bank-img" src={bankImg} />}

      {method.type === 'card' && (
        <div className="card-method">
          {method.brand}
          {` ••••`}
          {method.last4}
        </div>
      )}

      {method.type === 'ach' && (
        <div className="ach">
          {method.bank_inst}
          {` ••••`}
          {method.last4}
        </div>
      )}

      {showDelete && (
        <DeleteOutlined className="payment-card-delete-icon" onClick={() => showConfirm(method)} />
      )}

      {method.type === 'ach' && method.verified === false && (
        <>
          <div
            className="ach-pending-verification"
            cursor="pointer"
            onClick={() => console.log('Verify')}
          >
            Pending Verification
          </div>

          <VerifyForm
            submit={charges => {
              verifySource(method.source_id, charges)
                .then(result => {
                  if (result.type === 'StripeInvalidRequestError') {
                    message.error(result.message);
                  } else {
                    message.success('Bank successfully verified!');
                  }
                })
                .catch(error => {
                  message.error(error);
                });
            }}
          />
        </>
      )}
    </div>
  );
}
