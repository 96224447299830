/**
 * Function to set loading in store to true
 */
export const beginLoading = () => async dispatch => {
  dispatch({
    type: 'BEGIN_LOADING',
    loading: true,
  });
};

/**
 * Function to set loading in store to false
 */
export const endLoading = () => async dispatch => {
  dispatch({
    type: 'END_LOADING',
    loading: false,
  });
};
