import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Avatar, Menu, Drawer } from 'antd';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Toggle, { On, Off, featureTypes } from '../FeatureToggle';
import logo from '../../images/aloa_small_line.png';
import hamburger from '../../images/hamburger.svg';
import './TopBar.scss';

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

function TopBar({ currentUser }) {
  const name = currentUser ? currentUser.name : false;
  let initials = name ? name.match(/\b\w/g) || [] : [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

  const [visible, setVisible] = useState(false);

  return (
    <div>
      {currentUser && currentUser.devEnabled && (
        <div className="development-mode-label">Development Mode</div>
      )}
      <AppBar className="appbar">
        <Toolbar>
          <MediaQuery minWidth={701}>
            {currentUser ? (
              <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                  <a href={`${process.env.REACT_APP_MANAGE_URL}`}>
                    <img
                      src={logo}
                      alt="Logo"
                      height="28"
                      vspace="13"
                      hspace="14"
                    />
                  </a>
                </div>

                <div style={{ display: 'inline-block', marginLeft: '50px' }}>
                  <a
                    className="app-link"
                    href={process.env.REACT_APP_MANAGE_URL}
                  >
                    Manage
                  </a>
                </div>
                <div style={{ display: 'inline-block', marginLeft: '25px' }}>
                  <Link
                    style={{
                      color: '#2d89f6',
                      fontSize: '16px',
                      fontWeight: '500',
                      fontFamily: 'Avenir Next',
                    }}
                    className="app-link"
                    to="/"
                  >
                    Pay
                  </Link>
                </div>
              </div>
            ) : (
              <Link to="/login">Login</Link>
            )}
          </MediaQuery>

          <MediaQuery maxWidth={700}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <div
                style={{ display: 'inline-block' }}
                onClick={() => setVisible(true)}
              >
                <img style={{ width: '20px' }} src={hamburger} />
              </div>
              <Drawer
                title="Menu"
                placement="left"
                closable
                onClose={() => setVisible(false)}
                open={visible}
              >
                <Menu mode="inline" defaultSelectedKeys={['pay']}>
                  <Menu.Item key="manage">
                    <a href={`${process.env.REACT_APP_MANAGE_URL}`}>Manage</a>
                  </Menu.Item>
                  <Menu.Item key="pay">
                    <Link onClick={() => setVisible(false)} to="/">
                      Pay
                    </Link>
                  </Menu.Item>
                </Menu>
              </Drawer>

              <div style={{ display: 'inline-block' }}>
                <a href={process.env.REACT_APP_MANAGE_URL}>
                  <img
                    src={logo}
                    alt="Logo"
                    height="28"
                    vspace="13"
                    hspace="14"
                  />
                </a>
              </div>
            </div>
          </MediaQuery>
          <div className="account-section">
            <MediaQuery minWidth={700}>
              <div className="user-text-section">
                <Toggle feature={featureTypes.SSO} user={currentUser}>
                  <On>
                    <a
                      className="user-name"
                      href={process.env.REACT_APP_ACCOUNTS_URL}
                    >
                      {currentUser.name}
                    </a>
                  </On>
                  <Off>
                    <a className="user-name" href="/account">
                      {currentUser.name}
                    </a>
                  </Off>
                </Toggle>
                <div className="user-company">{currentUser.company}</div>
              </div>
            </MediaQuery>

            <div className="user-image-section">
              <Toggle feature={featureTypes.SSO} user={currentUser}>
                <On>
                  <a href={process.env.REACT_APP_ACCOUNTS_URL}>
                    {currentUser.image && currentUser.image !== '' ? (
                      <img className="user-image" src={currentUser.image} />
                    ) : (
                      <Avatar className="user-initials">{initials}</Avatar>
                    )}
                  </a>
                </On>
                <Off>
                  <Link to="/account">
                    {currentUser.image && currentUser.image !== '' ? (
                      <img className="user-image" src={currentUser.image} />
                    ) : (
                      <Avatar className="user-initials">{initials}</Avatar>
                    )}
                  </Link>
                </Off>
              </Toggle>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default connect(
  mapStateToProps,
  null
)(TopBar);
