import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, message, Modal } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MilestoneCard from '../Milestone';
import { Card } from '../Objects';
import { createMilestone } from '../../actions/milestone';
import { updateProject } from '../../actions/project';
import MilestoneEdit from '../Milestone/MilestoneEdit';
import 'ant-design-pro/dist/ant-design-pro.css';
import { INVOICE_STATUS_OPTIONS } from '../Milestone/constants';
import MilestoneGroup from './MilestoneGroup';

// import 'antd/dist/antd.css';

// TODO - needs work
function MilestonesSection({
  project,
  milestones,
  currentUser,
  payer,
  createMilestone,
}) {
  // Start investigating here for build issue

  const [conversionRates, setConversionRates] = useState({});
  const [openSections, setOpenSections] = useState(['pending_approval']);

  // Let's load the currency exchange rates
  // Hardcoded to check for EUR, but can change this
  useEffect(() => {
    if (project.currency != null && project.currency !== 'USD') {
      fetch(
        'https://api.currencylayer.com/live?access_key=b68cf402d91375e304fa668c0083da54&currencies=USD,EUR&format=1'
      )
        .then((res) => res.json())
        .then((result) => {
          if (result && result.quotes) {
            const conversionRatesTemp = result.quotes;
            setConversionRates(conversionRatesTemp);
          }
        });
    }
  }, [project]);

  const [editing, setEditing] = useState(null);

  if (project) {
    return (
      <>
        <div>
          {Object.keys(INVOICE_STATUS_OPTIONS).map((option) => (
            <MilestoneGroup
              type={option}
              openSections={openSections}
              setOpenSections={setOpenSections}
              milestones={
                // Only show milestones relevant to the project_id
                Object.values(milestones).filter(
                  (milestone) => milestone.project_id === project.id
                )
              }
              project={project}
              payer={payer}
              conversionRates={conversionRates}
              editing={editing}
              setEditing={setEditing}
              currentUser={currentUser}
            />
          ))}
        </div>
        {currentUser.acc_type === 2 && (
          <Button
            className="create-milestone-button"
            onClick={() => {
              createMilestone(project.id, project.late_fee_enabled).then(() =>
                message.success('Milestone created! Feel free to edit :)')
              );
            }}
          >
            New Milestone
          </Button>
        )}

        <Modal
          title={false}
          open={editing != null}
          onCancel={() => setEditing(null)}
          footer={false}
          width={800}
        >
          {milestones[editing] && (
            <MilestoneEdit
              milestone={milestones[editing]}
              project={project}
              cancelEdit={() => setEditing(null)}
              finishedEditing={() => setEditing(null)}
            />
          )}
        </Modal>
      </>
    );
  }
}

const grid = 6;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  paddingTop: grid * 2,
  paddingBottom: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const mapStateToProps = (state) => ({
  milestones: state.milestones,
});

const mapDispatchToProps = {
  createMilestone,
  updateProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(MilestonesSection);
