const url = process.env.REACT_APP_API_URL;

const fetchFeatures = () => dispatch =>
  new Promise((resolve, reject) => {
    fetch(`${url}/features`)
      .then(res => res.json())
      .then(resJson => {
        dispatch({
          type: 'GET_FEATURES',
          data: resJson,
        });
        resolve(resJson);
      })
      .catch(error => {
        dispatch({
          type: 'GET_FEATURE_ERROR',
        });
        reject(error);
      });
  });

export default fetchFeatures;
