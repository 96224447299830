const aloaCreditsZapierHook =
  'https://hooks.zapier.com/hooks/catch/5791999/oihhexc/';

export const updateSalesforceAloaCredits = async (email, credits) =>
  fetch(aloaCreditsZapierHook, {
    method: 'POST',
    body: JSON.stringify({ email, credits }),
  });

export default {
  updateSalesforceAloaCredits,
};
