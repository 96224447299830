export const milestones = (state = {}, action) => {
  const actionObj = {};
  switch (action.type) {
    case 'LOAD_MILESTONE':
      actionObj[action.milestone.id] = action.milestone;
      return {
        ...state,
        ...actionObj,
      };
    case 'UPDATE_MILESTONE':
      actionObj[action.milestone.id] = action.milestone;
      return {
        ...state,
        ...actionObj,
      };
    case 'DELETE_MILESTONE':
      // Delete milestone from state
      const newState = { ...state };

      delete newState[action.milestoneId];

      return newState;

    default:
      return state;
  }
};

export default milestones;
