import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    textColor: '#000000',
    primary1Color: '#002F49',
    primary2Color: '#000000',
    accent1Color: '#002F49',
    pickerHeaderColor: '#ffffff',
    alternateTextColor: '#ffffff',
  },
  appBar: {
    height: 60,
  },
});
