/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
`;

export const ModalTitle = styled.div`
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const ModalCloseButton = styled.div`
  color: #6d6d6d;
  cursor: pointer;
  flex-grow: 0;
`;

export const ModalBackButton = styled.div`
  color: #0d6cff;
  cursor: pointer;
  margin-right: 30px;
  display: flex;
  align-items: center;
`;
