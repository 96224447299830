/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/App.css';

// Redux stuff
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import Auth from './Auth';
import registerServiceWorker from './registerServiceWorker';
import { auth, db } from './fire';
import { loadUser, logoutUser, updateUserAppVersion } from './actions/user';
import { fetchProjectsForUser } from './actions/project';
import { beginLoading } from './actions/loading';
import fetchFeatures from './actions/features';
import fetchAppVersion from './actions/app';
import reducers from './reducers/index';

import { App, AppNotLoggedIn } from './App';
import ErrorBoundary from './components/ErrorBoundary';
import Loading from './components/Loading';

const store = createStore(reducers, applyMiddleware(thunkMiddleware));
store.dispatch(fetchFeatures());
let user = auth.currentUser;

const basename = process.env.REACT_APP_BASE_ROUTE
  ? process.env.REACT_APP_BASE_ROUTE
  : '/';

const auth0 = new Auth();
auth0
  .checkSession()
  .then((result) => {
    const { idToken } = result;
    if (idToken) {
      auth0.handleFirebaseToken(idToken);
    }
  })
  .catch((error) => {
    if (error.error === 'login_required') {
      auth.signOut();
      if (!auth.currentUser) {
        if (window.location.pathname.includes('register')) {
          window.location.href = `${process.env.REACT_APP_ACCOUNTS_URL}${window.location.pathname}`;
        } else if (!window.location.pathname.includes('callback')) {
          let redirectLocation = `${process.env.REACT_APP_ACCOUNTS_URL}/login?ref=pay`;

          if (window.location.pathname !== '/') {
            redirectLocation += `&path=${window.location.pathname}`;
          }

          if (window.location.search !== '') {
            redirectLocation += `&query=${window.location.search}`;
          }

          window.location.href = redirectLocation;
        }
      }
    }
  });

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<Loading />);

auth.onAuthStateChanged((tempuser) => {
  new Promise((resolve, reject) => {
    if (tempuser) {
      // User is signed in
      user = tempuser;

      const profilesRef = db.collection('users').doc(user.uid);
      profilesRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const newUser = doc.data();

            // Fetch server side app version, this is purely to force a refresh
            store.dispatch(fetchAppVersion());

            // This is where we really store the real app version,
            // Run a check to see what version the user is on and update it
            // if necessary
            const CURRENT_APP_VERSION = 1.25;

            store.dispatch(beginLoading());
            store.dispatch(loadUser(doc.data()));

            if (newUser.app_version != null) {
              if (newUser.app_version !== CURRENT_APP_VERSION) {
                // User version differs from this local version number

                store.dispatch(updateUserAppVersion(CURRENT_APP_VERSION));
              }
            } else {
              // There isn't a version saved for the user, save this one

              store.dispatch(updateUserAppVersion(CURRENT_APP_VERSION));
            }

            store.dispatch(
              fetchProjectsForUser(doc.data(), getLoadingPriority())
            );
            resolve(true);
          } else {
            // Error fetching user data - have them sign in again
            store.dispatch(logoutUser());
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      // User is signed out
      resolve(false);
    }
  })
    .then((loggedIn) => {
      root.render(
        <Provider store={store}>
          <BrowserRouter basename={basename}>
            <ErrorBoundary user={user}>
              {loggedIn ? <App /> : <AppNotLoggedIn auth0={auth0} />}
            </ErrorBoundary>
          </BrowserRouter>
        </Provider>,
        // eslint-disable-next-line no-undef
        document.getElementById('root')
      );
    })
    .catch((error) => {
      console.error(error);
    });
});

const getLoadingPriority = () => {
  const path = window.location.pathname;
  if (path.includes('projects')) {
    return [path.replace('/projects/', '')];
  }
  if (path.includes('project')) {
    return [path.replace('/project/', '')];
  }
  return null;
};

registerServiceWorker();
