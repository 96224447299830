import React, { useState } from 'react';
import {
  injectStripe,
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
  PostalCodeElement,
} from 'react-stripe-elements';
import { Button, Form } from 'antd';

function CreditCardForm({ stripe, user, ccReveived, children }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (ev) => {
    setLoading(true);
    ev.preventDefault();

    // Probably could use finally, but don't want console to complain about an uncaught error
    stripe.createToken({ name: user.name }).then(async ({ token }) => {
      ccReveived(token)
        .then(() => setLoading(false))
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    });
  };

  return (
    <Form
      labelCol={{ span: 24 }} // Force label to take the full width of the row
      wrapperCol={{ span: 24 }} // Force input to take the full width of the row
      onSubmit={handleSubmit}
    >
      <Form.Item className="add-credit-card-form-item" label="Card Number">
        <CardNumberElement className="stripe-input ant-input" />
      </Form.Item>
      <Form.Item className="add-credit-card-form-item" label="Expiration Date">
        <CardExpiryElement className="stripe-input ant-input" />
      </Form.Item>
      <Form.Item className="add-credit-card-form-item" label="CVC">
        <CardCVCElement className="stripe-input ant-input" />
      </Form.Item>
      <Form.Item className="add-credit-card-form-item" label="Postal code">
        <PostalCodeElement className="stripe-input ant-input" />
      </Form.Item>
      <div className="add-credit-card-form-button-item clearFix">
        <Form.Item className="add-credit-card-form-item">
          <div style={{ float: 'right' }}>
            {children}
            <Button
              loading={loading}
              onClick={handleSubmit}
              type="primary"
              style={{ color: '#ffffff' }}
            >
              Add Card
            </Button>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
}

export default injectStripe(CreditCardForm);
