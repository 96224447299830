/* eslint-disable react/destructuring-assignment */
import React from 'react';
import * as Sentry from '@sentry/browser';
import { Button } from 'antd';
import errorImage from '../../images/502error.svg';
import { isDevEnv, isDevServer } from '../../utils';

const sentryEnabled = !isDevEnv() || isDevServer();
if (sentryEnabled) {
  Sentry.init({
    dsn: 'https://0874b50640d64f4195a6e1baf5e69b96@sentry.io/1410729',
  });
}

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: user.uid, email: user.email });
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    if (sentryEnabled) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div style={{ textAlign: 'center' }}>
          <img
            style={{ marginTop: '100px', width: '400px' }}
            src={errorImage}
          />
          <div
            className="partner-message"
            style={{ fontSize: '18px', marginBottom: '20px' }}
          >
            Something went wrong! Our team has been notified but you can still
            report the issue.
          </div>

          <Button
            type="secondary"
            style={{ marginRight: '10px' }}
            onClick={() => Sentry.showReportDialog()}
          >
            Report Issue
          </Button>

          <Button type="primary" onClick={() => window.open('/', '_self')}>
            Go Home
          </Button>
        </div>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}
