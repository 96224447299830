import { db } from '../fire';
// eslint-disable-next-line import/no-cycle
import { updateProject } from './project';
import { id } from '../utils';

// Invite state data model
// export const Invite = {
//   id: {
//    date: Date,
//    email: String,
//    from: userId
//    project: projectId,
//    role: payer
//   }
// };

/**
 * Load invites into the store
 * @param {Object} data Data of invites to load into the store
 */
export const loadInvite = (data) => (dispatch) => {
  dispatch({
    type: 'LOAD_INVITE',
    data,
  });
};

/**
 * Fetch all the invites for a given project
 * @param {Object} project Project object to fetch invites for
 */
export const fetchInvitesForProject = (project) => (dispatch) =>
  new Promise((resolve, reject) => {
    db.collection('invites')
      .where('project', '==', project.id)
      .get()
      .then((snapshot) => {
        const invites = [];
        snapshot.forEach((doc) => {
          const data = {};
          data[doc.id] = doc.data();
          data[doc.id].id = doc.id;
          invites.push(doc.id);
          dispatch(loadInvite(data));
        });
        resolve(invites);
      })
      .catch((error) => reject(error));
  });

/**
 * Fetch all the invites for an email
 * @param {String} email
 */
export const fetchInvitesForEmail = (email) => (dispatch) =>
  new Promise((resolve, reject) => {
    db.collection('invites')
      .where('email', '==', email)
      .get()
      .then((snapshot) => {
        const invites = [];
        snapshot.forEach((doc) => {
          const data = {};
          data[doc.id] = doc.data();
          data[doc.id].id = doc.id;
          invites.push(doc.data());
          dispatch(loadInvite(data));
        });
        resolve(invites);
      })
      .catch((error) => reject(error));
  });

/**
 * Fetch invite with a given code
 * @param {String} code
 */
export const fetchInviteWithCode = (code) => (dispatch) =>
  new Promise((resolve, reject) => {
    db.collection('invites')
      .doc(code)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = {};
          data[doc.id] = doc.data();
          data[doc.id].id = doc.id;
          dispatch(loadInvite(data));
          resolve(doc.data());
        } else {
          reject(Error('No invite with id found'));
        }
      })
      .catch((error) => reject(error));
  });

/**
 * Create a new invite for a project with the specified data
 * @param {String} to Email of invitee
 * @param {String} from UserId of inviter
 * @param {Object} project Project object invite involves
 * @param {String} role Role of invitee in project
 */
export const createInviteForProject =
  (to, from, project, role, createInviteForProject = false) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const newId = id();
      const invite = {
        id: newId,
        email: to,
        date: Date(),
        from,
        role,
        project: project.id,
        show_payment_method_onboarding: createInviteForProject,
      };

      const { invites } = getState();
      const userAlreadyInvited =
        project.invites &&
        project.invites.filter((id) => invites[id].email === to).length !== 0;

      if (userAlreadyInvited) {
        reject(Error('User already invited to project'));
      } else {
        db.collection('invites')
          .doc(newId)
          .set(invite)
          .then(() => {
            const data = {};
            data[newId] = invite;
            dispatch(loadInvite(data));

            if (project.invites) {
              project.invites.push(newId);
            } else {
              project.invites = [newId];
            }

            dispatch(updateProject(project))
              .then(() => resolve(invite))
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      }
    });

/**
 * Cancel the invite for a project and remove it from the db
 * @param {Object} invite
 */
export const removeInviteFromProject = (invite) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const project = getState().projects[invite.project];
    project.invites = project.invites.filter((id) => id !== invite.id);

    dispatch(updateProject(project))
      .then(() => {
        db.collection('invites')
          .doc(invite.id)
          .delete()
          .then(() => resolve());
      })
      .catch((error) => reject(error));
  });

/**
 * Update the role of an invite for its project
 * @param {Object} invite Invite object to update
 * @param {String} role New role of the invitee in the project
 */
export const updateInviteRoleInProject = (invite, role) => (dispatch) =>
  new Promise((resolve, reject) => {
    invite.role = role;
    db.collection('invites')
      .doc(invite.id)
      .set({ role }, { merge: true })
      .then(() => {
        const updateData = {};
        updateData[invite.id] = invite;
        dispatch(loadInvite(updateData));
        resolve(invite);
      })
      .catch((error) => reject(error));
  });
