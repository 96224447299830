import React from 'react';
import { connect } from 'react-redux';
import { Modal, message } from 'antd';
import FeatureToggle, { On, Off, featureTypes } from '../FeatureToggle';
import './EulaDialog.scss';

const eulaLastUpdate = new Date('September 21, 2019 12:00:00');

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

function EulaDialog({ currentUser }) {
  return (
    (<FeatureToggle feature={featureTypes.EULA}>
      <On>
        {currentUser &&
          !(
            currentUser.eula &&
            currentUser.eula.signed &&
            new Date(currentUser.eula.timestamp) > eulaLastUpdate
          ) && (
            <Modal
              title="Sorry for the Interruption"
              open
              closable={false}
              onOk={() => {
                const ref = window.location.href
                  .toString()
                  .replace('https://', '')
                  .replace('http://', '');

                window.location.replace(
                  `${process.env.REACT_APP_ACCOUNTS_URL}/eula?ref=${ref}`
                );
              }}
              onCancel={() =>
                message.error(
                  'Sorry, you must accept the End User License Agreement before continuing'
                )
              }
            >
              <p>
                {`We recently updated our End User License Agreement. Please read and accept the
            agreement before continuing.`}
              </p>
            </Modal>
          )}
      </On>
      <Off />
    </FeatureToggle>)
  );
}

export default connect(
  mapStateToProps,
  {}
)(EulaDialog);
