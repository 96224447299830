/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';

// import 'antd/dist/antd.css';


import NumberFormat from 'react-number-format';

import { RadialChart, Hint } from 'react-vis';
import { Card } from '../../Objects';

export default function DataCard({
  title,
  subtitle,
  timeData,
  moneyData,
  radialChartData,
  totalAR,
}) {
  const [hintValue, setHintValue] = useState(false);

  return (
    <div>
      <Card>
        {' '}
        {title && <div className="data-card-title">{title}</div>}
        {subtitle && <div className="data-card-subtitle">{subtitle}</div>}
        <span className="data-card-value">
          {moneyData && (
            <NumberFormat
              value={moneyData}
              displayType="text"
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              prefix="$"
            />
          )}
          {timeData && (
            <span style={{ color: '#ff5e7e' }}>{timeData} Days </span>
          )}
          {radialChartData && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <RadialChart
                //       className="donut-chart-example"
                innerRadius={60}
                radius={100}
                getAngle={d => d.amount}
                data={radialChartData}
                onValueMouseOver={v =>
                  setHintValue({
                    Name: v.name,
                    Amount: `$${v.amount}`,
                    Percentage: `${((v.amount / totalAR) * 100).toFixed(2)}%`,
                  })
                }
                onSeriesMouseOut={v => setHintValue(false)}
                width={200}
                height={200}
                padAngle={0.04}
              >
                {hintValue !== false && <Hint value={hintValue} />}
              </RadialChart>
              <div
                style={{
                  fontSize: '15px',
                  color: '#272727',
                  fontFamily: 'Avenir Next',
                  position: 'absolute',
                  padding: '5px',
                }}
              >
                {radialChartData.length} Projects
              </div>
            </div>
          )}
        </span>
      </Card>
    </div>
  );
}
