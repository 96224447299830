/* eslint-disable import/no-cycle */
import { db } from '../fire';
import { beginLoading, endLoading } from './loading';
import { fetchMilestonesForProject } from './milestone';
import { fetchCollaborator } from './collaborator';
import { fetchInvitesForProject } from './invites';

/**
 * Loads a new project
 * @param {Object} project Project object to load
 */
export const loadProject = (project) => ({
  type: 'LOAD_PROJECT',
  project,
});

/**
 * Updates a project
 * @param {Object} project
 * @returns {Promise}
 */
export const updateProject = (project) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const updatedProject = { ...getState().projects[project.id], ...project };

    db.collection('projects')
      .doc(project.id)
      .set(project, { merge: true })
      .then(() => {
        dispatch({
          type: 'UPDATE_PROJECT',
          project: updatedProject,
        });
        resolve({ messge: 'Update successful' });
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Creates a new project
 * @param {string} name The name of the new project
 * @param {Object} user The user creating the project
 * @returns {Promise}
 */
export const createProject = (name, user) => (dispatch) =>
  new Promise((resolve, reject) => {
    // Default project object
    const newProject = {
      name,
      active: true,
      collaborators: [
        {
          user,
          role: 'payee',
        },
      ],
      late_fee_enabled: true,
      date_started: new Date(),
      collaborator_ids: [user],
      invites: [],
      milestonesOrder: [],
      // Approval data
      approved: false,
      approval: {
        user: '',
        date: '',
      },
      // Email notification data
      // TODO - Decide on appropriate default values for these
      notificationSettings: {
        completion: true,
        reminder: false,
      },
    };

    db.collection('projects')
      .add(newProject)
      .then((docRef) => {
        const projectData = {};
        newProject.id = docRef.id;
        projectData[docRef.id] = newProject;

        setTimeout(() => {
          dispatch(loadProject(projectData));
          resolve(docRef.id);
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          reject(error);
        }, 1000);
      });
  });

/**
 * Fetches relevant project items from firebase (milestones, collaborators, invites)
 * @param {Object} data project data to fetch full data for
 * @returns {Promise}
 */
export const fetchRelatedProjectDataFromFirebase = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const projectId = typeof data === 'object' ? data.id : data;
    const projectData = {};

    projectData[projectId] = data;
    const projectParam = { id: projectId };

    // Start async invites call
    const invites = dispatch(fetchInvitesForProject(projectParam));

    // Start async milestones call
    dispatch(fetchMilestonesForProject(projectParam))
      .then(async (result) => {
        if (!projectData[projectId].milestonesOrder) {
          projectData[projectId].milestonesOrder = result;
        }

        // await for invite response - need to load to render ProjectHeaderCard smoothly
        projectData[projectId].invites = await invites;
        dispatch(loadProject(projectData));
        resolve(projectData);
      })
      .catch((error) => reject(error));

    if (projectData[projectId].collaborators) {
      projectData[projectId].collaborators.forEach((collaborator) => {
        dispatch(
          fetchCollaborator(collaborator.user, projectId, collaborator.role)
        );
      });
    }
  });

/**
 * Fetch a specific project from firebase for a given Id and load it into the store
 * @param {String} projectId Id of project to load
 */
export const fetchProjectFromFirebase = (projectId) => (dispatch) =>
  new Promise((resolve, reject) => {
    db.collection('projects')
      .doc(projectId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = {};
          data[projectId] = doc.data();
          data[projectId].id = projectId;
          dispatch(loadProject(data));
          resolve(doc.data());
        } else {
          reject(Error('Project not found'));
        }
      });
  });

/**
 * Loads the projects for a user
 * @param {Object} user User object to load projects for
 * @param {Array} priority Array with projects to prioritize (important when loading from specific project page)
 * @returns {Promise}
 */
export const fetchProjectsForUser = (user, priority) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(beginLoading());

    console.log('fetchProjectsForUser', user);

    // Prioritize some projects over others (ex. in case of project page)
    if (priority) {
      Promise.all(
        priority.map((id) => {
          return new Promise((resolve, reject) => {
            db.collection('projects')
              .doc(id)
              .get()
              .then((doc) => {
                const data = doc.data();
                data.id = doc.id;
                dispatch(fetchRelatedProjectDataFromFirebase(data));
                resolve(data);
              })
              .catch((error) => reject(error));
          });
        })
      ).then(() => dispatch(endLoading()));
    }

    const projectsRef = db.collection('projects');
    let queryProjectsRef = projectsRef
      .where('collaborator_ids', 'array-contains', user.id)
      .orderBy('date_started', 'desc');

    // If user has acc_type 2 (admin), load all projects
    if (user.acc_type === 2) {
      queryProjectsRef = projectsRef.orderBy('date_started', 'desc');
    }

    queryProjectsRef
      .get()
      .then((snapshot) => {
        Promise.all(
          snapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return dispatch(fetchRelatedProjectDataFromFirebase(data));
          })
        ).then(() => {
          dispatch(endLoading());
          resolve();
        });
      })
      .catch((err) => {
        dispatch(endLoading());
        reject(err);
      });
  });

/**
 * Function for current user to agree to pricing contract
 * @param {string} projectId Project id for project to update
 * @returns {Promise}
 */
export const agreeToProject = (projectId) => (dispatch, getState) => {
  const currentUserId = getState().currentUser.id;
  const signature = {
    id: projectId,
    approved: true,
    approval: {
      user: currentUserId,
      date: Date.now(),
    },
  };

  return dispatch(updateProject(signature));
};

/**
 * Add milestone to a project
 * @param {string} projectId ProjectId to add milestone to
 * @param {Object} milestone MilestoneId to remove
 * @returns {Promise}
 */
export const addMilestoneToProject =
  (projectId, milestone) => (dispatch, getState) => {
    let { milestonesOrder } = getState().projects[projectId];
    milestonesOrder = milestonesOrder || [];
    milestonesOrder.push(milestone.id);

    return dispatch(updateProject({ id: projectId, milestonesOrder }));
  };

/**
 * Remove milestone from a project
 * @param {string} projectId ProjectId to remove milestone from
 * @param {string} milestoneId MilestoneId to remove
 * @returns {Promise}
 */
export const removeMilestoneFromProject =
  (projectId, milestoneId) => (dispatch, getState) => {
    const { milestonesOrder } = getState().projects[projectId];
    milestonesOrder.splice(milestonesOrder.indexOf(milestoneId), 1);
    return dispatch(updateProject({ id: projectId, milestonesOrder }));
  };

/**
 * Function to update project as arvchived
 * @param {Object} projectId Project to archive
 * @param {Boolean} archived Boolean value of project archived state
 * @returns {Promise}
 */
export const archiveProject = (project, archived) => (dispatch) =>
  new Promise((resolve, reject) => {
    project.archived = archived;
    dispatch(updateProject(project))
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });

/**
 * Function to add user to a project with a given role
 * @param {Object} project project to add user to
 * @param {String} user Id of user to add
 * @param {String} role role of new user
 * @returns {Promise}
 */
export const addUserToProject = (project, user, role) => (dispatch) =>
  new Promise((resolve, reject) => {
    project.collaborator_ids.push(user);
    project.collaborators.push({ user, role });

    dispatch(fetchCollaborator(user, project.id, role))
      .then(() => {
        dispatch(updateProject(project)).then(() => {
          resolve(project);
        });
      })
      .catch((error) => reject(error));
  });
