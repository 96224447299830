import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { BankOutlined, CreditCardOutlined } from '@ant-design/icons';
import { message, Button } from 'antd';
import {
  addPlaidSource,
  addCreditCard,
  addBankAccount,
} from '../../../actions/user';
import { showMilestoneActions } from '../../../actions/appDialog';
import addBankImg from '../../../images/add_bank.png';
import addCcImg from '../../../images/add_cc.png';
import addSepaImg from '../../../images/add_sepa.svg';
import AddCreditCard from './AddCreditCard';
import AddBankAccount from './AddBankAccount';
import AddSepa from './AddSepa';
import BankForm from './AddBankAccount/BankForm';
import Complete from './Complete';
import Toggle, { On, Off } from '../../FeatureToggle';
import featuresTypes from '../../FeatureToggle/featuresTypes';
import './AddPaymentDialog.scss';

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  addPlaidSource,
  addCreditCard,
  addBankAccount,
  showMilestoneActions,
};

/**
 * List of different active states for convenience
 * Component usage - this.setState(activeStates.example);
 */
const activeStates = {
  null: null,
  bankComplete: 'bank_complete',
  ccComplete: 'cc_complete',
  bankNoPlaid: 'bank_no_plaid',
  bank: 'bank',
  cc: 'cc',
  sepa: 'sepa',
};

/**
 * Component to let user add payment
 * Path - /addPayment
 */
function AddPaymentDialogContent({
  currentUser,
  addCreditCard,
  addPlaidSource,
  addBankAccount,
  visible,
  callback,
}) {
  const [active, setActive] = useState(activeStates.null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const { context } = useSelector(state => state.appDialog);

  // Reset to initial state when visibility is set to false
  useEffect(() => {
    if (!visible) setTimeout(() => setActive(activeStates.null), 1000);
  }, [visible]);

  useEffect(() => {
    if (context && context.linkToken) {
      setActive(activeStates.bank);
    }
  }, [context]);

  const cancelActive = () => {
    callback(paymentMethod);
  };

  const handleAddPlaidSource = (token, metadata) =>
    new Promise((resolve, reject) => {
      addPlaidSource(token, metadata)
        .then(response => {
          setPaymentMethod(response);
          setActive(activeStates.bankComplete);
          resolve(response);
        })
        .catch(error => {
          message.error(error.raw.message);
          reject(error);
        });
    });

  const handleAddBankAccount = bankData =>
    new Promise((resolve, reject) => {
      addBankAccount(bankData)
        .then(response => {
          setPaymentMethod(response);
          setActive(activeStates.bankComplete);
          resolve(response);
        })
        .catch(error => {
          message.error(error.message);
          reject(error);
        });
    });

  const handleAddCreditCard = token =>
    new Promise((resolve, reject) => {
      addCreditCard(token)
        .then(response => {
          setPaymentMethod(response);
          setActive(activeStates.ccComplete);
          resolve(response);
        })
        .catch(error => {
          if (error.type !== undefined) {
            message.error(error.message);
          } else {
            message.error(
              'Looks like something is wrong with your card... Try checking your input.'
            );
          }
          reject(error);
        });
    });

  const handleAddSepa = paymentMethod => {
    setPaymentMethod(paymentMethod);
    setActive(activeStates.sepaComplete);
  };

  return (
    <>
      <span className="app-dialog-header">{titleForActiveState(active)}</span>
      {active === null && (
        <>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <div
              className="add-bank-div"
              onClick={() => {
                setActive(activeStates.bank);
              }}
            >
              <img className="add-payment-img" src={addBankImg} />
            </div>
            <div
              className="add-cc-div"
              onClick={() => {
                setActive(activeStates.cc);
              }}
            >
              <img className="add-payment-img" src={addCcImg} />
            </div>
            <Toggle feature={featuresTypes.SEPA} user={currentUser}>
              <On>
                <div
                  className="add-cc-div"
                  onClick={() => {
                    setActive(activeStates.sepa);
                  }}
                >
                  <img className="add-payment-img" src={addSepaImg} />
                </div>
              </On>
              <Off />
            </Toggle>
          </div>
          <div className="partner-message">
            {
              'We’ve partnered with Stripe to process Credit Cards/ACH Transfers and Plaid to handle banking information. None of this information is stored on our servers.'
            }
          </div>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Button onClick={() => callback()}>Cancel</Button>
          </div>
        </>
      )}
      {active === activeStates.cc && (
        <AddCreditCard
          currentUser={currentUser}
          handleAddCreditCard={handleAddCreditCard}
          cancelActive={cancelActive}
        />
      )}
      {active === activeStates.bank && (
        <AddBankAccount
          currentUser={currentUser}
          handleAddBankAccount={handleAddBankAccount}
          handleAddPlaidSource={handleAddPlaidSource}
          addNonPlaidBank={() => setActive(activeStates.bankNoPlaid)}
          cancelActive={cancelActive}
        />
      )}
      {active === activeStates.bankNoPlaid && (
        <BankForm submit={handleAddBankAccount}>
          <Button
            className="add-payment-cancel-button"
            onClick={() => setActive(activeStates.bank)}
          >
            Cancel
          </Button>
        </BankForm>
      )}
      {active === activeStates.sepa && (
        <AddSepa
          cancelActive={cancelActive}
          handleAddSepa={handleAddSepa}
          complete="SEPA Direct Debit"
        />
      )}
      {active === activeStates.ccComplete && (
        <Complete cancelActive={cancelActive} complete="Credit Card" />
      )}
      {active === activeStates.bankComplete && (
        <Complete cancelActive={cancelActive} complete="Bank Account" />
      )}
      {active === activeStates.sepaComplete && (
        <Complete cancelActive={cancelActive} complete="SEPA Direct Debit" />
      )}
    </>
  );
}

const titleForActiveState = active => {
  if (active === activeStates.cc) {
    return <>
      {'Add New Credit Card'}
      <CreditCardOutlined style={{ marginLeft: '8px' }} />
    </>;
  }
  if (active === activeStates.bank || active === activeStates.bankNoPlaid) {
    return <>
      {'Add New Bank Account'}
      <BankOutlined style={{ marginLeft: '8px', verticalAlign: '-0.1em' }} />
    </>;
  }
  if (active === activeStates.sepa) {
    return <>
      {'Add SEPA Direct Debit'}
      <BankOutlined style={{ marginLeft: '8px', verticalAlign: '-0.1em' }} />
    </>;
  }
  return <>Add New Payment Method</>;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPaymentDialogContent);
