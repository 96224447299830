import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './Dashboard.scss';
import { Input, Button, message, Modal, Switch } from 'antd';

// import 'antd/dist/antd.css';

import Search from 'antd/lib/input/Search';
import AttensionSection from './AttentionSection';
import ProjectsSection from './ProjectSection';
import DataCards from './DataCards';
import { createProject } from '../../actions/project';
import { showAddPayment } from '../../actions/appDialog';
import { updateUserInfo } from '../../actions/user';

const mapStateToProps = (state) => ({
  projects: state.projects,
  currentUser: state.currentUser,
  milestones: state.milestones,
  loading: state.loading,
  collaborators: state.collaborators,
});

const mapDispatchToProps = {
  createProject,
  showAddPayment,
  updateUserInfo,
};

/**
 * Dashboard component that loads at base route
 */
function Dashboard({
  currentUser,
  projects,
  history,
  loading,
  createProject,
  milestones,
  showAddPayment,
  updateUserInfo,
  collaborators,
}) {
  const [name, setName] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [demoOn, setDemoOn] = useState(
    currentUser.demoModeOn != null ? currentUser.demoModeOn : false
  );

  const [hideAvatars, setHideAvatars] = useState(
    currentUser.hide_avatars != null ? currentUser.hide_avatars : false
  );

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (window.location.pathname.indexOf('addPaymentMethod') > -1) {
      showAddPayment();
    }
  }, [window.location]);

  const handleTextChange = (event) => {
    setName(event.target.value);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    createProject(name, currentUser.id)
      .then((projectId) => {
        const url = `/project/${projectId}`;
        message.success('Projected Created');
        setVisible(false);
        setConfirmLoading(false);
        history.push(url);
      })
      .catch((error) => {
        message.error(`Something went wrong: ${error}`);
        setVisible(false);
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setConfirmLoading(false);
  };

  const newProject = () => {
    setVisible(true);
  };

  function demoModeToggled(checked) {
    updateUserInfo(
      {
        demoModeOn: checked,
      },
      currentUser.id
    )
      .then((projectId) => {
        setDemoOn(checked);
      })
      .catch((error) => {
        message.error(`Something went wrong: ${error}`);
      });
  }

  useEffect(() => {
    if (currentUser.hide_avatars != null) {
      setHideAvatars(currentUser.hide_avatars);
    }
  }, [currentUser]);

  if (!loading) {
    return (
      <div className="App">
        <Modal
          open={visible}
          onCancel={handleCancel}
          closable={false}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={confirmLoading}
              onClick={handleOk}
            >
              Create Project
            </Button>,
          ]}
        >
          <div className="new-project-header">New Project</div>
          <Input placeholder="Project Name" onChange={handleTextChange} />
        </Modal>
        <div className="dashPage">
          {currentUser.acc_type === 2 && (
            <div className="flex flex-row gap-6 items-center justify-end py-4">
              <div className="flex flex-row gap-2 items-center">
                Show Avatars
                <Switch
                  size="small"
                  checked={!hideAvatars}
                  onChange={(checked) => {
                    if (currentUser) {
                      updateUserInfo(
                        {
                          hide_avatars: !checked,
                        },
                        currentUser.id
                      )
                        .then(() => {
                          setHideAvatars(!checked);
                        })
                        .catch((error) => {
                          message.error(`Something went wrong: ${error}`);
                        });
                    }
                  }}
                />
              </div>

              <Search
                placeholder="Search Projects"
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
              />

              <div
                className="top-button"
                // onClick={() => createProject('', currentUser)}
                onClick={newProject}
              >
                New Project
              </div>
            </div>
          )}

          <AttensionSection
            projects={Object.values(projects).filter(
              (project) => !project.archived
            )}
            currentUser={currentUser}
          />

          {currentUser.acc_type === 2 && !demoOn && (
            <div style={{ marginTop: 10 }}>
              <div className="section-header">Summary</div>

              <DataCards
                projects={Object.values(projects).filter(
                  (project) => !project.archived
                )}
                currentUser={currentUser}
                history={history}
                milestones={milestones}
              />
            </div>
          )}

          <div style={{ marginTop: 10 }} />

          <div className="section-header">Your Projects</div>

          <ProjectsSection
            projects={Object.values(projects).filter((project) => {
              if (
                !project.archived &&
                project.name
                  .toLowerCase()
                  .includes(searchTerm.toLocaleLowerCase())
              ) {
                return true;
              }
            })}
            demoOn={demoOn}
            currentUser={currentUser}
            history={history}
            milestones={milestones}
            collaborators={collaborators}
          />

          <div className="section-header">
            Archived
            {!showArchived && (
              <span
                className="archive-button"
                onClick={() => setShowArchived(!showArchived)}
              >
                (show)
              </span>
            )}
            {showArchived && (
              <span
                className="archive-button"
                onClick={() => setShowArchived(!showArchived)}
              >
                (hide)
              </span>
            )}
          </div>

          {showArchived && (
            <ProjectsSection
              projects={Object.values(projects).filter((project) => {
                if (
                  project.archived &&
                  project.name
                    .toLowerCase()
                    .includes(searchTerm.toLocaleLowerCase())
                ) {
                  return true;
                }
              })}
              demoOn={demoOn}
              currentUser={currentUser}
              archived
              history={history}
              milestones={milestones}
              collaborators={collaborators}
            />
          )}

          {currentUser.acc_type === 2 && (
            <div className="demo-toggle">
              <span className="demo-toggle-text">Demo Mode</span>{' '}
              <Switch checked={demoOn} onChange={demoModeToggled} />
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="App">
      <div className="dashPage">
        <h1 className="sectionTitle">Loading Projects...</h1>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
