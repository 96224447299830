import moment from 'moment';
import { currencyFormat } from '../utils';
import { getDueDateFromInvoiceDate } from '../components/AppDialog/PDFs/utils';

const REMINDER_WORKFLOW_NAME = 'PaymentReminderWorkflow';
const PENDING_APPROVAL_TEMPLATE = 'pending-approval';
const REMINDER_TEMPLATE = 'approval-reminder';
const LATE_FEE_WARNING_TEMPLATE = 'late-fee-warning';
const LATE_FEE_APPLIED_TEMPLATE = 'late-fee-applied';

export const executeReminderEmailWorkflow = (users, milestone, project) =>
  new Promise((resolve, reject) => {
    Promise.all(
      users.map((user) => {
        const reminders = getDefaultWorkflow(user, milestone, project);
        const postBody = {
          name: REMINDER_WORKFLOW_NAME,
          arguments: {
            user: {
              email: user.email,
              name: user.name,
            },
            invoiceId: milestone.id,
            projectId: project.id,
            reminders,
          },
        };

        return fetch(process.env.REACT_APP_WORKFLOW_ENDPONT, {
          method: 'POST',
          body: JSON.stringify(postBody),
          mode: 'no-cors',
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
        });
      })
    )
      .then(() => {
        resolve(`Started ${REMINDER_WORKFLOW_NAME}`);
      })
      .catch((error) => reject(error));
  });

// Format function without doing the toDate() conversion
const formatTimestampNoConversion = (timestamp) => {
  const date = timestamp || new Date();
  return moment(date).format('MMMM DD, YYYY');
};

const getMilestoneDescription = (milestone) => {
  if (milestone.description) {
    return milestone.description;
  }
  return milestone.name;
};

const getDefaultWorkflow = (user, milestone, project) => [
  {
    delay: 0, // Immediately
    templateId: PENDING_APPROVAL_TEMPLATE,
    templateData: {
      user: user.name,
      milestone: {
        name: milestone.name,
        description: getMilestoneDescription(milestone),
      },
      project: {
        id: project.id,
        name: project.name,
      },
    },
  },
  {
    delay: 72, // 3 days
    templateId: REMINDER_TEMPLATE,
    templateData: {
      greeting: `Hi ${user.name}`,
      milestone: {
        name: milestone.name,
        status: 'Pending Approval',
        description: getMilestoneDescription(milestone),
        amount: currencyFormat(milestone.amount, 2),
        dueDate: formatTimestampNoConversion(
          getDueDateFromInvoiceDate(milestone.due_date, milestone.net_due_date)
        ),
      },
      project: {
        id: project.id,
        name: project.name,
      },
      age: '3 days ago',
    },
  },
  {
    delay: 96, // 7 days
    templateId: REMINDER_TEMPLATE,
    templateData: {
      greeting: `Hi ${user.name}`,
      milestone: {
        name: milestone.name,
        status: 'Pending Approval',
        description: getMilestoneDescription(milestone),
        amount: currencyFormat(milestone.amount, 2),
        dueDate: formatTimestampNoConversion(
          getDueDateFromInvoiceDate(milestone.due_date, milestone.net_due_date)
        ),
      },
      project: {
        id: project.id,
        name: project.name,
      },
      age: 'one week ago',
    },
  },
  {
    delay: 'due_date', // Use milestone due date
    templateId: LATE_FEE_WARNING_TEMPLATE,
    templateData: {
      greeting: `Hi ${user.name}`,
      milestone: {
        name: milestone.name,
        amount: currencyFormat(milestone.amount, 2),
      },
      project: {
        id: project.id,
        name: project.name,
      },
    },
  },
  {
    delay: 720, // 30 days
    templateId: LATE_FEE_APPLIED_TEMPLATE,
    templateData: {
      greeting: `Hi ${user.name}`,
      milestone: {
        name: milestone.name,
        amount: currencyFormat(milestone.amount, 2),
        dueDate: formatTimestampNoConversion(
          getDueDateFromInvoiceDate(milestone.due_date, milestone.net_due_date)
        ),
      },
      project: {
        id: project.id,
        name: project.name,
      },
    },
  },
];

export default {
  executeReminderEmailWorkflow,
};
