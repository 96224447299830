import React, { useState, useMemo } from 'react';
import * as moment from 'moment';
import { useTable, useSortBy } from 'react-table';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { Card, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
  }
`;

export default function RecentlyPaidMilestonesTable({
  currentUser,
  milestones,
  projects,
}) {
  const [filterDays, setFilterDays] = useState(null);

  const compareDates = (rowA, rowB, columnId) => {
    const dateA = rowA.values[columnId];
    const dateB = rowB.values[columnId];

    if (!dateA && !dateB) return 0;
    if (!dateA || isNaN(dateA)) return 1; // nulls or invalid dates last
    if (!dateB || isNaN(dateB)) return -1; // nulls or invalid dates last

    return dateA - dateB; // Newest first
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Project',
        id: 'projectName',
        accessor: (d) => d.projectInfo.name,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ cell: { value } }) => (
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            prefix="$"
          />
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => (
          <Link to={`/project/${row.original.projectInfo.id}`}>
            {row.original.name}
          </Link>
        ),
      },
      {
        Header: 'Payment Date',
        id: 'payment_date',
        accessor: (d) => d.paymentData?.date,
        Cell: ({ cell: { value } }) =>
          value ? moment.unix(value / 1000).format('MM/DD/YYYY') : '',
        sortType: compareDates,
      },
    ],
    []
  );

  const data = useMemo(() => {
    const paidMilestones = [];
    projects.forEach((project) => {
      Object.values(milestones)
        .filter(
          (milestone) =>
            milestone.project_id === project.id && milestone.status === 'paid'
        )
        .forEach((milestone) => {
          paidMilestones.push({
            ...milestone,
            projectInfo: project,
          });
        });
    });

    const validPaidMilestones = paidMilestones.filter(
      (milestone) =>
        milestone.paymentData?.date && !isNaN(milestone.paymentData.date)
    );

    if (filterDays) {
      const cutoffDate = moment().subtract(filterDays, 'days');
      return validPaidMilestones.filter((milestone) =>
        moment.unix(milestone.paymentData.date / 1000).isAfter(cutoffDate)
      );
    }

    return validPaidMilestones;
  }, [milestones, projects, filterDays]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [
            {
              id: 'payment_date',
              desc: true, // This ensures the most recent dates are at the top
            },
          ],
        },
      },
      useSortBy
    );

  const menu = (
    <Menu
      onClick={({ key }) => setFilterDays(key === 'all' ? null : parseInt(key))}
    >
      <Menu.Item key="all">Show all</Menu.Item>
      <Menu.Item key="7">Last 7 days</Menu.Item>
      <Menu.Item key="14">Last 14 days</Menu.Item>
      <Menu.Item key="30">Last 30 days</Menu.Item>
    </Menu>
  );

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '1rem',
        }}
      >
        <Dropdown overlay={menu}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            Filter by <DownOutlined />
          </a>
        </Dropdown>
      </div>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </Card>
  );
}
