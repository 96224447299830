/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { db, auth } from '../fire';
import stripeApi from '../api/stripe';
import { removeInviteFromProject, fetchInvitesForEmail } from './invites';
import { addUserToProject, fetchProjectFromFirebase } from './project';
import { updateSalesforceAloaCredits } from '../api/zapier';
import { getCustomerId } from '../utils';

/**
 * Load user data to store
 * @param {Object} user user object from firebase
 * @returns {Action}
 */
export const loadUser = user => {
  const loadedUser = user;
  loadedUser.acc_type = parseInt(user.acc_type, 10);
  return {
    type: 'LOAD_USER',
    user,
  };
};

/**
 * Log out user
 * Resets user in store
 * @returns {Action}
 */
export const logoutUser = () => {
  auth.signOut();
  return {
    type: 'LOGOUT_USER',
  };
};

/**
 * Updates the users version in the database
 * @param {Number} app_version user object from firebase
 * @returns {Action}
 */
export const updateUserAppVersion = app_version => {
  return function(dispatch, getState) {
    const { currentUser } = getState();

    const docRef = db.collection('users').doc(currentUser.id);

    return docRef
      .update({
        app_version,
      })
      .catch(console.error);
  };
};

/**
 * Logs in user with password, then calls loadUser to load in Store
 * @param {object} user - Full user object from Firebase - email property required
 * @param {string} password - Users's passowrd
 * @returns {Promise}
 */
export const login = (user, password) => dispatch => {
  dispatch(loadUser(user));
  return auth.signInWithEmailAndPassword(user.email, password);
};

/**
 * Function to create user, optionally from invite
 * @param {Object} userData Data to create user with
 */
export const createUser = newUserInput => dispatch =>
  new Promise((resolve, reject) => {
    auth
      .createUserWithEmailAndPassword(
        newUserInput.email.toLowerCase(),
        newUserInput.password
      )
      .then(async userData => {
        const { uid } = userData.user; // The UID of recently created user on firebase

        if (!newUserInput.image) {
          newUserInput.image = '';
        }

        const newUser = {
          image: newUserInput.image,
          name: newUserInput.name,
          company: newUserInput.company,
          email: newUserInput.email,
          id: uid,
          payment_methods: [],
          acc_type: 0,
          terms: {
            approved: false,
            signature: {
              company: '',
              dateSigned: '',
              fullName: '',
              title: '',
            },
          },
          billing_info: {
            address_1: '',
            address_2: '',
            city: '',
            state: '',
            zip: '',
          },
        };

        const stripeData = {
          email: newUser.email,
          description: `${newUser.name} - ${newUser.company}`,
        };

        const stripeResponse = await stripeApi.newCustomer(stripeData);
        const customerKey =
          process.env.REACT_APP_ENV === 'dev'
            ? 'customer_id_dev'
            : 'customer_id';
        newUser[customerKey] = stripeResponse.id;

        db.collection('users')
          .doc(uid)
          .set(newUser)
          .then(async () => {
            const invites = await dispatch(
              fetchInvitesForEmail(newUserInput.email.toLowerCase())
            );
            Promise.all(
              invites.map(invite =>
                dispatch(fetchProjectFromFirebase(invite.project)).then(
                  project => {
                    dispatch(removeInviteFromProject(invite));
                    dispatch(addUserToProject(project, uid, invite.role));
                  }
                )
              )
            ).then(() => resolve(newUser));
          });
      })
      .catch(error => {
        reject(error);
      });
  });

/**
 * Signs terms and conditions
 * TODO - Implement for Project (not on signup)
 * @returns {Promise}
 */
export const signTermsAndConditions = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();
    const signature = {
      terms: {
        approved: true,
        signature: {
          fullName: currentUser.name ? currentUser.name : '',
          title: currentUser.title ? currentUser.title : '',
          company: currentUser.company ? currentUser.company : '',
          dateSigned: Date.now(),
        },
      },
    };

    db.collection('users')
      .doc(currentUser.id)
      .set(signature, { merge: true })
      .then(() => {
        currentUser.terms = signature.terms;
        dispatch(loadUser(currentUser));
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });

/**
 * Saves updated billing info for user
 * @param {object} newBillingInfo - New billing info object to save to user
 * @param {string} newEmail - New email to update user's email to
 * @returns {Promise}
 */
export const saveBillingInfo = (newBillingInfo, newEmail) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const authUser = auth.currentUser;
    const { currentUser } = getState();

    if (currentUser.id) {
      db.collection('users')
        .doc(currentUser.id)
        .set(newBillingInfo, { merge: true })
        .then(() => {
          if (newEmail !== authUser.email) {
            authUser
              .updateEmail(newEmail)
              .then(() => {
                dispatch(loadUser());
                resolve('Account updated!');
              })
              .catch(error => reject(error));
          } else {
            dispatch(loadUser({ ...currentUser, ...newBillingInfo }));
            resolve('Account updated!');
          }
        })
        .catch(error => reject(error));
    } else {
      reject(Error('User not signed in'));
    }
  });

/**
 * Saves updated general info for user
 * @param {object} newInfo - New billing info object to save to user
 * @param {string} newEmail - New email to update user's email to
 * @returns {Promise}
 */
export const updateUserInfo = newInfo => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();

    if (currentUser.id) {
      db.collection('users')
        .doc(currentUser.id)
        .set(newInfo, { merge: true })
        .then(() => {
          dispatch(loadUser({ ...currentUser, ...newInfo }));
          resolve('Account updated!');
        })
        .catch(error => reject(error));
    } else {
      reject(Error('User not signed in'));
    }
  });

/**
 * Update current user's password
 * TODO - Test this function
 * @param {string} newPassword
 * @param {string} confirmPassword
 * @returns {Promise}
 */
export const updateUserPassword = (newPassword, confirmPassword) => () =>
  new Promise((resolve, reject) => {
    if (newPassword === confirmPassword) {
      auth.currentUser
        .updatePassword(newPassword)
        .then(() => resolve('Password successfully chnaged!'))
        .catch(error => reject(error));
    } else {
      reject(Error("Passwords don't match!"));
    }
  });

/**
 * Adds plaid source to customer
 * @param {string} token - String token for Plaid to parse
 * @param {Object} metadata - Object with account data
 * @returns {Promise}
 */
export const addPlaidSource = (token, metadata) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();
    const customerId =
      process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
        ? currentUser.customer_id_dev
        : currentUser.customer_id;

    stripeApi
      .addPlaidSource(customerId, metadata.account_id, token)
      .then(response => {
        const payment_method = {
          token_id: metadata.account.id,
          last4: metadata.account.mask,
          type: 'ach',
          brand: metadata.account.name,
          bank_inst: metadata.institution.name,
          source_id: response.id,
          verified: true,
        };

        dispatch(addPaymentMethod(payment_method))
          .then(() => resolve(response))
          .catch(error => reject(error));
      })
      .catch(error => reject(error));
  });

/**
 * Adds bank data to user
 * @param {Object} bankData
 * @returns {Promise}
 */
export const addBankAccount = bankData => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();
    const customerId =
      process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
        ? currentUser.customer_id_dev
        : currentUser.customer_id;

    const bankAccount = {
      account_holder_name: bankData.accountName,
      account_holder_type: bankData.type,
      object: 'bank_account',
      country: bankData.country,
      account_number: bankData.accountNumber,
      routing_number: bankData.routingNumber,
    };

    stripeApi
      .addSource(customerId, bankAccount)
      .then(response => {
        if (response.type === 'StripeInvalidRequestError') {
          reject(Error(response.message));
        } else {
          const payment_method = {
            last4: response.last4,
            type: 'ach',
            brand: response.object,
            bank_inst: response.bank_name,
            source_id: response.id,
            verified: false,
          };

          dispatch(addPaymentMethod(payment_method))
            .then(() => resolve(response))
            .catch(error => reject(error));
        }
      })
      .catch(error => reject(error));
  });

/**
 * Verifies bank for user
 * @param {String} sourceId Source to be verified
 * @param {[Int]} amounts Array of amounts from verify charges
 */
export const verifySource = (sourceId, amounts) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();
    const customerId =
      process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
        ? currentUser.customer_id_dev
        : currentUser.customer_id;
    stripeApi
      .verifySource(customerId, sourceId, amounts)
      .then(response => {
        const { payment_methods } = currentUser;
        payment_methods.forEach(paymentMethod => {
          if (paymentMethod.source_id === sourceId) {
            paymentMethod.verified = true;
          }
        });
        currentUser.payment_methods = payment_methods;
        db.collection('users')
          .doc(currentUser.id)
          .set({ payment_methods }, { merge: true })
          .then(() => resolve(response))
          .catch(error => reject(error));
        dispatch(loadUser({ ...currentUser }));
      })
      .catch(error => reject(error));
  });

/**
 * Adds credit card to user
 * @param {object} token - Token with credit card data
 * @returns {Promise}
 */
export const addCreditCard = token => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();
    const customerId =
      process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
        ? currentUser.customer_id_dev
        : currentUser.customer_id;

    stripeApi
      .addSource(customerId, token.id)
      .then(response => {
        if (response.type === 'StripeInvalidRequestError') {
          reject(Error(response.message));
        } else {
          const payment_method = {
            token_id: token.id,
            source_id: response.id,
            last4: token.card.last4,
            type: token.type,
            brand: token.card.brand,
            verified: true,
          };
          dispatch(addPaymentMethod(payment_method))
            .then(() => resolve(response))
            .catch(error => reject(error));
        }
      })
      .catch(error => reject(error));
  });

export const addSepaSource = (stripeInstance, sepaInput) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();
    stripeApi
      .addSepaSource(stripeInstance, getCustomerId(currentUser), sepaInput)
      .then(response => {
        const payment_method = {
          source_id: response.id,
          type: response.type,
          brand: 'SEPA Direct Debit',
          last4: response.sepa_debit.last4,
          verified: true,
        };

        dispatch(addPaymentMethod(payment_method))
          .then(() => resolve(response))
          .catch(error => reject(error));
      });
  });

/**
 * Utility function with common logic to add payment methods to user
 * Updates the user in the store when complete
 * @param {*} payment_method
 * @returns {Promise}
 */
const addPaymentMethod = payment_method => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();
    const userRef = db.collection('users').doc(currentUser.id);

    db.runTransaction(t =>
      t.get(userRef).then(doc => {
        if (!doc.exists) {
          reject(Error("Error: can't find user"));
        }

        let userPaymentMethods = doc.get('payment_methods');
        userPaymentMethods = userPaymentMethods || [];
        userPaymentMethods.push(payment_method);

        currentUser.payment_methods = userPaymentMethods;
        dispatch(loadUser({ ...currentUser }));

        t.update(userRef, 'payment_methods', userPaymentMethods);
      })
    )
      .then(() => resolve())
      .catch(error => reject(error));
  });

/**
 * Function to delete payment method for user
 * Updates the user in store when complete
 * @param {Object} payment_method
 * @returns {Promise}
 */
export const deletePaymentMethod = payment_method => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();
    const customerId =
      process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
        ? currentUser.customer_id_dev
        : currentUser.customer_id;

    const userRef = db.collection('users').doc(currentUser.id);

    db.runTransaction(t =>
      t.get(userRef).then(doc => {
        if (!doc.exists) {
          reject(Error("Error: Can't find user"));
        }

        const paymentMethods = doc.get('payment_methods');
        currentUser.payment_methods = paymentMethods.filter(
          paymentMethod => paymentMethod.source_id !== payment_method.source_id
        );
        dispatch(loadUser({ ...currentUser }));

        t.update(userRef, 'payment_methods', currentUser.payment_methods);
      })
    )
      .then(() => {
        stripeApi.removeSource(customerId, payment_method.source_id);
        resolve();
      })
      .catch(error => reject(error));
  });

export const chargeCredits = chargeAmount => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();

    if (chargeAmount === 0) {
      resolve();
    } else if (chargeAmount > currentUser.credits) {
      reject(Error('Insufficient credit balance'));
    } else {
      dispatch(updateCredits(-1 * chargeAmount))
        .then(() =>
          resolve({
            message: `Succesfully charged ${chargeAmount} credits`,
            amount: chargeAmount,
          })
        )
        .catch(error => reject(error));
    }
  });

export const addCredits = creditAmount => dispatch =>
  new Promise((resolve, reject) => {
    if (creditAmount <= 1) {
      reject(Error('Please add a whole number of credits > 0'));
    }

    dispatch(updateCredits(creditAmount))
      .then(() =>
        resolve({
          message: `Succesfully added ${creditAmount} credits`,
          amount: creditAmount,
        })
      )
      .catch(error => reject(error));
  });

const updateCredits = amount => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentUser } = getState();
    const userRef = db.collection('users').doc(currentUser.id);

    let creditBalance = currentUser.credits;

    db.runTransaction(t =>
      t.get(userRef).then(doc => {
        if (!doc.exists) {
          reject(Error("Error: Can't find user"));
        }

        creditBalance = doc.get('credits');
        creditBalance += amount;

        currentUser.credits = creditBalance;
        dispatch(loadUser({ ...currentUser }));

        t.update(userRef, 'credits', currentUser.credits);
      })
    )
      .then(() => updateSalesforceAloaCredits(currentUser.email, creditBalance))
      .then(() => resolve());
  });
