import { combineReducers } from 'redux';
import { currentUser } from './user';
import { projects } from './project';
import { milestones } from './milestone';
import { collaborators } from './collaborator';
import { invites } from './invites';
import loading from './loading';
import appDialog from './appDialog';
import features from './features';
import app from './app';

const appReducer = combineReducers({
  currentUser,
  projects,
  milestones,
  loading,
  collaborators,
  invites,
  appDialog,
  features,
  app,
});

// Thanks Dan Abramov
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
