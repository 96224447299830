import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { Card } from '../../Objects';
import { updateProject, archiveProject } from '../../../actions/project';
import EditProjectHeader from './EditProjectHeader';
import ProjectHeader from './ProjectHeader';

// import 'antd/dist/antd.css';


import '../ProjectPage.scss';

function ProjectHeaderCard({
  project,
  currentUser,
  updateProject,
  archiveProject,
  collaborators,
  invites,
}) {
  const [editing, setEditing] = useState(false);

  return (
    <Card>
      {!editing ? (
        <ProjectHeader
          project={project}
          currentUser={currentUser}
          updateProject={updateProject}
          archiveProject={archiveProject}
          collaborators={collaborators}
          invites={invites}
        />
      ) : (
        <EditProjectHeader
          project={project}
          currentUser={currentUser}
          updateProject={updateProject}
          archiveProject={archiveProject}
          allCollaborators={collaborators}
          invites={invites}
          cancelEdit={() => setEditing(false)}
        />
      )}

      {currentUser.acc_type === 2 && (
        <Button
          className="project-header-edit-button"
          onClick={() => {
            setEditing(!editing);
          }}
        >
          {!editing ? 'Edit Project' : 'Cancel'}
        </Button>
      )}

      <div style={{ clear: 'both' }} />
    </Card>
  );
}

const mapStateToProps = state => ({
  collaborators: state.collaborators,
  invites: state.invites,
});

const mapDispatchToProps = {
  updateProject,
  archiveProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHeaderCard);
