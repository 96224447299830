import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

export default function DefaultPayerSetting({
  project,
  updateSettings,
  collaborators,
}) {
  const [defaultPayer, setDefaultPayer] = useState(project.defaultPayer);

  useEffect(() => {
    setDefaultPayer(project.defaultPayer);
  }, [project]);

  const payers = collaborators.filter(
    (collaborator) => collaborator && collaborator.role === 'payer'
  );

  const handleSelectPayer = (value) => {
    const newPayer = value
      ? collaborators.find((collaborator) => collaborator.id === value)
      : null;
    setDefaultPayer(newPayer);
    updateSettings({ defaultPayer: newPayer });
  };

  return (
    <div>
      <div className="edit-header">Default Payer</div>

      <PayerContainer>
        <PayerHeader>Select Payer</PayerHeader>
        <Select
          style={{ width: '100%' }}
          value={defaultPayer ? defaultPayer.id : null}
          onChange={handleSelectPayer}
        >
          <Option value={null}>None</Option>
          {payers.map((payer) => (
            <Option value={payer.id}>{payer.name}</Option>
          ))}
        </Select>
      </PayerContainer>
    </div>
  );
}

const PayerContainer = styled.div`
  color: #666666;
`;

const PayerHeader = styled.div`
  margin-top: 10px;
`;
