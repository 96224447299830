import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'stretch',
    fontSize: 12,
    fontFamily: 'Open Sans',
    marginTop: '15px',
  },
  header: {
    fontFamily: 'Open Sans',
    color: '#666666',
  },
  billingInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    fontSize: 10,
  },
  text: {
    flexGrow: 0,
  },
});

export default function BillingInfo({ customer }) {
  if (customer) {
    return (
      <View style={styles.container}>
        <View style={styles.billingInfoRow}>
          <Text style={styles.header}>Paid by</Text>
        </View>
        <View style={styles.billingInfoRow}>
          <Text style={styles.text}>{customer.name}</Text>
        </View>
        <View style={styles.billingInfoRow}>
          <Text style={styles.text}>{customer.billing_info.address_1}</Text>
        </View>
        {customer.billing_info.address_2 && (
          <View style={styles.billingInfoRow}>
            <Text style={styles.text}>{customer.billing_info.address_2}</Text>
          </View>
        )}
        <View style={styles.billingInfoRow}>
          <Text style={styles.text}>
            {customer.billing_info.city}, {customer.billing_info.state}{' '}
            {customer.billing_info.zip}
          </Text>
        </View>
      </View>
    );
  }

  return <View />;
}

BillingInfo.propTypes = {
  customer: PropTypes.object.isRequired,
};
