// Create our number formatter.
export const currencyFormatter = {
  format: (value, currency) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency ?? 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value / 100);
  },
};

export const getDate = (firestoreDate) => {
  if (!firestoreDate) {
    return new Date();
  } else if (firestoreDate.seconds) {
    return new Date(firestoreDate.seconds * 1000);
  }

  return new Date(firestoreDate);
};

export const getDueDateFromInvoiceDate = (invoiceDate, dueDate) => {
  // If there is no due date, default to 14 days after invoice date
  if (!dueDate) {
    if (!invoiceDate) {
      return new Date();
    }

    const newDueDate = new Date(getDate(invoiceDate));
    newDueDate.setDate(newDueDate.getDate() + 14);
    return newDueDate;
  }

  return getDate(dueDate);
};

export const getPaymentsForInvoice = (invoice) => {
  if (invoice.paymentData) {
    // Amount in payment data has gotten messy. Credits are represented a few different ways
    let creditsUsed = invoice.paymentData.creditsAmount;
    if (!creditsUsed && invoice.paymentData.amount?.credits) {
      creditsUsed = Number(invoice.paymentData.amount.credits) * 100;
    }

    return [
      {
        id: `payment-${invoice.id}`,
        credits_used: creditsUsed ?? 0,
        currency: invoice.paymentData.currency,
        amount: invoice.paymentData.paymentAmount * 100,
        date: invoice.paymentData.date,
        payment_method: `${invoice.paymentData.paymentMethod.brand} ${invoice.paymentData.paymentMethod.last4}`,
      },
    ];
  }

  // Default if payment data on the invoice is missing
  return [
    {
      id: `payment-${invoice.id}`,
      credits_used: 0,
      currency: 'usd',
      amount: Number(invoice.amount) * 100,
      date: `(unspecified date)`,
      payment_method: `(unspecified payment method)`,
    },
  ];
};

export const getLineItemsForInvoice = (invoice) => {
  if (invoice.line_items) {
    return invoice.line_items;
  }

  return [
    {
      quantity: 1,
      rate: invoice.amount,
      description:
        invoice.description !== '' ? invoice.description : '(No Description)',
    },
  ];
};
