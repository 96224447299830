import { message } from 'antd';
import firebase from 'firebase';
import { getPayers, sendAutoPayEmail } from '../api/email';
import dialogTypes from '../constants/dialogTypes';
import status from '../components/Milestone/constants';
import { payForMilestone, updateMilestone } from './milestone';
import { updateProject } from './project';
import { dayJsToTimestamp } from '../utils';
import { executeReminderEmailWorkflow } from '../api/workflow';

export const showAddPayment = (context, callback) => (dispatch, getState) => {
  if (!callback) {
    callback = () => dispatch(hideAppDialog());
  }

  if (!context) {
    // If context is not provided, use context from previous payment state
    // Callers should provide an empty context if they want to reset it
    if (getState().appDialog && getState().appDialog.context) {
      // eslint-disable-next-line prefer-destructuring
      context = getState().appDialog.context;
    } else {
      context = {};
    }
  }

  dispatch({
    type: 'SHOW_ADD_PAYMENT',
    dialogType: dialogTypes.addPayment,
    context,
    callback,
  });
};

export const createPdf = {
  invoice: (milestone, project) => openPdf('invoice', milestone, project),
  receipt: (milestone, project) => openPdf('receipt', milestone, project),
};

const openPdf = (type, milestone, project) => (dispatch) => {
  const callback = () => dispatch(hideAppDialog());

  dispatch({
    type: 'SHOW_MILESTONE_PDF',
    callback,
    pdfType: type,
    milestone,
    project,
  });
};

export const clearPaymentOAuthContext = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_PAYMENT_OAUTH_CONTEXT',
  });
};

export const showMilestoneActions = (dialogData, callback) => (dispatch) => {
  if (!callback) {
    callback = () => dispatch(hideAppDialog());
  }

  if (!dialogData) {
    dialogData = {};
  }

  dispatch({
    type: 'SHOW_MILESTONE_ACTIONS',
    dialogType: dialogTypes.milestoneActions,
    dialogData,
    callback,
  });
};

export const showUseCredits = (dialogData, callback) => (dispatch) => {
  if (!callback) {
    callback = () => dispatch(hideAppDialog());
  }
  dispatch({
    type: 'SHOW_USE_CREDITS',
    dialogType: dialogTypes.useCredits,
    dialogData,
    callback,
  });
};

export const hideAppDialog = () => (dispatch) => {
  dispatch({
    type: 'HIDE_APP_DIALOG',
  });
};

export const setAppDialogTitle = (title) => (dispatch) => {
  dispatch({
    type: 'SET_APP_DIALOG_TILE',
    data: title,
  });
};

export const agreeToMilestone =
  (milestone, project, paymentMethod, credits, shouldHideAppDialog) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { currentUser } = getState();
      const customerId =
        process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
          ? currentUser.customer_id_dev
          : currentUser.customer_id;

      if (paymentMethod) {
        const chargeDescription = `AloaPay Charge: ${milestone.name}`;

        // startLoading(paid);
        dispatch(
          payForMilestone(
            customerId,
            milestone,
            project,
            paymentMethod,
            credits,
            chargeDescription
          )
        )
          .then((result) => {
            if (shouldHideAppDialog) {
              dispatch(hideAppDialog());
            }
            resolve(result);
          })
          .catch((error) => {
            if (error.type === 'firebaseError') {
              dispatch(hideAppDialog());
            }
            reject(error);
          });
      } else {
        if (shouldHideAppDialog) {
          dispatch(hideAppDialog());
        }
        reject(Error('Sorry, you must select a payment method'));
      }
    });

export const payRemainingMilestoneAmount =
  (milestone, project, paymentMethod) => (dispatch) =>
    new Promise((resolve, reject) => {
      const paymentAmount = milestone.amountPending;
      const milestoneAmountAdjusted = {
        ...milestone,
        amount: paymentAmount,
      };

      // TODO - The multi-payment milestone does not work yet
      dispatch(
        agreeToMilestone(milestoneAmountAdjusted, project, paymentMethod)
      )
        .then(() => {
          milestone.amountPending = false;
          if (project.payments) {
            project.payments.forEach((payment, index) => {
              if (
                payment.item.id === milestone.id &&
                payment.amount === paymentAmount * 100 &&
                !payment.paid
              ) {
                project.payments[index].paid = true;
                project.payments[index].date = dayJsToTimestamp(
                  dayjs(new Date(), 'MM/DD/YYYY')
                );
              }
            });
          }

          dispatch(updateProject(project)).then(() =>
            dispatch(updateMilestone(project.id, milestone)).then((result) =>
              resolve(result)
            )
          );
        })
        .catch((error) => reject(error));
    });

export const rejectMilestone = (milestone, project, reason) => (dispatch) =>
  new Promise((resolve, reject) => {
    milestone.status = status.declined;
    milestone.decline_reason = reason;
    dispatch(updateMilestone(project.id, milestone))
      .then(() => {
        resolve({ message: 'Update sent!' });
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        dispatch(hideAppDialog());
      });
  });

export const updateMilestoneStatus =
  (milestone, project, newStatus, shouldHideAppDialog) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      milestone.status = newStatus;
      if (newStatus === status.pendingApproval) {
        milestone.invoice_created_date = new Date();

        // If the milestone is being marked as complete, set the milestone invoice date to the current date
        if (!milestone.due_date) {
          milestone.due_date = firebase.firestore.Timestamp.fromDate(
            new Date()
          );
        }

        // If there is no net_due_date set, let's set it to 14 days from now
        if (!milestone.net_due_date) {
          const newDueDate = new Date();
          newDueDate.setDate(newDueDate.getDate() + 14);
          milestone.net_due_date =
            firebase.firestore.Timestamp.fromDate(newDueDate);
        }

        const payers = getPayers(project, getState().collaborators);

        executeReminderEmailWorkflow(payers, milestone, project);
      }

      dispatch(updateMilestone(project.id, milestone))
        .then(() => {
          if (
            newStatus === status.pendingApproval &&
            project.autoPay &&
            project.autoPay.enabled
          ) {
            return dispatch(autoPayMilestone(milestone, project));
          }

          return Promise.resolve();
        })
        .then(() => {
          resolve({ message: 'Status updated!' });
        })
        .catch((error) => reject(error))
        .finally(() => {
          if (shouldHideAppDialog) {
            dispatch(hideAppDialog());
          }
        });
    });

const autoPayMilestone = (milestone, project) => (dispatch) =>
  new Promise((resolve, reject) => {
    const customerId =
      process.env.REACT_APP_ENV === 'dev' &&
      project.autoPay.payer.customer_id_dev
        ? project.autoPay.payer.customer_id_dev
        : project.autoPay.payer.customer_id;
    const chargeDescription = `AloaPay Charge: ${milestone.name}`;

    dispatch(
      payForMilestone(
        customerId,
        milestone,
        project,
        project.autoPay.paymentMethod,
        0,
        chargeDescription,
        project.autoPay.payer
      )
    )
      .then((result) => {
        sendAutoPayEmail(
          project.autoPay.payer,
          project.autoPay.paymentMethod,
          milestone,
          project
        );
        message.success(
          'Auto-pay complete! An email was sent to the payer with the charge details.'
        );
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
