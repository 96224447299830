import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { usePlaidLink } from 'react-plaid-link';
import {
  getStateAfterOauth,
  saveStateBeforeOauth,
} from '../../../../api/plaid/helpers';
import { clearPaymentOAuthContext } from '../../../../actions/appDialog';

export default function PlaidLink({ token, setLoading, handleAddPlaidSource }) {
  const { context } = useSelector(state => state.appDialog);

  const dispatch = useDispatch();

  const onEvent = useCallback(
    eventName => {
      if (eventName === 'OPEN_OAUTH') {
        saveStateBeforeOauth(context, token);
      } else if (eventName === 'FAIL_OAUTH' || eventName === 'CLOSE_OAUTH') {
        getStateAfterOauth();
      } else if (eventName === 'TRANSITION_VIEW') {
        dispatch(clearPaymentOAuthContext());
      } else if (eventName === 'EXIT') {
        setLoading(false);
      }
    },
    [token, context]
  );

  const fromOAuth = useCallback(
    () => context && context.receivedRedirectUri !== undefined,
    [context]
  );

  const config = {
    // When re-initializing Link after OAuth redirection, the same
    // Link token from the first initialization must be used
    token,
    onSuccess: (token, metadata) => {
      handleAddPlaidSource(token, metadata)
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    },
    onEvent,
  };

  if (fromOAuth()) {
    config.receivedRedirectUri = context.receivedRedirectUri;
  } else if (config.receivedRedirectUri) {
    delete config.receivedRedirectUri;
  }

  const { open, ready } = usePlaidLink(config);

  // this opens link as soon as it's ready
  useEffect(() => {
    if (ready && fromOAuth()) {
      open();
    }
  }, [ready, open]);

  const openPlaid = useCallback(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return (
    <Button type="primary" onClick={openPlaid}>
      Connect a Bank Account
    </Button>
  );
}
