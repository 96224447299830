import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Select, Avatar, message } from 'antd';

const { Option } = Select;

export default function CollaboratorCard({
  currentUser,
  project,
  collaborator,
  removeCollaboratorFromProject,
  updateCollaboratorRoleInProject,
}) {
  const [changes, setChanges] = useState(collaborator);

  const removeCollaborator = () => {
    removeCollaboratorFromProject(collaborator, project)
      .then(() => {
        setChanges(null);
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  const handleChange = value => {
    updateCollaboratorRoleInProject(collaborator, project, value)
      .then(collaborator => {
        setChanges(collaborator);
      })
      .catch(error => {
        console.log(error);
        message.error(error.message);
      });
  };

  const makeInitials = () => {
    const { name } = collaborator;
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  };

  if (changes === null) {
    return null;
  }

  return (
    <div className="edit-collaborator-card clearfix">
      {collaborator.image ? (
        <div style={{ display: 'inline-block', float: 'left' }}>
          <Avatar
            className="edit-collaborator-avatar"
            shapre="square"
            size="large"
            src={collaborator.image}
          />
        </div>
      ) : (
        <div style={{ display: 'inline-block', float: 'left' }}>
          <Avatar className="edit-collaborator-avatar">{makeInitials()}</Avatar>
        </div>
      )}

      <div className="edit-collaborator-name">{collaborator.name}</div>

      {/* Ability to edit collaborators if user is admin */}
      {currentUser.acc_type === 2 && (
        <div className="edit-collaborator-role-section">
          <div style={{ display: 'inline-block', marginRight: '5px' }}>
            {currentUser.acc_type === 2 && (
              <Select
                defaultValue={collaborator.role}
                style={{ width: 100 }}
                onChange={handleChange}
                disabled={currentUser.acc_type !== 2}
              >
                <Option value="viewer">Viewer</Option>
                <Option value="payer">Payer</Option>
                <Option value="payee">Payee</Option>
              </Select>
            )}
          </div>
          <div style={{ display: 'inline-block' }}>
            <Button
              shape="circle"
              icon={<CloseOutlined />}
              style={{
                boxShadow: 'none',
                border: 'none',
                background: 'none',
              }}
              onClick={removeCollaborator}
              disabled={collaborator.id === currentUser.id}
            />
          </div>
        </div>
      )}
    </div>
  );
}
