/* eslint-disable no-console */
import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Avatar, message } from 'antd';
import firebase, { auth } from '../../fire';
import SettingsCard from '../Objects/SettingsCard';
import { accountStyles } from '../../styles';
import { getBase64, id } from '../../utils';

// import 'antd/dist/antd.css';


const newPasswordDefault = {
  pass1: '',
  pass2: '',
};

export default function BillingInfoCard({
  currentUser,
  saveBillingInfo,
  updateUserPassword,
}) {
  // eslint-disable-next-line react/destructuring-assignment
  const [changes, setChanges] = useState(currentUser);
  const [newPassword, setNewPassword] = useState(newPasswordDefault);
  const [newEmail, setNewEmail] = useState(auth.currentUser.email);
  const [open, setOpen] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);

  // const handleClick = newMessage => {
  //   setOpen(true);
  //   setMessage(newMessage);
  // };
  const handleClick = newMessage => {
    // console.log(newMessage);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      setOpen(false);
    }
  };

  const handleChange = (event, type) => {
    if (type === 'name') {
      changes.name = event.target.value;
    }

    if (type === 'company') {
      changes.company = event.target.value;
    }

    const billingInfo = changes.billing_info;

    if (type === 'address_1') {
      billingInfo.address_1 = event.target.value;
    }

    if (type === 'address_2') {
      billingInfo.address_2 = event.target.value;
    }

    if (type === 'city') {
      billingInfo.city = event.target.value;
    }

    if (type === 'state') {
      billingInfo.state = event.target.value;
    }

    if (type === 'zip') {
      billingInfo.zip = event.target.value;
    }

    changes.billing_info = billingInfo;

    // Do something special for email
    let tempNewEmail = newEmail;
    if (type === 'email') {
      tempNewEmail = event.target.value;
    }

    // For passwords
    if (type === 'pass1') {
      newPassword.pass1 = event.target.value;
    }

    if (type === 'pass2') {
      newPassword.pass2 = event.target.value;
    }

    setChanges(Object.assign({}, changes));
    setNewPassword(newPassword);
    setNewEmail(tempNewEmail);
  };

  // TODO: Abstract this to actions
  // Do as part of SSO - going to have to migrate accounts
  const imageUpload = e => {
    setAvatarLoading(true);
    const file = e.target.files[0];
    if (file.size > 3000000) {
      message.error('Sorry, file must be less than 3 Mb');
      setAvatarLoading(false);
      return;
    }
    getBase64(file, base64 => {
      // eslint-disable-next-line no-undef
      localStorage.fileBase64 = base64;
      // console.log("file stored", base64);
    });

    // Create a root reference
    const storageRef = firebase.storage().ref();

    const randomId = id();
    const uploadTask = storageRef
      .child(`${currentUser.id}/${randomId}.jpg`)
      .put(file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded
        // and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //   console.log(`Upload is ${progress}% done`);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            //    console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            //     console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      () => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          handleClick('Image Ready');
          currentUser.image = downloadURL;
          setAvatarLoading(false);
          setChanges({ ...currentUser });
        });
      }
    );
  };

  const saveBillingInfoChanges = () => {
    const newBillingInfo = changes;

    saveBillingInfo(newBillingInfo, newEmail)
      .then(result => {
        message.success(result);
      })
      .catch(error => {
        message.error(error);
      });
  };

  const changePassword = () => {
    updateUserPassword(newPassword.pass1, newPassword.pass2)
      .then(() => {
        message.success('Password successfully changed!');
        setNewPassword(newPasswordDefault);
      })
      .catch(error => {
        message.error(error.message);
      })
      .finally(() => {
        setNewPassword({ pass1: '', pass2: '' });
      });
  };

  return <>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />

    <SettingsCard name="Account Info">
      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
        }}
      >
        Account Image
      </div>
      <label htmlFor="imageFile" style={{ cursor: 'pointer' }}>
        <input
          style={{ display: 'none' }}
          type="file"
          id="imageFile"
          name="imageFile"
          onChange={imageUpload}
        />
        {!avatarLoading && currentUser.image && (
          <Avatar
            size={100}
            src={currentUser.image}
            style={{ cursor: 'pointer' }}
          />
        )}
        {!avatarLoading && !currentUser.image && (
          <Avatar
            size={100}
            style={{
              color: '#f56a00',
              backgroundColor: '#fde3cf',
              verticalAlign: 'middle',
              cursor: 'pointer',
            }}
          >
            Change
          </Avatar>
        )}
        {avatarLoading && (
          <Avatar style={{ backgroundColor: '#fff' }} size={100}>
            <LoadingOutlined
              style={{
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: '25px',
              }} />
          </Avatar>
        )}
      </label>
      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        Name
      </div>
      <input
        value={changes.name}
        style={accountStyles.lineTextBox}
        onInput={e => handleChange(e, 'name')}
      />
      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        Company
      </div>
      <input
        value={changes.company}
        style={accountStyles.lineTextBox}
        onInput={e => handleChange(e, 'company')}
      />
      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        Email
      </div>

      <input
        value={newEmail}
        style={accountStyles.lineTextBox}
        onInput={e => handleChange(e, 'email')}
      />
      {/* {props.currentUser.acc_type === 2 &&
        <div>
          <div style={{fontSize: "17px",fontWeight: 500,
          fontFamily: "Avenir Next", marginTop: "20px"}}>
            Developer Mode
          </div>
          <Switch onChange={onChange.bind(this)} checked={currentUser.devEnabled} />
        </div>
      } */}

      <div style={{ float: 'right', paddingTop: 20, paddingBottom: 20 }}>
        <Button type="primary" onClick={saveBillingInfoChanges}>
          Save Account Info
        </Button>
      </div>
      <div style={{ clear: 'right' }} />
    </SettingsCard>

    <SettingsCard name="Security">
      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        New Password
      </div>
      <input
        type="password"
        value={newPassword.pass1}
        style={accountStyles.lineTextBox}
        onInput={e => handleChange(e, 'pass1')}
      />

      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        Confirm Password
      </div>
      <input
        type="password"
        value={newPassword.pass2}
        style={accountStyles.lineTextBox}
        onInput={e => handleChange(e, 'pass2')}
      />

      <div style={{ float: 'right', paddingTop: 20, paddingBottom: 20 }}>
        <Button type="primary" onClick={changePassword}>
          Change Password
        </Button>
      </div>

      <div style={{ clear: 'right' }} />
    </SettingsCard>

    <SettingsCard name="Billing Info">
      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        Billing Address 1
      </div>
      <input
        value={changes.billing_info.address_1}
        style={accountStyles.lineTextBox}
        onInput={e => handleChange(e, 'address_1')}
      />

      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        Billing Address 2
      </div>
      <input
        value={changes.billing_info.address_2}
        style={accountStyles.lineTextBox}
        onInput={e => handleChange(e, 'address_2')}
      />

      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        City
      </div>
      <input
        value={changes.billing_info.city}
        style={accountStyles.lineTextBox}
        onInput={e => handleChange(e, 'city')}
      />

      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        State
      </div>
      <input
        value={changes.billing_info.state}
        style={accountStyles.lineTextBox}
        onInput={e => handleChange(e, 'state')}
      />

      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        Zip Code
      </div>
      <input
        value={changes.billing_info.zip}
        style={accountStyles.lineTextBox}
        onInput={e => handleChange(e, 'zip')}
      />

      <div style={{ float: 'right', paddingTop: 20, paddingBottom: 20 }}>
        <Button type="primary" onClick={saveBillingInfoChanges}>
          Save Billing Info
        </Button>
      </div>

      <div style={{ clear: 'right' }} />
    </SettingsCard>
  </>;
}
