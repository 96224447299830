import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// Components
import BillingInfoCard from './BillingInfoCard';
import PaymentMethodCards from './PaymentMethodCards';
import PayoutMethodCards from './PayoutMethodCards';
import ModalOverlay from '../Objects/ModalOverlay';
import './Account.scss';

// Actions
import {
  logoutUser,
  saveBillingInfo,
  updateUserPassword,
  verifySource,
  deletePaymentMethod,
} from '../../actions/user';
import { showAddPayment } from '../../actions/appDialog';
// Lazily load AddPaymentModal to not block main component rendering

const mapStateToProps = (state) => ({
  projects: state.projects,
  currentUser: state.currentUser,
  transactions: state.transactions,
});

const mapDispatchToProps = {
  logoutUser,
  saveBillingInfo,
  updateUserPassword,
  showAddPayment,
  verifySource,
  deletePaymentMethod,
};

const style = {
  logoutButton: {
    color: '#ababab',
    fontFamily: 'Sofia Pro',
    fontSize: '15px',
    float: 'right',
    marginTop: '0.3em',
    marginBottom: 30,
    cursor: 'pointer',
  },
  tAndCButton: {
    color: '#ababab',
    fontFamily: 'Sofia Pro',
    fontSize: '15px',
    float: 'left',
    marginTop: '0.3em',
    marginBottom: 30,
    cursor: 'pointer',
  },
};

function Account({
  currentUser,
  saveBillingInfo,
  updateUserPassword,
  logoutUser,
  showAddPayment,
  verifySource,
  deletePaymentMethod,
  location,
}) {
  const [open, setOpen] = useState(false);
  const [pdfSource, setPdfSource] = useState('');

  useEffect(() => {
    if (location.pathname.indexOf('verify') !== -1) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  });

  if (currentUser) {
    return (
      <div className="invoicePage">
        <ModalOverlay
          open={open}
          src={pdfSource}
          type="Terms and Conditions"
          handleClose={() => {
            setOpen(false);
          }}
        />
        <BillingInfoCard
          billing_info={currentUser.billing_info}
          currentUser={currentUser}
          saveBillingInfo={saveBillingInfo}
          updateUserPassword={updateUserPassword}
        />

        {currentUser.acc_type !== 1 && (
          <PaymentMethodCards
            currentUser={currentUser}
            showAddPayment={showAddPayment}
            verifySource={verifySource}
            deletePaymentMethod={deletePaymentMethod}
          />
        )}

        {currentUser.acc_type === 1 && (
          <PayoutMethodCards currentUser={currentUser} />
        )}

        <a style={style.logoutButton} onClick={logoutUser}>
          Log Out
        </a>
      </div>
    );
  }
  return <div>Loading...</div>;
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
