import React from 'react';
import { Radio, Space } from 'antd';
import moment from 'moment';
import { numberWithCommas } from '../../../utils';

const RadioGroup = Radio.Group;

export default function SelectPaymentAmount({
  project,
  milestone,
  paymentAmount,
  setPaymentAmount,
  paymentMethod,
}) {
  return (
    <RadioGroup
      value={paymentAmount}
      onChange={(event) => setPaymentAmount(event.target.value)}
      style={{ width: '100%' }}
    >
      <Space direction="vertical">
        {project.payments
          .filter((payment) => payment.item.id === milestone.id)
          .map((payment) => (
            <Radio
              style={{
                width: '100%',
              }}
              key={payment.amount}
              value={payment.amount}
              disabled={payment.paid}
            >
              {labelForPayment(
                payment,
                paymentMethod,
                payment.amount === paymentAmount
              )}
            </Radio>
          ))}
      </Space>
    </RadioGroup>
  );
}

const labelForPayment = (payment) => {
  return (
    <>
      {`$${numberWithCommas(payment.amount / 100)}`}
      {payment.paid && (
        <span style={{ float: 'right', marginRight: '20px' }}>
          <span style={{ marginRight: '10px' }}>Paid</span>
          {moment(payment.date.toDate()).format('MM/DD/YYYY')}
        </span>
      )}
    </>
  );
};
