import React from 'react';
import styled from 'styled-components';

export default function NoMilestonesMessage() {
  return (
    <MessageDiv>
      No milestones left to pay
      <span style={{ marginLeft: '4px' }} role="img" aria-label="thumbs up">
        👍
      </span>
      <br />
      Use your credits on your next project!
    </MessageDiv>
  );
}

const MessageDiv = styled.div`
  font-size: 16px;
  text-align: center;
`;
