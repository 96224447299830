import React, { useState } from 'react';
import { countries } from 'country-data';

import { Input, Radio, Button, Select, message, Form } from 'antd';
import { isArray } from 'util';

const { Option } = Select;

export default function BankForm({ submit, children }) {
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [confirmRoutingNumber, setConfirmRoutingNumber] = useState('');
  const [type, setType] = useState('individual');
  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    if (!accountName) {
      message.error('Account name required');
      setLoading(false);
    } else if (!accountNumber) {
      message.error('Account number required');
      setLoading(false);
    } else if (!confirmAccountNumber) {
      message.error('Confirm account number');
      setLoading(false);
    } else if (accountNumber !== confirmAccountNumber) {
      message.error('Account numbers do not match');
      setLoading(false);
    } else if (!routingNumber) {
      message.error('Routing number required');
      setLoading(false);
    } else if (!confirmRoutingNumber) {
      message.error('Confirm routing number required');
      setLoading(false);
    } else if (routingNumber !== confirmRoutingNumber) {
      message.error('Routing numbers do not match');
      setLoading(false);
    } else {
      submit({
        accountName,
        accountNumber,
        confirmAccountNumber,
        routingNumber,
        type,
        country: country.key,
      })
        .then(() => {
          message.success(
            'Added your bank account! Now just wait 1-2 business days to see the verification charges, and it can be approved 👍'
          );
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const accountNumbersMatch = () => {
    if (accountNumber && confirmAccountNumber) {
      return accountNumber === confirmAccountNumber;
    }
    return true;
  };

  const routingNumbersMatch = () => {
    if (routingNumber && confirmRoutingNumber) {
      return routingNumber === confirmRoutingNumber;
    }
    return true;
  };

  const countryOptions = [];
  Object.keys(countries).map((key) => {
    if (key.length === 2 && !isArray(countries[key])) {
      return countryOptions.push(
        <Option key={key}>{countries[key].name}</Option>
      );
    }
    return null;
  });

  return (
    <Form
      labelCol={{ span: 24 }} // Force label to take the full width of the row
      wrapperCol={{ span: 24 }} // Force input to take the full width of the row
      style={{ borderBottom: '0px', marginTop: '20px', maxWidth: 600 }}
    >
      <Form.Item
        className="add-credit-card-form-item"
        label="Account Holder Name"
      >
        <Input
          value={accountName}
          onChange={(event) => setAccountName(event.target.value)}
          placeholder="Account Holder Name"
        />
      </Form.Item>
      <Form.Item className="add-credit-card-form-item" label="Account Number">
        <Input
          value={accountNumber}
          onChange={(event) => setAccountNumber(event.target.value)}
          placeholder="Account Number"
        />
      </Form.Item>
      <Form.Item
        className="add-credit-card-form-item"
        label="Confirm Account Number"
        validateStatus={accountNumbersMatch() ? 'success' : 'error'}
        help={accountNumbersMatch() ? '' : 'Account numbers do not match'}
      >
        <Input
          value={confirmAccountNumber}
          onChange={(event) => setConfirmAccountNumber(event.target.value)}
          placeholder="Confirm Account Number"
        />
      </Form.Item>
      <Form.Item className="add-credit-card-form-item" label="Routing Number">
        <Input
          value={routingNumber}
          onChange={(event) => setRoutingNumber(event.target.value)}
          placeholder="Routing Number"
        />
      </Form.Item>
      <Form.Item
        className="add-credit-card-form-item"
        label="Confirm Routing Number"
        validateStatus={routingNumbersMatch() ? 'success' : 'error'}
        help={routingNumbersMatch() ? '' : 'Routing numbers do not match'}
      >
        <Input
          value={confirmRoutingNumber}
          onChange={(event) => setConfirmRoutingNumber(event.target.value)}
          placeholder="Confirm Routing Number"
        />
      </Form.Item>
      <Form.Item className="add-credit-card-form-item" label="Country">
        <Select
          value={country}
          showSearch
          labelInValue
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          onChange={setCountry}
          placeholder="Select Country"
        >
          {countryOptions}
        </Select>
      </Form.Item>
      <Form.Item label="Is this an individual or company account?">
        <Radio.Group
          value={type}
          onChange={(event) => setType(event.target.value)}
        >
          <Radio value="individual">Individual</Radio>
          <Radio value="company">Company</Radio>
        </Radio.Group>
      </Form.Item>
      <div className="clearFix">
        <Form.Item className="add-credit-card-form-item">
          <div style={{ float: 'right' }}>
            {children}
            <Button onClick={handleSubmit} loading={loading} type="primary">
              Add Bank Account
            </Button>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
}
