/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { Row, Col } from 'antd';
import { Card } from '../../Objects';
import { agreeToMilestone } from '../../../actions/appDialog';
import { numberWithCommas, aggregateAmountForStatus } from '../../../utils';
import DataCard from './DataCard';
import './DataCards.scss';

export default function DataCards({
  projects,
  currentUser,
  history,
  archived,
  milestones,
}) {
  if (projects.length > 0) {
    const aggregate_pending_approval = 0;
    const aggregate_paid = 0;
    const aggregate_in_progress = 0;

    const goToAnalytics = () => {
      history.push(`analytics/revenue`);
    };

    return (
      <div>
        <Row type="flex" justify="space-between">
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9 }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                goToAnalytics();
              }}
            >
              <DataCard
                history={history}
                title="Amount owed"
                subtitle={`${
                  aggregateAmountForStatus(
                    'pending_approval',
                    projects,
                    milestones
                  ).aggregateTotalMilestones
                } Milestones`}
                moneyData={
                  aggregateAmountForStatus(
                    'pending_approval',
                    projects,
                    milestones
                  ).aggregateTotalAmount
                }
              />
            </div>
          </Col>
          <Col xs={{ span: 11 }} md={{ span: 11 }} lg={{ span: 7 }}>
            <DataCard
              history={history}
              title="In Development"
              subtitle={`${
                aggregateAmountForStatus('in_progress', projects, milestones)
                  .aggregateTotalMilestones
              } Milestones`}
              moneyData={
                aggregateAmountForStatus('in_progress', projects, milestones)
                  .aggregateTotalAmount
              }
            />
          </Col>
          <Col xs={{ span: 11 }} md={{ span: 11 }} lg={{ span: 6 }}>
            <DataCard
              history={history}
              title="Total Collected"
              subtitle="All Time"
              moneyData={
                aggregateAmountForStatus('paid', projects, milestones)
                  .aggregateTotalAmount
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
  if (!archived) {
    return <Card>No projects found... Yet 😉</Card>;
  }
  return null;
}
