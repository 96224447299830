import React, { useState } from 'react';
import { Checkbox, Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

export default function AutoPaySettings({
  project,
  updateSettings,
  collaborators,
}) {
  const [enabled, setEnabled] = useState(
    project.autoPay ? project.autoPay.enabled : false
  );
  const [payer, setPayer] = useState(
    project.autoPay ? project.autoPay.payer : null
  );
  const [paymentMethod, setPaymentMethod] = useState(
    project.autoPay ? project.autoPay.paymentMethod : null
  );

  const payers = collaborators.filter(
    (collaborator) => collaborator && collaborator.role === 'payer'
  );

  const handleChangeEnabled = (e) => {
    setEnabled(e.target.checked);
    setPayer(null);
    setPaymentMethod(null);
    updateSettings({
      enabled: e.target.checked,
      payer: null,
      paymentMethod: null,
    });
  };

  const handleSelectPayer = (value) => {
    const newPayer = collaborators.find(
      (collaborator) => collaborator.id === value
    );
    setPayer(collaborators.find((collaborator) => collaborator.id === value));
    setPaymentMethod(null);
    updateSettings({ enabled, payer: newPayer, paymentMethod: null });
  };

  const handleSelectPaymentMethod = (value) => {
    setPaymentMethod(value);
    updateSettings({ enabled, payer, paymentMethod: value });
  };

  return (
    <div>
      <div className="edit-header">Auto Pay</div>

      <InfoText>
        If enabled, the selected payer will be charged automatically once a
        milestone is marked as
        <em> Due</em>
      </InfoText>

      <CheckboxContainer>
        <Checkbox onChange={handleChangeEnabled} checked={enabled}>
          Enabled
        </Checkbox>
      </CheckboxContainer>

      <PayerContainer enabled={enabled}>
        <PayerHeader>Select Payer</PayerHeader>
        <Select
          disabled={!enabled}
          style={{ width: '100%' }}
          value={payer ? payer.id : null}
          onChange={handleSelectPayer}
        >
          {enabled &&
            payers.map((payer) => (
              <Option value={payer.id}>{payer.name}</Option>
            ))}
        </Select>

        <PayerHeader>Select Payment Method</PayerHeader>
        <Select
          disabled={!enabled || !payer}
          style={{ width: '100%' }}
          value={paymentMethod}
          onSelect={handleSelectPaymentMethod}
        >
          {payer &&
            payer.payment_methods
              .filter((method) => method.verified)
              .map((method) => (
                <Option value={method.source_id}>
                  {getPaymentMethodText(method)}
                </Option>
              ))}
        </Select>
      </PayerContainer>
    </div>
  );
}

export const validateAutoPaySettings = (settings) => {
  let errorString = '';

  if (settings && settings.enabled) {
    if (!settings.payer) {
      errorString = 'Payer must be selected if Auto Pay is enabled';
    } else if (!settings.paymentMethod) {
      errorString = 'Payment method must be selected if Auto Pay is enabled';
    }
  }

  return errorString;
};

export const getPaymentMethodText = (method) =>
  `${method.bank_inst ? method.bank_inst : method.brand} ${method.last4}`;

const InfoText = styled.div`
  font-size: 12px;
`;

const CheckboxContainer = styled.div`
  margin-top: 10px;
`;

const PayerContainer = styled.div`
  color: ${(props) => (props.enabled ? '#666666' : '#cccccc')};
`;

const PayerHeader = styled.div`
  margin-top: 10px;
`;
