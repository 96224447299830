import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Button } from 'antd';

export function Card({ children, style, onClick }) {
  return (
    <Paper
      style={{
        marginBottom: 30,
        boxShadow: '0 1px 5px 0 rgba(0,0,0,.1)',
        borderRadius: '8px',
        padding: '20px',
        ...style,
      }}
      onClick={onClick}
    >
      <div
        style={{
          textAlign: 'left',
          fontFamily: 'Avenir Next',
          fontSize: '14px',
          fontWeight: 400,
          color: '#7d7d7d',
          textDecoration: 'none',
          marginTop: '2px',
        }}
      >
        {children}
      </div>
    </Paper>
  );
}

export function OutlineButton({ name, color, action }) {
  return (
    <div>
      <Button
        style={{
          float: 'right',
          color,
          borderColor: color,
          fontFamily: 'Avenir Next',
          fontWeight: 500,
          fontSize: '13px',
        }}
        onClick={action}
      >
        {name}
      </Button>
      <div style={{ clear: 'right' }} />
    </div>
  );
}

export function SolidButton({ name, action }) {
  return (
    <div>
      <div
        style={{
          fontFamily: 'Brown',
          fontSize: '13px',
          border: '1px solid',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px',
          padding: '7px',
          backgroundColor: '#056cff',
          color: '#ffffff',
          cursor: 'pointer',
          float: 'right',
        }}
        onClick={action}
      >
        <div
          style={{
            display: 'inline-block',
            fontSize: '13px',
            verticalAlign: 'middle',
          }}
        >
          {name}
        </div>
      </div>

      <div style={{ clear: 'right' }} />
    </div>
  );
}
