import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { showAddPayment, showMilestoneActions } from '../../actions/appDialog';
// import { addPlaidSource } from '../../actions/user';
import { getStateAfterOauth } from '../../api/plaid/helpers';

export default function OAuth() {
  const previousState = getStateAfterOauth();

  const dispatch = useDispatch();

  if (previousState) {
    let callback;

    if (previousState.milestone && previousState.project) {
      callback = paymentMethod =>
        dispatch(
          showMilestoneActions({
            project: previousState.project,
            milestone: previousState.milestone,
            newStatus: 'paid',
            paymentMethod,
          })
        );
    }

    dispatch(
      showAddPayment(
        {
          ...previousState,
          receivedRedirectUri: window.location.href,
        },
        callback
      )
    );

    return <Redirect to={previousState.path} />;
  }

  return <Redirect to="/" />;
}
