import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../Auth.css';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, message, Upload, Alert, Form } from 'antd';
import firebase from '../../fire';
import { Card } from '../Objects';
import { login, createUser } from '../../actions/user';
import { fetchInviteWithCode } from '../../actions/invites';
import { getBase64, id } from '../../utils';

import aloaSmallLineLogo from '../../images/aloa_small_line.png';

const FormItem = Form.Item;

const mapDispatchToProps = {
  login,
  createUser,
  fetchInviteWithCode,
};

function SignUp({
  inviteCode,
  login,
  fetchInviteWithCode,
  createUser,
  history,
}) {
  const [newUser, setNewUser] = useState({ image: '' });
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    fetchInviteWithCode(inviteCode)
      .then(invite => {
        setNewUser({ ...newUser, ...{ email: invite.email } });
      })
      .catch(error => {
        console.error(error);
        setInvalid(true);
      });
  }, [inviteCode]);

  const createAccount = () => {
    let goodToGo = true;

    if (!newUser.name) {
      goodToGo = false;
      message.error('Missing Name');
    } else if (!newUser.company) {
      goodToGo = false;
      message.error('Missing Company');
    } else if (!newUser.email) {
      goodToGo = false;
      message.error('Missing Email');
    } else if (!newUser.password) {
      goodToGo = false;
      message.error('Missing password');
    } else if (!newUser.passwordConfirm) {
      goodToGo = false;
      message.error('Missing confirm password');
    } else if (newUser.password !== newUser.passwordConfirm) {
      goodToGo = false;
      message.error('Passwords do not match');
    }

    setLoading(goodToGo);

    if (goodToGo) {
      createUser(newUser)
        .then(user => {
          login(user, newUser.password).then(() => history.push('/'));
        })
        .catch(error => {
          setLoading(false);
          message.error(error.message);
        });
    }
  };

  // TODO - This doesn't do anything yet..
  // eslint-disable-next-line no-unused-vars
  const enterPressed = event => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      // TODO - where is Handle Click
      // handleClick(event);
    }
  };

  const handleChange = (event, type) => {
    newUser[type] = event.target.value;
    setNewUser({ ...newUser });
  };

  const handleChangeImage = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      const storageRef = firebase.storage().ref();
      const randomId = id();

      const uploadTask = storageRef
        .child(`tempFolder/${randomId}.jpg`)
        .put(info.file.originFileObj);

      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        error => {
          message.error(error.message);
          setLoading(false);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            newUser.image = downloadURL;
            getBase64(info.file.originFileObj, imageUrl => {
              setImageUrl(imageUrl);
              setNewUser(newUser);
              setLoading(false);
            });
          });
        }
      );
    }
  };

  return (
    <div className="registerArea">
      {invalid && (
        <div>
          <Alert
            message="Invalid Invite URL"
            description="The invite URL is not valid, please double check the URL you are using or make sure the invite was not revoked"
            type="error"
          />
        </div>
      )}
      <img
        src={aloaSmallLineLogo}
        style={{ height: '40px', marginBottom: '40px', marginTop: '100px' }}
        alt="Logo"
      />
      <Card style={{ width: '100%' }}>
        <Form onSubmit={enterPressed}>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Upload
              name="avatar"
              listType="picture-card
              showUploadList={false}
              action="
              onChange={handleChangeImage}
            >
              {imageUrl ? (
                <img
                  style={{ width: '100px', margin: 'auto' }}
                  src={imageUrl}
                  alt="avatar"
                />
              ) : (
                <div>
                  <div className="ant-upload-text">Choose Profile Pic</div>
                </div>
              )}
            </Upload>
          </div>

          <FormItem>
            <Input
              value={newUser.name}
              onChange={e => handleChange(e, 'name')}
              prefix={<UserOutlined className="signup-icon" />}
              placeholder="Name"
            />
          </FormItem>
          <FormItem>
            <Input
              value={newUser.company}
              onChange={e => handleChange(e, 'company')}
              prefix={<UserOutlined className="signup-icon" />}
              placeholder="Company Name"
            />
          </FormItem>
          <FormItem>
            <Input
              value={newUser.email}
              onChange={e => handleChange(e, 'email')}
              prefix={<MailOutlined className="signup-icon" />}
              placeholder="Email"
              disabled
            />
          </FormItem>
          <FormItem>
            <Input
              value={newUser.password}
              onChange={e => handleChange(e, 'password')}
              prefix={<LockOutlined className="signup-icon" />}
              type="password"
              placeholder="Password"
            />
          </FormItem>
          <FormItem>
            <Input
              value={newUser.passwordConfirm}
              onChange={e => handleChange(e, 'passwordConfirm')}
              prefix={<LockOutlined className="signup-icon" />}
              type="password"
              placeholder="Confirm Password"
            />
          </FormItem>

          <Button
            type="primary"
            loading={loading}
            onClick={() => createAccount()}
            block
          >
            Create Account
          </Button>
        </Form>
      </Card>
      Have an account?
      <Link to="/login"> Sign in </Link>
    </div>
  );
}

export default connect(
  null,
  mapDispatchToProps
)(SignUp);
