import React from 'react';
import { Link } from 'react-router-dom';
import img404 from '../../images/404.png';

/**
 * 404 component to show when user goes to invalid path
 */
export default function NotFound() {
  return (
    <div style={{ textAlign: 'center' }}>
      <Link to="/">
        <img className="image" alt="" src={img404} />
        <div className="message">Nothing here! (click to go home)</div>
      </Link>
    </div>
  );
}
