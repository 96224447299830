/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import { useElements, IbanElement } from '@stripe/react-stripe-js';
import { Button, Form, Input } from 'antd';
import './IbanFormStyle.css';
import styled from 'styled-components';

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: 'DE',
  style: IBAN_STYLE,
};

export default function IbanForm({ onSubmit, disabled }) {
  const elements = useElements();

  const handleSubmit = (event) => {
    event.preventDefault();

    const iban = elements.getElement(IbanElement);
    const accountholderName = event.target['accountholder-name'].value;
    const email = event.target.email.value;

    onSubmit({
      iban,
      accountholderName,
      email,
    });
  };

  const { Item } = Form;

  return (
    <Form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '1rem',
        }}
      >
        <NameContainer>
          <StyledItem>
            Name
            <br />
            <Input
              name="accountholder-name"
              placeholder="Jenny Rosen"
              required
            />
          </StyledItem>
        </NameContainer>

        <EmailContainer>
          <StyledItem>
            Email Address
            <br />
            <Input
              name="email"
              type="email"
              placeholder="jenny.rosen@example.com"
              required
            />
          </StyledItem>
        </EmailContainer>
      </div>

      <IbanItem>
        IBAN
        <IbanElement options={IBAN_ELEMENT_OPTIONS} />
      </IbanItem>

      <Item>
        <Button type="primary" htmlType="submit" disabled={disabled}>
          Set up SEPA Direct Debit
        </Button>
      </Item>

      <MandateAcceptance>
        By providing your payment information and confirming this payment, you
        authorise (A) Aloa and Stripe, our payment service provider, to send
        instructions to your bank to debit your account and (B) your bank to
        debit your account in accordance with those instructions. As part of
        your rights, you are entitled to a refund from your bank under the terms
        and conditions of your agreement with your bank. A refund must be
        claimed within 8 weeks starting from the date on which your account was
        debited. Your rights are explained in a statement that you can obtain
        from your bank. You agree to receive notifications for future debits up
        to 2 days before they occur.
      </MandateAcceptance>
    </Form>
  );
}

const StyledItem = styled(Form.Item)`
  margin-bottom: 15px;
`;

const NameContainer = styled.span`
  float: left;
  width: 50%;
  min-width: 225x;
`;

const NameInput = styled.input`
  width: 95%;
  min-width: 225px;
  ::-webkit-input-placeholder {
    opacity: 60%;
  }
`;

const EmailContainer = styled.span`
  float: left;
  width: 50%;
  min-width: 225px;
`;

const EmailInput = styled.input`
  width: 100%;
  ::-webkit-input-placeholder {
    opacity: 60%;
  }
`;

const IbanItem = styled(Form.Item)`
  clear: both;
`;

const MandateAcceptance = styled.div``;
