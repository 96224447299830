import React from 'react';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Button, message } from 'antd';
import IbanForm from './IbanForm';
import { keys } from '../../../../constants';
import { addSepaSource } from '../../../../actions/user';

const stripePromise = loadStripe(keys.stripe);

export default function SepaDialogContent({ handleAddSepa, cancelActive }) {
  const dispatch = useDispatch();

  const handleSubmit = async sepaInput => {
    const stripeInstance = await stripePromise;

    try {
      const paymentMethod = await dispatch(
        addSepaSource(stripeInstance, sepaInput)
      );

      handleAddSepa(paymentMethod);
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div>
      <Elements stripe={stripePromise}>
        <IbanForm onSubmit={handleSubmit}>
          <Button className="add-payment-cancel-button" onClick={cancelActive}>
            Cancel
          </Button>
        </IbanForm>
      </Elements>
    </div>
  );
}
