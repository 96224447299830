import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import typography from '@material-ui/core/Typography';
// import { PayoutSchedulePopup } from './PayoutMethodPopups';
import styles from '../../styles';

// import 'antd/dist/antd.css';


import PaymentCard from './PaymentCard';

export default function PayoutMethodCards({ currentUser }) {
  const [active, setActive] = useState(null);

  return (
    (<Paper style={styles.paper}>
      <div
        style={{
          fontSize: 17,
          fontWeiht: typography.fontWeightMedium,
          padding: 15,
          backgroundColor: '#4C6BFF',
          color: 'white',
        }}
      >
        Payout Schedule & Methods
      </div>
      <div style={{ borderBottom: '1px solid #E4E4E4' }} />
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 30,
        }}
      >
        <div style={{ paddingTop: 30 }}>
          {currentUser.payment_methods && (
            <div>
              {currentUser.payment_methods.map(method => (
                <div
                  style={{
                    padding: 5,
                    marginBottom: 15,
                    border: '0.5px solid',
                    borderColor: '#e6e6e6',
                    borderRadius: '5px',
                  }}
                >
                  <PaymentCard user={currentUser} method={method} />
                </div>
              ))}
            </div>
          )}

          {active === 'schedule' && (
            // <PayoutSchedulePopup
            //   currentUser={currentUser}
            //   closeAction={handleClose}
            //   open
            // />
            (<div>Payout Schedule</div>)
          )}

          <div
            style={{
              color: '#8B89FF',
              fontFamily: 'Sofia Pro',
              fontSize: '15px',
              cursor: 'pointer',
            }}
            onClick={() => setActive('schedule')}
          >
            Add Payout Schedule
          </div>

          <div style={{}}>
            <Link
              to="/addPayoutMethod"
              style={{
                color: '#8B89FF',
                fontFamily: 'Sofia Pro',
                fontSize: '15px',
              }}
            >
              Add Payout Method
            </Link>
          </div>
        </div>
      </div>
    </Paper>)
  );
}
