import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import dialogTypes from '../../constants/dialogTypes';
import AddPaymentDialogContent from './AddPaymentDialogContent';
import MilestoneActionsDialogContent from './MilestoneActionsDialogContent';
import UseCreditsDialogContent from './UseCreditsDiaogContent';
import PDFViewer from './PDFs';
import { hideAppDialog } from '../../actions/appDialog';

const mapStateToProps = (state) => ({
  visible: state.appDialog.visible,
  type: state.appDialog.type,
  title: state.appDialog.title,
  callback: state.appDialog.callback,
});

const mapDispatchToProps = {
  hideAppDialog,
};

/**
 * AppDialog to walk user through main app actions
 */
function AppDialog({ visible, type, title, callback, hideAppDialog }) {
  const closable = type !== dialogTypes.pdf;
  const width = type === dialogTypes.pdf ? '800px' : '570px';

  const modalStyles = {
    pdf: {
      top: '20px',
    },
  };

  const style = {
    padding: '5px',
    zIndex: 100,
    ...modalStyles[type],
  };

  return (
    <Modal
      open={visible}
      footer={null}
      width={width}
      style={style}
      name={title}
      onCancel={hideAppDialog}
      closable={closable}
    >
      {/* Pass visible to content to reset state on visibility change */}
      {/* The purpose of the callback is to allow whoever opened the dialog to control what happens when done */}
      {/* Ex: We want the add payment modal opened during approve milestone to resume the approve milestone actions when done */}
      {type === dialogTypes.addPayment && (
        <AddPaymentDialogContent open={visible} callback={callback} />
      )}
      {type === dialogTypes.milestoneActions && (
        <MilestoneActionsDialogContent open={visible} callback={callback} />
      )}
      {type === dialogTypes.useCredits && (
        <UseCreditsDialogContent open={visible} callback={callback} />
      )}
      {type === dialogTypes.pdf && <PDFViewer close={hideAppDialog} />}
    </Modal>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppDialog);
