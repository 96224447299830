import React from 'react';
import typography from '@material-ui/core/Typography';
import { Card } from './index';

// import 'antd/dist/antd.css';


export default function SettingsCard({ name, children }) {
  return (
    <div>
      <div
        style={{
          fontSize: 16,
          fontWeight: typography.fontWeightMedium,
          paddingBottom: 15,
          color: '#4e4e4e',
          fontFamily: 'Brown',
          // fontWeight: 500,
          textAlign: 'left',
        }}
      >
        {name}
      </div>

      <Card>
        <div style={{ textAlign: 'left' }}>{children}</div>
      </Card>
    </div>
  );
}
