import React from 'react';
import './Milestone.scss';
import status from './constants';
import LineItems from './LineItems/LineItems';

export default function MilestoneDescription({
  milestone,
  project,
  conversionRates,
}) {
  return (
    <>
      {/* Milestone description */}

      {milestone.quickbooks_invoice_url &&
        milestone.status === status.pendingApproval && (
          <div className="milestone-description">
            <div className="description-text-error">
              ⚠️ Due to an issue with our payment processor, this invoice must
              be paid through Quickbooks. Please click the "Approve & Pay"
              button to pay this invoice through Quickbooks.
            </div>
          </div>
        )}

      <div className="milestone-description">{milestone.description}</div>

      {/* Show milestone decline reason if milestone declined and a reason given */}
      {milestone.status === 'declined' && milestone.decline_reason !== '' && (
        <div className="rejection-reason">
          <b>Rejection Reason</b>
          <br />
          {milestone.decline_reason}
        </div>
      )}

      {milestone.line_items && milestone.line_items.length > 0 && (
        <LineItems
          line_items={milestone.line_items}
          amount={milestone.amount}
          project={project}
          conversionRates={conversionRates}
          milestone={milestone}
        />
      )}
    </>
  );
}
