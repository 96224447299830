/* eslint-disable array-callback-return */
import React from 'react';
import { Alert } from 'antd';
import MilestoneCard from '../Milestone';
import { Card } from '../Objects';

// import 'antd/dist/antd.css';


// TODO - Gotta test this whole thing
export default function AttensionSection({
  currentUser,
  projects,
  deleteMilestone,
}) {
  const pendingMilestones = [];

  if (projects) {
    return (
      <div>
        {pendingMilestones.length > 0 && (
          <div style={{ marginBottom: '20px' }}>
            <Alert
              message="Action Needed"
              description="Projects cannot proceed (causing delay) until the following milestones have been approved."
              type="warning"
              showIcon
            />
          </div>
        )}

        {pendingMilestones.map(item => (
          <Card key={item}>
            <MilestoneCard
              milestone={item}
              dashboard
              project={item.project}
              currentUser={currentUser}
              deleteMilestone={deleteMilestone}
            />
          </Card>
        ))}
      </div>
    );
  }
}
