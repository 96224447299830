export const projects = (state = {}, action) => {
  const updatedObj = {};
  switch (action.type) {
    case 'LOAD_PROJECT':
      return {
        ...state,
        ...action.project,
      };
    case 'UPDATE_PROJECT':
      updatedObj[action.project.id] = action.project;
      return {
        ...state,
        ...updatedObj,
      };
    default:
      return state;
  }
};

export const projectIds = (state = { ids: [] }, action) => {
  const newIds = !state.ids.includes(action.projectId)
    ? state.ids.concat(action.projectId)
    : state.ids;
  switch (action.type) {
    case 'LOAD_PROJECT_ID':
      return {
        ids: newIds,
      };
    default:
      return state;
  }
};
