/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Modal, InputNumber, Button } from 'antd';
import styled from 'styled-components';
import { currencyFormat, isAdmin } from '../../../utils';

import { updateCollaboratorData } from '../../../actions/collaborator';
import { isNaN } from 'lodash';

export default function CollaboratorCard({ collaborator, invite, isInvite }) {
  const currentUser = useSelector((state) => state.currentUser);

  const [creditEditorVisible, setCreditEditorVisible] = useState(false);

  if ((!isInvite && !collaborator) || (isInvite && !invite)) {
    return null;
  }

  const dispatch = useDispatch();

  const makeInitials = () => {
    if (isInvite) {
      return invite.email.substring(0, 2).toUpperCase();
    }
    const { name } = collaborator;
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  };

  const shouldShowCredits = isAdmin(currentUser) && collaborator;

  const [creditsEditable, setCreditsEditable] = useState(
    collaborator?.credits || 0
  );

  useEffect(() => {
    setCreditsEditable(collaborator?.credits || 0);
  }, [collaborator]);

  return (
    <CardContainer className="clearfix">
      <Modal
        title={null}
        open={creditEditorVisible}
        onCancel={() => setCreditEditorVisible(false)}
        footer={null}
      >
        <div className="flex flex-col gap-6 items-start">
          <div className="text-lg">
            Edit credits for {collaborator?.name || 'No name'}
          </div>
          <InputNumber
            value={creditsEditable}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value && value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setCreditsEditable(value)}
            size="large"
            style={{ width: '50%' }}
          />

          <Button
            type="primary"
            onClick={() => {
              // Make sure credits is a number
              // Convert it
              const credits = parseFloat(creditsEditable);

              // if not a number, return
              if (isNaN(credits)) {
                return;
              }

              dispatch(
                updateCollaboratorData(collaborator.id, {
                  credits,
                })
              );
              setCreditEditorVisible(false);
            }}
          >
            Save Credits
          </Button>
        </div>
      </Modal>
      {collaborator && collaborator.image ? (
        <Avatar
          className="avatar-img"
          shapre="square"
          size="large"
          src={collaborator.image}
        />
      ) : (
        <Avatar className="avatar-initials" shapre="square" size="large">
          {makeInitials()}
        </Avatar>
      )}

      <TextSection>
        <div className="collaborator-name">
          {isInvite ? invite.email : collaborator.name}
        </div>

        <NameCompanyRole>
          {isInvite ? (
            '(invited)'
          ) : (
            <div>
              {collaborator.company && `${collaborator.company} `}
              {`(${collaborator.role})`}
            </div>
          )}
        </NameCompanyRole>

        {shouldShowCredits && (
          <Credits>
            {`Credits: ${currencyFormat(collaborator.credits || 0, 2)}`}{' '}
            <a
              className="cursor-pointer text-blue-700"
              onClick={() => setCreditEditorVisible(true)}
            >
              Edit
            </a>
          </Credits>
        )}
      </TextSection>
    </CardContainer>
  );
}

const CardContainer = styled.div`
  margin-top: 20px;
  clear: both;
`;

const TextSection = styled.div`
  float: left;
  max-width: 72%;
`;

const NameCompanyRole = styled.div`
  veritcal-align: middle;
`;

const Credits = styled.div`
  float: left;
`;
