import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { fetchLinkToken } from '../../../../api/plaid';
import PlaidLink from './PlaidLink';

export default function AddBankAccount({
  handleAddPlaidSource,
  cancelActive,
  addNonPlaidBank,
}) {
  const currentUser = useSelector(state => state.currentUser);
  const linkToken = useSelector(state =>
    state.appDialog.context && state.appDialog.context.linkToken
      ? state.appDialog.context.linkToken
      : null
  );

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(linkToken);

  useEffect(() => {
    if (loading && !linkToken) {
      fetchLinkToken(currentUser).then(token => {
        setToken(token);
        setLoading(false);
      });
    }
  }, [linkToken]);

  return (
    <div className="clearfix">
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        <PlaidButtonContainer>
          {token ? (
            <PlaidLink
              token={token}
              setLoading={setLoading}
              handleAddPlaidSource={handleAddPlaidSource}
            />
          ) : (
            <Button type="primary" disabled>
              Connect a Bank Account
            </Button>
          )}
        </PlaidButtonContainer>

        <div className="plaid-msg-div">
          {
            'We use Plaid for instant bank account verification. If you do not see your bank in the dialog opened above, click below to add your bank.'
          }
        </div>

        <NonPlaidBankContainer>
          <Button
            className="non-plaid-bank-button"
            onClick={() => {
              addNonPlaidBank();
            }}
          >
            {"I don't see my bank"}
          </Button>
        </NonPlaidBankContainer>

        <div className="add-bank-cancel-button" onClick={cancelActive}>
          <Button onClick={cancelActive}>Cancel</Button>
        </div>
      </Spin>
    </div>
  );
}

const PlaidButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 25px;
`;

const NonPlaidBankContainer = styled.div`
  width: 100%;
  text-align: center;
`;
