import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { Button } from 'antd';
import CreditCardForm from './CreditCardForm';
import { keys } from '../../../../constants';

export default function AddCreditCard({
  currentUser,
  handleAddCreditCard,
  cancelActive,
}) {
  const apiKey = keys.stripe;

  return (
    <div>
      <StripeProvider apiKey={apiKey}>
        <Elements>
          <CreditCardForm ccReveived={handleAddCreditCard} user={currentUser}>
            <Button
              className="add-payment-cancel-button"
              onClick={cancelActive}
            >
              Cancel
            </Button>
          </CreditCardForm>
        </Elements>
      </StripeProvider>
    </div>
  );
}
