/* eslint-disable import/no-cycle */
import axios from 'axios';
import { db } from '../fire';
import stripeApi from '../api/stripe';
import { removeMilestoneFromProject, addMilestoneToProject } from './project';
import {
  id,
  convertDateToUTC,
  isDevEnv,
  roundPrice,
  getEurConversionRate,
  getLateFeeAmount,
} from '../utils';
import { chargeCredits } from './user';

/**
 * Loads new milestone object into store
 * @param {Object} milestone Milestone object to load
 */
export const loadMilestone = (milestone) => ({
  type: 'LOAD_MILESTONE',
  milestone,
});

export const deleteMilestoneAction = (milestoneId) => ({
  type: 'DELETE_MILESTONE',
  milestoneId,
});

/**
 * Updates milestone object in store
 * @param {Object} milestone Milestone object to update
 */
export const updateMilestone = (projectId, milestone) => (dispatch) =>
  new Promise((resolve, reject) => {
    const milestoneRef = db
      .collection('projects')
      .doc(projectId)
      .collection('milestones')
      .doc(milestone.id);

    milestoneRef
      .set(milestone, { merge: true })
      .then(() => {
        dispatch({
          type: 'UPDATE_MILESTONE',
          milestone: { ...milestone },
        });
        resolve({ message: 'Updated milestone' });
      })
      .catch((error) => reject(error));
  });

/**
 * Create milestone
 * @param {String} projectId Project to create milestone in
 */
export const createMilestone =
  (projectId, late_fee_enabled = false) =>
    (dispatch) =>
      new Promise((resolve, reject) => {
        const milestoneId = id();

        const newMilestone = {
          id: milestoneId,
          name: 'New Milestone',
          amount: 0.0,
          description: '',
          status: 'work_pending',
          late_fee_enabled,
          project_id: projectId,
          date_started: convertDateToUTC(new Date()),
        };

        dispatch(loadMilestone(newMilestone));

        db.collection('projects')
          .doc(projectId)
          .collection('milestones')
          .doc(milestoneId)
          .set(newMilestone)
          .then(() => {
            dispatch(addMilestoneToProject(projectId, newMilestone));
            resolve(newMilestone);
          })
          .catch((error) => reject(error));
      });

/**
 * Action to delete milestone and update in store
 * @param {String} projectId Project to delete milestone from
 * @param {String} milestoneId Milestone to delete
 */
export const deleteMilestone = (projectId, milestoneId) => (dispatch) =>
  new Promise((resolve, reject) => {
    db.collection('projects')
      .doc(projectId)
      .collection('milestones')
      .doc(milestoneId)
      .delete()
      .then(() => {
        dispatch(deleteMilestoneAction(milestoneId));
        resolve({ message: 'Deleted milestone' });
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Loads milestones for project
 * @param {object} project Project object to fetch milestones for
 * @returns {Promise} Milestone IDs (use to backfill if milestoneOrder doesn't exist)
 */
export const fetchMilestonesForProject = (project) => (dispatch) =>
  new Promise((resolve, reject) => {
    db.collection('projects')
      .doc(project.id)
      .collection('milestones')
      .onSnapshot(
        (snapshot) => {
          const milestoneIds = [];
          let milestoneData = {};
          snapshot.forEach((doc) => {
            milestoneData = doc.data();
            milestoneData.id = doc.id;
            milestoneData.project_id = project.id;

            // If milestoneData amount is a string, convert it to a number
            if (typeof milestoneData.amount === 'string') {
              milestoneData.amount = Number(milestoneData.amount);
            }

            milestoneIds.push(doc.id);
            dispatch(loadMilestone(milestoneData));
          });
          resolve(milestoneIds);
        },
        (error) => {
          reject(error);
        }
      );
  });

/**
 * Send Payment data to Zapier when paid
 * @param {Object} milestone Milestone object to use as input
 * @param {String} project Project that milestone is a part of
 */
export const sendPaymentToZapier = (amount, milestone, project) => {
  const payProjectId = project.id;
  const payMilestoneName = milestone.name;

  if (!isDevEnv()) {
    const rawBody = `${amount}aloasplitchar${payProjectId}aloasplitchar${payMilestoneName}`;
    axios
      .post('https://hooks.zapier.com/hooks/catch/5791999/ou5vzor/', rawBody, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .catch((error) => {
        console.error('Error sending milestone action to zapier', error);
      });
  }
};

/**
 * Pay for milestone - Call Stripe API and Firebase to update, then update in store
 * @param {String} customer Customer id to charge in Stripe
 * @param {Object} milestone Milestone object to charge and update
 * @param {String} project Project that milestone is a part of
 * @param {String} paymentSourceId Id string of the payment source for the charge
 * @param {String} chargeDescription Description of the charge to create in Stripe
 */
export const payForMilestone =
  (
    customer,
    milestone,
    project,
    paymentSourceId,
    credits,
    chargeDescription,
    user
  ) =>
    (dispatch, getState) =>
      new Promise(async (resolve, reject) => {
        const newStatus = 'paid';
        const projectId =
          project && typeof project === 'string' ? project : project.id;

        const { currentUser } = getState();
        const paymentUser = user || currentUser;

        const paymentMethod = paymentUser.payment_methods.filter(
          (paymentMethod) => paymentMethod.source_id === paymentSourceId
        )[0];

        const customerData = {
          name: paymentUser.name,
          id: paymentUser.id,
          customer_id: customer,
          billing_info: paymentUser.billing_info,
          company: paymentUser.company,
        };

        if (credits) {
          credits = roundPrice(credits);
        }

        var lateFee = getLateFeeAmount(project, milestone);

        let paymentAmount = credits
          ? roundPrice(lateFee + milestone.amount - credits)
          : roundPrice(lateFee + milestone.amount);

        // For use in non US currency projects
        let creditsAmount = credits ? roundPrice(credits) : 0;

        const currency = paymentMethod.type === 'sepa_debit' ? 'eur' : 'usd';

        if (project.currency != null && project.currency === 'EUR') {
          const eurConversionRate = await getEurConversionRate();

          paymentAmount = (paymentAmount * eurConversionRate).toFixed(2);

          if (credits && credits > 0) {
            creditsAmount = (creditsAmount * eurConversionRate).toFixed(2);
          }
        }

        const paymentData = {
          customer: customerData,
          date: Date.now(),
          chargeDescription,
          paymentMethod,
          currency: project.currency || 'USD',
          paymentAmount,
          creditsAmount,
          lateFee,
        };

        stripeApi
          .newPayment(
            customer,
            paymentSourceId,
            Math.round(paymentAmount * 100),
            currency,
            chargeDescription
          )
          .then(async () => {
            const updatedMilestone = milestone;
            updatedMilestone.status = newStatus;
            updatedMilestone.paymentData = paymentData;

            let creditsError = false;

            if (credits > 0) {
              try {
                await dispatch(chargeCredits(credits));
                milestone.paymentData.amount = {
                  primary: paymentAmount,
                  credits,
                };
              } catch (error) {
                creditsError = true;
                reject(
                  Error(
                    'Whoops! There was an error charging your Aloa credits. Please reach out to a team member to help get your milestone approved.'
                  )
                );
              }
            }

            if (!creditsError) {
              dispatch(updateMilestone(projectId, updatedMilestone))
                .then(() => {
                  sendPaymentToZapier(paymentAmount, milestone, project);
                  resolve({
                    message: 'Succesfully Approved!',
                  });
                })
                .catch((error) => {
                  const firebaseError = Error(error.message);
                  firebaseError.type = 'firebaseError';
                  reject(firebaseError);
                });
            }
          })
          .catch((error) => {
            const stripeError = Error(error.message);
            stripeError.type = 'stripeError';
            reject(stripeError);
          });
      });

export const duplicateMilestone = (projectId, milestone) => (dispatch) =>
  new Promise((resolve, reject) => {
    const { net_due_date, ...milestoneWithoutNetDueDate } = milestone;
    const newMilestone = {
      ...milestoneWithoutNetDueDate,
      id: id(),
      name: `${milestone.name} (Copy)`,
      date_started: convertDateToUTC(new Date()),
    };

    dispatch(loadMilestone(newMilestone));

    db.collection('projects')
      .doc(projectId)
      .collection('milestones')
      .doc(newMilestone.id)
      .set(newMilestone)
      .then(() => {
        dispatch(addMilestoneToProject(projectId, newMilestone));
        resolve(newMilestone);
      })
      .catch((error) => reject(error));
  });