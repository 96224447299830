export default (loading = false, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING':
      return action.loading;
    case 'END_LOADING':
      return action.loading;
    default:
      return loading;
  }
};
