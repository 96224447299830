/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Components
import { MuiThemeProvider } from '@material-ui/core';
import Login from './components/Auth';
import ProjectPage from './components/ProjectPage';
import Dashboard from './components/Dashboard';
import Account from './components/Account';
import SignUp from './components/SignUp';
import SignupRedirect from './components/SignUp/Redirect';
import TopBar from './components/TopBar';
import NotFound from './components/404';
import AppDialog from './components/AppDialog';
import Loading from './components/Loading';
import EulaDialog from './components/EulaDialog';
import ARSummary from './components/Analytics/ARSummary';
import PlaidOAuth from './components/PlaidOAuth';
import Toggle, { On, Off, featureTypes } from './components/FeatureToggle';

// Other dependencies
import muiTheme from './constants/muiTheme';

function LoginRedirect({ auth0 }) {
  return (
    <Switch>
      <Route
        exact
        path="/callback"
        render={(props) => {
          auth0
            .handleAuthentication(props)
            .then(() => {
              props.history.push('/');
            })
            .catch((error) => {
              console.error(error.message);
              props.history.push('/');
            });
          return <Loading />;
        }}
      />
      <Route path="/">
        <Loading />
      </Route>
    </Switch>
  );
}

export const AppNotLoggedIn = ({ auth0 }) => (
  <Toggle feature={featureTypes.SSO}>
    <On>
      <LoginRedirect auth0={auth0} />
    </On>
    <Off>
      <Switch>
        <Route
          exact
          path="/register/:inviteCode"
          render={(props) => (
            <SignUp
              inviteCode={props.match.params.inviteCode}
              history={props.history}
            />
          )}
        />
        <Route path="*" component={Login} />
      </Switch>
    </Off>
  </Toggle>
);

export const App = () => (
  <MuiThemeProvider theme={muiTheme}>
    <TopBar />
    <AppDialog />
    <EulaDialog />
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/account" component={Account} />
      <Route exact path="/analytics/revenue" component={ARSummary} />
      <Route
        exact
        path="/project/:projectId"
        render={(props) => {
          window.scrollTo(0, 0);
          return (
            <ProjectPage
              projectId={props.match.params.projectId}
              history={props.history}
            />
          );
        }}
      />
      <Route
        exact
        path="/projects/:projectId"
        render={(props) => (
          <Redirect to={`/project/${props.match.params.projectId}`} />
        )}
      />
      <Route path="/addPaymentMethod" component={Dashboard} />
      <Route path="/register" component={SignupRedirect} />
      <Route path="/callback" render={() => <Redirect to="/" />} />
      <Route path="/plaid-oauth" component={PlaidOAuth} />
      <Route path="*" component={NotFound} />
    </Switch>
  </MuiThemeProvider>
);
