import React, { useState } from 'react';
import { Switch } from 'antd';

export default function LateFeeSettings({ project, updateSettings }) {
  const [enabled, setEnabled] = useState(
    project.late_fee_enabled ? project.late_fee_enabled : false
  );

  return (
    <div className="w-full">
      <div className="edit-header">Late fee</div>
      <div className="w-full flex flex-row gap-2 items-center justify-between">
        <span>Late fee enabled</span>
        <Switch
          checked={enabled}
          onChange={(checked) => {
            setEnabled(checked);
            updateSettings(checked);
          }}
        />
      </div>
    </div>
  );
}
