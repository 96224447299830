import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import status from './constants';
import { createPdf } from '../../actions/appDialog';
import { duplicateMilestone } from '../../actions/milestone';

export default function MilestoneButtons({
  currentUser,
  milestone,
  project,
  payer,
  openEdit,
  handleClickOpen,
}) {
  const dispatch = useDispatch();

  // Values for action buttons
  const { newStatus, buttonText, showReject } = getButtonSettings({
    milestone,
    currentUser,
  });

  // Convenient translation for user acc_type
  const userAdmin = currentUser.acc_type === 2;

  const paid = milestone.status === status.paid;
  const pendingApproval = milestone.status === status.pendingApproval;
  const showDownloadPdf =
    (paid || pendingApproval) &&
    (currentUser.role === 'payer' || currentUser.role === 'payee' || userAdmin);
  const pdfButtonFloat = paid ? 'right' : 'left';

  const milestonePayer =
    milestone.paymentData && milestone.paymentData.customer
      ? { data: milestone.paymentData.customer }
      : payer;

  const handleDuplicate = () => {
    dispatch(duplicateMilestone(project.id, milestone));
  };

  return (
    <div style={{ paddingTop: 10 }}>
      {/* Allow Milestone editing if user is admin */}
      {userAdmin && (
        <div style={{ paddingTop: 10 }}>
          <Button className="edit-button" type="dashed" onClick={openEdit}>
            Edit
          </Button>
          {!paid && (
            <Button className="edit-button" type="dashed" onClick={handleDuplicate}>
              Duplicate
            </Button>
          )}
        </div>
      )}

      {/* Render main action button for given milestone state and user role */}
      {buttonText && (
        <div style={{ float: 'right', paddingTop: 10 }}>
          <Button
            type="primary"
            onClick={() => {
              if (
                newStatus === status.paid &&
                milestone.quickbooks_invoice_url &&
                milestone.quickbooks_invoice_url !== ''
              ) {
                // Open the invoice in a new tab
                window.open(milestone.quickbooks_invoice_url, '_blank');
              } else {
                handleClickOpen(newStatus, milestonePayer);
              }
            }}
          >
            {buttonText}
          </Button>
        </div>
      )}

      {showReject && (
        <div style={{ float: 'right', paddingTop: 10, marginRight: 10 }}>
          <Button onClick={() => handleClickOpen(status.declined)}>
            Reject
          </Button>
        </div>
      )}

      {paid &&
        milestone.quickbooks_invoice_url &&
        milestone.quickbooks_invoice_url !== '' && (
          <div
            style={{
              float: `${pdfButtonFloat}`,
              paddingTop: 10,
              marginRight: 0,
            }}
          >
            <Button
              className="pdf-buttons"
              onClick={() => {
                // If there is a quickbooks url, open it in a new tab
                window.open(milestone.quickbooks_invoice_url, '_blank');
              }}
            >
              View on Quickbooks
            </Button>
          </div>
        )}

      {/* Show buttons to Generate Invoice + receipt */}
      {showDownloadPdf &&
        (!milestone.quickbooks_invoice_url ||
          milestone.quickbooks_invoice_url === '') && (
          <div style={{ float: `${pdfButtonFloat}`, paddingTop: 10 }}>
            <Button
              className="pdf-buttons"
              onClick={() => {
                dispatch(createPdf.invoice(milestone, project));
              }}
            >
              View Invoice
            </Button>
          </div>
        )}

      {paid && (
        <div
          style={{
            float: `${pdfButtonFloat}`,
            paddingTop: 10,
            marginRight: 0,
          }}
        >
          <Button
            className="pdf-buttons"
            onClick={() => {
              // If there is a quickbooks url, open it in a new tab
              if (
                milestone.quickbooks_invoice_url &&
                milestone.quickbooks_invoice_url !== ''
              ) {
                window.open(milestone.quickbooks_invoice_url, '_blank');
              } else {
                dispatch(createPdf.receipt(milestone, project));
              }
            }}
          >
            View Receipt
          </Button>
        </div>
      )}
    </div>
  );
}

const getButtonSettings = ({ milestone, currentUser }) => {
  const workPending = milestone.status === status.workPending;
  const declined = milestone.status === status.declined;
  const pendingApproval =
    milestone.status === status.pendingApproval || milestone.amountPending;
  const inProgress = milestone.status === status.inProgress;

  const userIsPayer = currentUser.role === 'payer';
  const userIsPayee = currentUser.role === 'payee';

  let newStatus = '';
  let buttonText = '';
  let showReject = false;

  if (userIsPayee) {
    if (workPending || declined) {
      newStatus = status.inProgress;
      buttonText = 'Mark as Started';
    } else if (inProgress) {
      newStatus = status.pendingApproval;
      buttonText = 'Mark as Complete';
    }
  }

  if (userIsPayer) {
    if (pendingApproval || declined) {
      newStatus = status.paid;
      buttonText = 'Approve & Pay';
    }

    showReject = pendingApproval && !milestone.amountPending;
  }

  return {
    newStatus,
    buttonText,
    showReject,
  };
};
