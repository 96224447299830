// import {typography} from '@material-ui/core/styles';

export default {
  navigation: {
    fontSize: 15,
    fontWeight: 200,
    color: 'grey',
    paddingBottom: 15,
    display: 'block',
  },
  title: {
    fontSize: '1.2em',
    paddingTop: '.50em',
    fontFamily: 'Avenir Next',
    fontWeight: 500,
    lineHeight: 'normal',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  smallTitle: {
    fontSize: '1em',
    fontWeight: 300,
    color: '#AEADAD',
    fontFamily: 'Avenir Next',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  smallTitleTime: {
    fontSize: '0.7em',
    fontWeight: 300,
    color: '#AEADAD',
    fontFamily: 'Avenir Next',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  subTitle: {
    paddingTop: '0.2em',
    paddingRight: '1.5em',
    paddingBottom: '1.0em',
    fontSize: '1.0em',
    fontWeight: 300,
    color: '#666',
    fontFamily: 'Avenir Next',
  },
  cardLeft: {
    padding: 30,
    marginTop: '1.3em',
    marginLeft: '25%',
    marginRight: '1%',
    width: '24.5%',
    float: 'left',
    marginBottom: '1.3em',
    fontFamily: 'Avenir Next',
  },
  cardRight: {
    padding: 30,
    marginTop: '1.3em',
    width: '24.5%',
    float: 'left',
    fontFamily: 'Avenir Next',
  },
  cardBoth: {
    marginTop: '1.3em',
    marginLeft: '25%',
    marginRight: '25%',
    clear: 'both',
    fontFamily: 'Avenir Next',
  },
  proPic: {
    marginTop: 15,
    height: '100%',
    width: '100%',
  },
  rightBlock: {
    float: 'left',
    marginLeft: 15,
    paddingBottom: 15,
  },
  paper: {
    padding: 30,
    marginTop: '1.3em',
    marginLeft: '0px',
    marginRight: '0px',
    clear: 'both',
    fontFamily: 'Avenir Next',
    overflow: 'auto',
    borderRadius: '4px',
    position: 'relative',
    boxShadow: 'none',
  },
  paperTablet: {
    padding: 30,
    marginTop: '1.3em',
    marginLeft: '15%',
    marginRight: '15%',
    clear: 'both',
    fontFamily: 'Avenir Next',
    overflow: 'auto',
    borderRadius: '4px',
  },
  paperMobile: {
    marginTop: '2.3em',
    marginLeft: '5%',
    marginRight: '5%',
    fontFamily: 'Avenir Next',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  headerPaper: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: '1.2em',
    marginLeft: '25%',
    marginRight: '25%',
    clear: 'both',
    overflow: 'auto',
    borderRadius: '4px',
  },
  paperInvoices: {
    marginTop: '1.3em',
    fontFamily: 'Avenir Next',
    borderRadius: '4px',
    overflow: 'hidden',
    boxShadow: 'none',
  },
  profileInfoCard: {
    padding: 1,
    width: '100%',
    float: 'left',
    fontFamily: 'Avenir Next',
    clear: 'both',
    borderRadius: '4px',
    marginBottom: 20,
    marginRight: 20,
  },
  paymentOptionCard: {
    padding: 1,
    width: '300px',
    float: 'left',
    fontFamily: 'Avenir Next',
    clear: 'both',
    borderRadius: '4px',
    marginBottom: 20,
    opacity: 0.5,
    marginRight: 20,
  },
  addPaymentMethodCard: {
    padding: 1,
    width: '300px',
    float: 'left',
    fontFamily: 'Avenir Next',
    clear: 'both',
    borderRadius: '4px',
    marginBottom: 20,
    opacity: 0.5,
    marginRight: 20,
  },
  paymentOptionCardSelected: {
    padding: 1,
    width: '300px',
    float: 'left',
    fontFamily: 'Avenir Next',
    clear: 'both',
    borderRadius: '4px',
    marginBottom: 20,
    opacity: 1,
    marginRight: 20,
  },
  paymentMadeCard: {
    padding: 1,
    width: '100%',
    float: 'left',
    fontFamily: 'Avenir Next',
    clear: 'both',
    borderRadius: '4px',
    marginBottom: 20,
    opacity: 1,
    marginRight: 20,
  },
  stickDiv: {
    marginLeft: '10%',
    width: '20%',
    marginRight: '10%',
    float: 'left',
    fontFamily: 'Avenir Next',
    position: '-webkit-sticky',
    // position: 'sticky',
    top: '20px',
  },
  stickDivSafari: {
    marginLeft: '170px',
    width: '260px',
    marginRight: '60px',
    float: 'left',
    fontFamily: 'Avenir Next',
    position: '-webkit-sticky',
    top: '20px',
  },
  headerPaperMobile: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: '1.2em',
    marginLeft: '2%',
    marginRight: '2%',
    clear: 'both',
    overflow: 'auto',
    borderRadius: '4px',
  },
  headerPaperTablet: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: '1.2em',
    marginLeft: '15%',
    marginRight: '15%',
    clear: 'both',
    overflow: 'auto',
    borderRadius: '4px',
  },
  accountHeaderItem: {
    paddingTop: '0.3em',
    fontSize: '1.0em',
    fontWeight: 200,
    color: '#666',
    fontFamily: 'Avenir Next',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: 400,
    marginTop: 5,
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  caseHeader: {
    fontSize: 15,
    paddingTop: 25,
    display: 'flex',
  },
  lineItem: {
    fontSize: 15,
    paddingTop: 25,
  },
  lineItemFirst: {
    fontSize: 15,
    paddingTop: 15,
  },
  lineTitle: {
    fontWeight: 400,
    fontFamily: 'Sofia Pro',
    width: '100%',
    color: '#C1C3C6',
    paddingBottom: '4px',
  },
  lineValue: {
    fontWeight: 400,
    width: '100%',
  },
  verifiedInfo: {
    height: '30px',
    width: '100%',
    fontSize: '1em',
    color: '#000000',
    position: 'relative',
    top: 0,
    userSelect: 'none',
    textDecoration: 'none',
    marginRight: 5,
    marginBottom: 5,
  },
  verifiedInfoGray: {
    height: '30px',
    width: '100%',
    fontSize: '1em',
    color: '#000000',
    position: 'relative',
    top: 0,
    userSelect: 'none',
    textDecoration: 'none',
    marginRight: 5,
    marginBottom: 5,
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 15,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  actionCard: {
    padding: 15,
    marginTop: '1.3em',
    width: '100%',
    float: 'left',
    fontFamily: 'Avenir Next',
    clear: 'both',
  },
  actionCardMobile: {
    padding: 15,
    marginTop: '1.3em',
    marginLeft: '5%',
    marginRight: '5%',
    fontFamily: 'Avenir Next',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  proPicSmall: {
    float: 'right',
    height: 35,
    marginRight: 10,
    borderRadius: '4px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  proPicSmallMobile: {
    float: 'left',
    height: 35,
    marginRight: 10,
    borderRadius: '4px',
  },
  refProPicSmall: {
    float: 'left',
    height: 35,
    marginRight: 10,
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  pocProPicSmall: {
    float: 'left',
    height: 35,
    marginRight: 10,
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  nameLabel: {
    fontSize: '1.5em',
    fontFamily: 'Avenir Next',
    fontWeight: 500,
    lineHeight: 'normal',
    wordWrap: 'break-word',
    maxWidth: '100%',
    display: 'inline-block',
    verticalAlign: 'middle',
    color: '#000000',
  },
  nameLabelMobile: {
    fontSize: '1.5em',
    fontFamily: 'Avenir Next',
    fontWeight: 500,
    widht: '100%',
    lineHeight: 'normal',
    wordWrap: 'break-word',
    maxWidth: '100%',
    color: '#000000',
  },
  refNameLabel: {
    fontSize: '1.5em',
    fontFamily: 'Avenir Next',
    fontWeight: 500,
    lineHeight: 'normal',
    wordWrap: 'break-word',
    maxWidth: '100%',
    color: '#000000',
  },
  tag: {
    color: '#2f54eb',
    background: '#f0f5ff',
    borderColor: '#adc6ff',
    borderRadius: '4px',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: 5,
    marginTop: 5,
    fontSize: '0.9em',
    display: 'inline-block',
  },
  cardHeader: {
    fontSize: 25,
    fontWeight: 600,
    paddingTop: 30,
    paddingLeft: 30,
    backgroundColor: '#ffffff',
    color: 'black',
  },
  infoRow: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 30,
  },
};

export const footerStyle = {
  link: {
    fontSize: '20px',
    fontFamily: 'Avenir Next',
  },
};

export const buttonStyle = {
  thinBlue: {
    height: '20px',
    fontSize: '12px',
    padding: '7px 16px 4px',
    border: 0,
    borderRadius: '3px',
    lineHeight: 'inherit',
    backgroundColor: '#ffffff',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#dcdcdc',
    color: '#2263ff',
    position: 'relative',
    top: 0,
    marginTop: '15px!important',
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    textAlign: 'center',
    textDecoration: 'none',
    marginRight: 5,
  },
  thinGreen: {
    height: '20px',
    fontSize: '12px',
    padding: '7px 16px 4px',
    border: 0,
    borderRadius: '3px',
    lineHeight: 'inherit',
    backgroundColor: '#ffffff',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#dcdcdc',
    color: '#0dbf0d',
    position: 'relative',
    top: 0,
    marginTop: '15px!important',
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    textAlign: 'center',
    textDecoration: 'none',
    marginRight: 5,
  },
  thinSkypePurp: {
    height: '30px',
    fontSize: '14px',
    paddingTop: '10px',
    width: '100%',
    border: 0,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#00aff0',
    borderRadius: '3px',
    lineHeight: 'inherit',
    backgroundColor: '#ffffff',
    color: '#00aff0',
    position: 'relative',
    top: 0,
    marginTop: '15px!important',
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  thinJoin: {
    height: '30px',
    fontSize: '14px',
    paddingTop: '10px',
    width: '100%',
    border: 1,
    fontWeight: 500,
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: '#F0F2F6',
    borderRadius: '3px',
    lineHeight: 'inherit',
    backgroundColor: '#ffffff',
    color: '#6094F7',
    position: 'relative',
    top: 0,
    marginTop: '15px!important',
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  thinQuoteGreen: {
    height: '30px',
    fontSize: '14px',
    paddingTop: '10px',
    width: '100%',
    border: 0,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#12dc91',
    borderRadius: '3px',
    lineHeight: 'inherit',
    backgroundColor: '#ffffff',
    color: '#12dc91',
    position: 'relative',
    top: 0,
    marginTop: '15px!important',
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  thickBlue: {
    height: '30px',
    fontSize: '14px',
    paddingTop: '10px',
    width: '100%',
    border: 0,
    borderRadius: '3px',
    lineHeight: 'inherit',
    backgroundColor: 'rgb(19, 30, 53)',
    color: '#ffffff',
    boxShadow: '0 1px 1px rgba(0,0,0,.3)',
    position: 'relative',
    top: 0,
    marginTop: '15px!important',
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  thickSkype: {
    height: '30px',
    fontSize: '14px',
    paddingTop: '10px',
    width: '100%',
    border: 0,
    borderRadius: '3px',
    lineHeight: 'inherit',
    backgroundColor: '#00aff0',
    color: '#ffffff',
    boxShadow: '0 1px 1px rgba(0,0,0,.3)',
    position: 'relative',
    top: 0,
    marginTop: '15px!important',
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  thickSkypePurp: {
    height: '30px',
    fontSize: '14px',
    paddingTop: '10px',
    width: '100%',
    border: 0,
    borderRadius: '3px',
    lineHeight: 'inherit',
    backgroundColor: '#00aff0',
    color: '#ffffff',
    boxShadow: '0 1px 1px rgba(0,0,0,.3)',
    position: 'relative',
    top: 0,
    marginTop: '15px!important',
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  thickGreen: {
    height: '30px',
    fontSize: '14px',
    paddingTop: '10px',
    width: '100%',
    border: 0,
    borderRadius: '3px',
    lineHeight: 'inherit',
    backgroundColor: '#02d44c',
    color: '#ffffff',
    boxShadow: '0 1px 1px rgba(0,0,0,.3)',
    position: 'relative',
    top: 0,
    marginTop: '15px!important',
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    textAlign: 'center',
    textDecoration: 'none',
  },
  thickRed: {
    height: '30px',
    fontSize: '14px',
    paddingTop: '10px',
    width: '100%',
    border: 0,
    borderRadius: '3px',
    lineHeight: 'inherit',
    backgroundColor: '#fd658f',
    color: '#ffffff',
    boxShadow: '0 1px 1px rgba(0,0,0,.3)',
    position: 'relative',
    top: 0,
    marginTop: '15px!important',
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    textAlign: 'center',
    textDecoration: 'none',
  },
  icon: {
    height: 11,
    width: 11,
    marginRight: 5,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
};

export const accountStyles = {
  lineTextBox: {
    fontSize: '13px',
    backgroundColor: '#ffffff',
    marginTop: '7px',
    width: '40%',
    resize: 'none',
    borderRadius: '5px',
    padding: '7px',
    paddingLeft: '12px',
    fontWeight: '500',
    color: 'rgb(97, 97, 97)',
    border: '1px solid #d6d6d6',
    fontFamily: '"Avenir Next"',
    whiteSpace: 'pre-wrap',
    outline: 'none',
  },
  proPic: {
    float: 'left',
    marginLeft: 15,
    marginTop: 15,
    height: '100%',
  },
  rightBlock: {
    float: 'left',
    marginLeft: 15,
    paddingBottom: 15,
  },
  title: {
    fontSize: '1.4em',
    paddingTop: '1em',
    fontFamily: 'Sofia Pro',
    fontWeight: 300,
    lineHeight: 'normal',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  paper: {
    marginTop: '2.3em',
    fontFamily: 'Avenir Next',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  paperTablet: {
    marginTop: '1.3em',
    marginLeft: '15%',
    marginRight: '15%',
    clear: 'both',
    fontFamily: 'Avenir Next',
    overflow: 'hidden',
    borderRadius: '4px',
  },
  paperMobile: {
    marginTop: '2.3em',
    marginLeft: '2%',
    marginRight: '2%',
    fontFamily: 'Avenir Next',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  headerPaper: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 20,
    marginTop: '1.2em',
    clear: 'both',
    display: 'flex',
    borderRadius: '4px',
  },
  headerPaperMobile: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: '1.2em',
    marginLeft: '2%',
    marginRight: '2%',
    clear: 'both',
    display: 'flex',
    borderRadius: '4px',
  },
  headerPaperTablet: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: '1.2em',
    marginLeft: '15%',
    marginRight: '15%',
    clear: 'both',
    display: 'flex',
    borderRadius: '4px',
  },
  accountHeaderItem: {
    paddingTop: '0.3em',
    fontSize: '1.0em',
    fontWeight: 500,
    color: '#666',
    fontFamily: 'Avenir Next',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: 400,
    marginTop: 5,
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  lineItem: {
    fontSize: 20,
    paddingTop: 25,
  },
  lineItemTrans: {
    fontSize: 17,
    paddingTop: 5,
  },
  lineItemFirst: {
    fontSize: 20,
    paddingTop: 15,
  },
  lineTitle: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    width: '100%',
    color: '#C1C3C6',
    paddingBottom: '4px',
  },
  lineAbout: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontSize: 15,
    width: '100%',
    color: '#000000',
    paddingBottom: 4,
  },
  lineValue: {
    fontWeight: 500,
    fontFamily: 'Avenir Next',
    fontSize: 20,
    width: '100%',
  },
  lineTitleTrans: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontsize: 13,
    width: '100%',
    color: '#525f7f',
    paddingBottom: '4px',
  },
  lineValueTrans: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontSize: 15,
    width: '100%',
  },
  exploreBox: {
    width: 400,
    display: 'inline-block',
    marginRight: 22.5,
    marginLeft: 22.5,
    marginBottom: 40,
  },
};
