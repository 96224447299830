import React from 'react';
import './LineItems.css';
import { AiFillInfoCircle } from 'react-icons/ai';
import {
  getConvertedAmountForLineItem,
  getLateFeeAmount,
} from '../../../utils';
import { Tooltip } from 'antd';

export const currencyFormatter = {
  format: (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value / 100);
  },
};

export default function LineItems({
  line_items = [],
  amount,
  project,
  conversionRates,
  milestone,
}) {
  return (
    <div className="li-body">
      <table className="li-details">
        <thead className="li-header">
          <tr>
            <td className="charge-description">Description</td>
            <td className="charge-quantity">Qty</td>
            <td className="charge-rate">Rate</td>
            <td className="charge-total">Amount</td>
          </tr>
        </thead>

        <tbody className="line-items">
          {line_items.map((lineItem) => (
            <tr className="charge" key={lineItem.id}>
              <td className="charge-description">{lineItem.description}</td>
              <td className="charge-quantity">{lineItem.quantity}</td>
              <td className="charge-rate">{lineItem.rate}</td>
              <td className="charge-total">
                {' '}
                {currencyFormatter.format(
                  (lineItem.quantity || 0) * (lineItem.rate || 0) * 100
                )}
                {project.currency != null && project.currency !== 'USD' && (
                  <span style={{ marginLeft: '5px' }}>
                    {getConvertedAmountForLineItem({
                      amount: (lineItem.quantity || 0) * (lineItem.rate || 0),
                      currency: project.currency,
                      paymentAmount: milestone.paymentData
                        ? milestone.paymentData.paymentAmount
                        : null,
                      creditsAmount: milestone.paymentData
                        ? milestone.paymentData.creditsAmount
                        : null,
                      projectCurrency: project.currency,
                      conversionRates,
                      invoiceAmount: milestone.amount,
                    })}
                  </span>
                )}
              </td>
            </tr>
          ))}

          {getLateFeeAmount(project, milestone) > 0 && (
            <tr className="charge" key="late_fee">
              <td className="charge-description flex flex-row gap-1 items-center">
                Late fee
                <Tooltip title="Payments not made by due date shall be subject to late charges equal to 1.5% per month of the overdue amount.">
                  <AiFillInfoCircle className="text-neutral-500 cursor-help" />
                </Tooltip>
              </td>
              <td className="charge-quantity" />
              <td className="charge-rate" />
              <td className="charge-total">
                {' '}
                {currencyFormatter.format(
                  getLateFeeAmount(project, milestone) * 100
                )}
                {project.currency != null && project.currency !== 'USD' && (
                  <span style={{ marginLeft: '5px' }}>
                    {getConvertedAmountForLineItem({
                      amount: getLateFeeAmount(project, milestone) * 100,
                      currency: project.currency,
                      paymentAmount: milestone.paymentData
                        ? milestone.paymentData.paymentAmount
                        : null,
                      creditsAmount: milestone.paymentData
                        ? milestone.paymentData.creditsAmount
                        : null,
                      projectCurrency: project.currency,
                      conversionRates,
                      invoiceAmount: milestone.amount,
                    })}
                  </span>
                )}
              </td>
            </tr>
          )}
        </tbody>

        <tr>
          <td className="charge-description">Total</td>
          <td className="charge-quantity" />
          <td className="charge-rate" />
          <td className="charge-total">
            {currencyFormatter.format(
              amount * 100 + getLateFeeAmount(project, milestone) * 100
            )}
          </td>
        </tr>
      </table>
    </div>
  );
}
