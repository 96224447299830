import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  footer: {
    borderTop: '0.5px solid #8191A1',
    paddingTop: '10px',
  },
  footerRow: {
    fontSize: 10,
    fontFamily: 'Open Sans',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '10px',
  },
  textLeft: {
    flexGrow: '1',
    alignSelf: 'flex-end',
  },
  textRight: {
    flexGrow: '0',
    alignItems: 'flex-end',
  },
  urlText: {
    color: `#0d6cff`,
  },
});

const getHelpOptions = (organization) => {
  if (
    organization.help_options?.phone?.enabled &&
    organization.help_options?.email?.enabled
  ) {
    return `Questions? Contact ${organization.name} at ${organization.help_options.phone.value} or at ${organization.help_options.email.value}.`;
  }
  if (
    !organization.help_options?.phone?.enabled &&
    organization.help_options?.email?.enabled
  ) {
    return `Questions? Contact ${organization.name} at ${organization.help_options.email.value}.`;
  }
  if (
    organization.help_options?.phone?.enabled &&
    !organization.help_options?.email?.enabled
  ) {
    return `Questions? Contact ${organization.name} at ${organization.help_options.phone.value}.`;
  }
  return `Contact ${organization.name} for any questions.`;
};

export default function Footer({ organization, invoice }) {
  return (
    <View style={styles.footer}>
      <View style={styles.footerRow}>
        <Text style={styles.textLeft}>{getHelpOptions(organization)}</Text>
        <View style={styles.textRight}>
          <Text>Powered by AloaPay</Text>
          <Text>{invoice.id.toUpperCase()} - Page 1 of 1</Text>
        </View>
      </View>
    </View>
  );
}

Footer.propTypes = {
  organization: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
};
