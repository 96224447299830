/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import {
  currencyFormatter,
  getDate,
  getDueDateFromInvoiceDate,
} from '../utils';
import { getLateFeeAmount } from '../../../../utils';

const styles = StyleSheet.create({
  container: {
    marginTop: '25px',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'stretch',
    marginTop: '5px',
  },
  mainText: {
    fontSize: 24,
    flexGrow: 1,
    fontWeight: '200',
    fontFamily: 'Lato Bold',
    alignSelf: 'flex-end',
    justifySelf: 'flex-start',
  },
  description: {
    fontFamily: 'Open Sans',
    fontSize: 12,
    flexGrow: 0,
    color: '#666666',
    fontWeight: '600',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
});

export default function Summary({ invoice }) {
  let dueDate = new Date();

  if (invoice.due_date) {
    dueDate = getDate(invoice.due_date);
  } else if (invoice.paymentData?.date) {
    dueDate = getDate(invoice.paymentData.date);
  }

  let summaryText = '';

  if (invoice.name && invoice.description) {
    summaryText = `${invoice.name}: ${invoice.description}`;
  } else if (invoice.name && !invoice.description) {
    summaryText = invoice.name;
  } else if (invoice.description && !invoice.name) {
    summaryText = invoice.description;
  }

  var lateFee = getLateFeeAmount(
    invoice?.project || {
      late_fee_enabled: false,
    },
    invoice
  );

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.mainText}>
          {currencyFormatter.format(
            lateFee > 0
              ? lateFee * 100 + invoice.amount * 100
              : invoice.amount * 100
          )}
          {' due '}
          {getDueDateFromInvoiceDate(
            dueDate,
            invoice.net_due_date
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>{summaryText}</Text>
      </View>
    </View>
  );
}

Summary.propTypes = {
  invoice: PropTypes.object.isRequired,
};
