import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Select, Avatar, Tooltip, Popover, message } from 'antd';

const { Option } = Select;

export default function EditCollaboratorInviteCard({
  currentUser,
  invite,
  removeInviteFromProject,
  updateInviteRoleInProject,
}) {
  const [changes, setChanges] = useState(invite);
  const [copyTipVisible, setCopyTipVisible] = useState(false);
  const [copyTipShown, setCopyTipShown] = useState(false);
  const [popOverVisible, setPopOverVisible] = useState(false);

  const removeInvite = () => {
    removeInviteFromProject(invite)
      .then(() => {
        setChanges(null);
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  const showCopyTip = () => {
    if (!copyTipShown) {
      setTimeout(() => {
        setCopyTipVisible(true);
        setCopyTipShown(true);
        setTimeout(() => {
          setCopyTipVisible(false);
        }, 2200);
      }, 800);
    }
  };

  const handleChange = value => {
    updateInviteRoleInProject(invite, value)
      .then(invite => {
        setChanges(invite);
      })
      .catch(error => {
        console.log(error);
        message.error(error.message);
      });
  };

  const popoverVisibleChange = visible => {
    if (!visible) {
      setCopyTipVisible(false);
      setPopOverVisible(visible);
    } else {
      setPopOverVisible(visible);
    }
  };

  const makeInitials = () => {
    return invite.email.substring(0, 2).toUpperCase();
  };

  const popoverContent = () => {
    const url = `${window.location.origin}/register/${invite.id}`;
    return (
      (<div>
        <p>
          {`Share this link with ${invite.email} so they can join your project`}
        </p>
        <Tooltip
          open={copyTipVisible && popOverVisible}
          title="Click on the link to copy"
        >
          <p>
            <a
              onClick={() => {
                const textField = document.createElement('textarea');
                textField.innerText = url;
                textField.visible = false;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
                message.success('Link copied!');
              }}
            >
              {url}
            </a>
          </p>
        </Tooltip>
      </div>)
    );
  };

  if (changes === null) {
    return null;
  }
  return (
    <div className="edit-collaborator-card clearfix">
      <div style={{ display: 'inline-block', float: 'left' }}>
        <Avatar className="edit-collaborator-avatar">{makeInitials()}</Avatar>
      </div>

      <div className="edit-collaborator-name">
        {invite.email}
        <div className="edit-invite-url">
          <Popover
            content={popoverContent()}
            onClick={showCopyTip}
            onVisibleChange={popoverVisibleChange}
            title="Invitation Url"
            trigger="click"
          >
            <span style={{ color: '#015bea', cursor: 'pointer' }}>
              {` [invite url] `}
            </span>
          </Popover>
        </div>
      </div>

      {/* Ability to edit invite if user is admin */}
      {currentUser.acc_type === 2 && (
        <div className="edit-collaborator-role-section">
          <div style={{ display: 'inline-block', marginRight: '5px' }}>
            <Select
              defaultValue={invite.role}
              style={{ width: 100 }}
              onChange={handleChange}
              disabled={currentUser.acc_type !== 2}
            >
              <Option value="viewer">Viewer</Option>
              <Option value="payer">Payer</Option>
              <Option value="payee">Payee</Option>
            </Select>
          </div>
          <div style={{ display: 'inline-block' }}>
            <Button
              shape="circle"
              icon={<CloseOutlined />}
              style={{
                boxShadow: 'none',
                border: 'none',
                background: 'none',
              }}
              onClick={removeInvite}
            />
          </div>
        </div>
      )}
    </div>
  );
}
