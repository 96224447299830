import dialogTypes from '../constants/dialogTypes';

const appDialog = (
  state = {
    visible: false,
    type: '',
    title: '',
    callback: () => {},
  },
  action
) => {
  switch (action.type) {
    case 'SHOW_ADD_PAYMENT':
      return {
        visible: true,
        type: action.dialogType,
        title: 'Add Payment Method',
        callback: action.callback,
        context: action.context,
      };
    case 'CLEAR_PAYMENT_OAUTH_CONTEXT':
      delete state.context.linkToken;
      delete state.context.receivedRedirectUri;
      return { ...state };
    case 'SHOW_MILESTONE_ACTIONS':
      return {
        visible: true,
        type: action.dialogType,
        title: 'Do something with the milestone',
        callback: action.callback,
        ...action.dialogData,
      };
    case 'SHOW_USE_CREDITS':
      return {
        visible: true,
        type: action.dialogType,
        title: 'Use Aloa Credits',
        callback: action.callback,
        ...action.dialogData,
      };
    case 'SHOW_MILESTONE_PDF':
      return {
        visible: true,
        type: dialogTypes.pdf,
        title: '',
        callback: action.callback,
        pdfType: action.pdfType,
        invoice: {
          ...action.milestone,
          project: action.project,
        },
      };
    case 'HIDE_APP_DIALOG':
      return {
        ...state,
        visible: false,
        callback: () => {},
      };
    case 'SET_APP_DIALOG_TILE':
      return {
        ...state,
        title: action.type,
      };
    default:
      return state;
  }
};

export default appDialog;
