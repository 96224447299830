/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { Card } from '../../Objects';
import ProjectCard from './ProjectCard';

export default function ProjectsSection({
  projects,
  currentUser,
  history,
  archived,
  milestones,
  collaborators,
  demoOn,
}) {
  if (projects.length > 0) {
    return (
      <div>
        {projects.map(project => (
          <Card>
            <ProjectCard
              collaborators={collaborators}
              key={project.id}
              project={project}
              currentUser={currentUser}
              history={history}
              milestones={milestones}
              demoOn={demoOn}
              currentUser={currentUser}
            />
          </Card>
        ))}
      </div>
    );
  }
  if (!archived) {
    return <Card>No projects found... Yet 😉</Card>;
  }
  return null;
}
