import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Button, Modal } from 'antd';
import ProjectHeaderCard from './ProjectHeaderCard';
import AloaCreditCard from './AloaCreditCard';
import MilestonesSection from './MilestoneSection';

// // How many of these styles do we need?

import 'ant-design-pro/dist/ant-design-pro.css';
import './ProjectPage.scss';

function ProjectPage({ currentUser, project, collaborators, history }) {
  const [visible, setVisible] = useState(false);
  const [payer, setPayer] = useState(null);

  useEffect(() => {
    const tcEnabled = false;
    if (tcEnabled && !visible) {
      setTimeout(() => {
        setVisible(true);
      }, 1000);
    }
  });

  useEffect(() => {
    if (project && project.collaborators) {
      project.collaborators.forEach(collaborator => {
        const id = collaborator.user;
        if (collaborator.role === 'payer' && collaborators[id]) {
          setPayer(collaborators[id]);
        }
      });
    }
  }, [collaborators]);

  if (project) {
    return (
      <div className="App">
        <TermsAndConditionsModal
          visible={visible}
          showTC={() => history.push('/terms')}
        >
          We just need you to approve the pricing for project so work can start
        </TermsAndConditionsModal>
        <MediaQuery minWidth={701}>
          <div className="desktop-header-content">
            <div className="project-page-header">Project Info</div>
            <ProjectHeaderCard project={project} currentUser={currentUser} />
            {currentUser.credits > 0 && (
              <AloaCreditCard project={project} currentUser={currentUser} />
            )}
          </div>
          <div className="desktop-body-content">
            <div className="project-page-header">Milestones</div>
            <MilestonesSection
              currentUser={currentUser}
              project={project}
              payer={payer}
            />
          </div>
          <div style={{ paddingTop: 100 }} />
        </MediaQuery>

        <MediaQuery maxWidth={700}>
          <div className="mobile-content">
            <div className="project-page-header">Project Info</div>
            <ProjectHeaderCard project={project} currentUser={currentUser} />
            <div className="project-page-header">Milestones</div>
            <MilestonesSection
              currentUser={currentUser}
              project={project}
              payer={payer}
            />
          </div>
          <div style={{ paddingTop: 100 }} height={100} />
        </MediaQuery>
      </div>
    );
  }
  return (
    <div className="App">
      <div className="dashPage">
        <h1 className="sectionTitle">Loading Project...</h1>
      </div>
    </div>
  );
}

function TermsAndConditionsModal({ visible, showTC, children }) {
  return (
    <Modal
      title="Almost there..."
      open={visible}
      closable={false}
      footer={[
        <Button key="submit" type="primary" loading={false} onClick={showTC}>
          Okay
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
}

const mapStateToProps = (state, props) => ({
  currentUser: state.currentUser,
  project: state.projects ? state.projects[props.projectId] : {},
  collaborators: state.collaborators,
});

export default connect(
  mapStateToProps,
  null
)(ProjectPage);
