import React from 'react';
import { connect } from 'react-redux';

export { default as featureTypes } from './featuresTypes';

const environment = process.env.REACT_APP_ENV === 'dev' ? 'dev' : 'prod';

/**
 * On child for Toggle component
 * In the future we will incorporate auto metrics here to track the number of times a feature is On
 */
export function On({ children }) {
  return children;
}

/**
 * Off child for Toggle component
 * In the future we will incorporate auto metrics here to track the number of times a feature is Off
 */
export function Off({ children }) {
  return children;
}

/**
 * Function that contains enable check logic
 * @param {Object} feature Feature to check if enabled
 * @param {string} userId Id of user to check for
 */
export const isFeatureEnabledForUser = (feature, userId) => {
  if (feature) {
    if (feature.users[userId]) {
      return feature.users[userId][environment];
    }
    const { percent } = feature.environments[environment];
    const randomInt = Date.now() % 100;
    if (randomInt < percent) {
      return true;
    }
  }
  return false;
};

/**
 * Main Toggle component
 * @param {String} feature feature id to toggle
 * @param {String} user User id to toggle feature for
 * @param {Array} children React children to render
 * @param {Boolean} on Override to turn toggle on with custom condition
 * @param {Boolean} off Override to turn toggle off with custom condition
 */
function Toggle({ features, feature, user, children, on, off }) {
  const userId = typeof user === 'object' ? user.id : user;
  const featureId = typeof feature === 'object' ? feature.id : feature;
  const featureEnabled =
    (isFeatureEnabledForUser(features[featureId], userId) || (on || false)) &&
    !(off || false);

  if (!features[featureId]) {
    return null;
  }

  return (
    <>
      {children.map(child => {
        if (child.type && (child.type.name === On.name) === featureEnabled) {
          return child.props.children ? child : null;
        }
        return null;
      })}
    </>
  );
}

const mapStateToProps = state => ({
  features: state.features,
});

export default connect(
  mapStateToProps,
  {}
)(Toggle);
