import React, { useState } from 'react';
import { connect } from 'react-redux';
import ModalOverlay from '../Objects/ModalOverlay';
import MilestoneHeader from './MilestoneHeader';
import MilestoneDescription from './MilestoneDescription';
import MilestoneButtons from './MilestoneButtons';
import MilestoneEdit from './MilestoneEdit';
import './Milestone.scss';

import { addMilestoneToProject } from '../../actions/project';
import {
  updateMilestone,
  payForMilestone,
  deleteMilestone,
} from '../../actions/milestone';
import { showAddPayment, showMilestoneActions } from '../../actions/appDialog';
import { Modal } from 'antd';

function MilestoneCard({
  milestone,
  project,
  currentUser,
  payer,
  showMilestoneActions,
  conversionRates,
  editing,
  setEditing,
}) {
  const [pdfOpen, setPdfOpen] = useState(false);
  const [pdfSource, setPdfSource] = useState('');
  const [pdfType, setPdfType] = useState('');

  /**
   * Functions to open/close edit milestone option
   */
  const openEdit = () => {
    setEditing(milestone.id);
  };

  const cancelEdit = () => {
    setEditing(null);
  };

  /**
   * When clicking main action button, open dialog and set the dialog status
   * @param {String} newStatus - The new milestone status
   */
  const handleClickOpen = (newStatus) => {
    showMilestoneActions({ milestone, project, newStatus, showPdf });
  };

  const showPdf = (data, type) => {
    setPdfOpen(true);
    setPdfSource(data);
    setPdfType(type);
  };

  if (!milestone) {
    return null;
  }

  return (
    <>
      {/* ModaOverlay is to show PDF of receipts and invoices */}

      <ModalOverlay
        open={pdfOpen}
        src={pdfSource}
        type={pdfType}
        conversionRates={conversionRates}
        handleClose={() => setPdfOpen(false)}
      />

      <div>
        {/* Main milestone card content */}
        <MilestoneHeader
          milestone={milestone}
          conversionRates={conversionRates}
          project={project}
        />

        <MilestoneDescription
          milestone={milestone}
          conversionRates={conversionRates}
          project={project}
        />

        <MilestoneButtons
          milestone={milestone}
          project={project}
          payer={payer}
          currentUser={currentUser}
          handleClickOpen={handleClickOpen}
          openEdit={openEdit}
          showPdf={showPdf}
          conversionRates={conversionRates}
        />

        <div style={{ clear: 'right' }} />
      </div>
    </>
  );
}

const mapDispatchToProps = {
  updateMilestone,
  payForMilestone,
  deleteMilestone,
  addMilestoneToProject,
  showAddPayment,
  showMilestoneActions,
};

export default connect(null, mapDispatchToProps)(MilestoneCard);
