/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/user';
import Toggle, { On, Off, featureTypes } from '../FeatureToggle';
import './SignUp.scss';

const mapDispatchToProps = {
  logoutUser,
};

function SignUpRedirect({ logoutUser, history }) {
  return (
    <div style={{ textAlign: 'center' }}>
      <div className="redirect-section">
        <p>Looks like you're logged in...</p>
        <p>You'll need to log out before you can use the invite link.</p>
        <br />
        <Toggle feature={featureTypes.SSO}>
          <On>
            <a
              className="redirect-action-text"
              href={`${process.env.REACT_APP_ACCOUNTS_URL}/logout`}
            >
              Click here to log out
            </a>
          </On>
          <Off>
            <p className="redirect-action-text" onClick={logoutUser}>
              Click here to log out
            </p>
          </Off>
        </Toggle>
        <p>or</p>
        <p className="redirect-action-text" onClick={history.goBack}>
          Click here to go back
        </p>
      </div>
    </div>
  );
}

export default connect(
  null,
  mapDispatchToProps
)(SignUpRedirect);
