export const fetchLinkToken = async user => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/plaid/createLinkToken`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user,
        redirect_uri: `${window.location.protocol}//${
          window.location.host
        }/plaid-oauth`,
      }),
    }
  );
  const responseJSON = await response.json();
  return responseJSON.link_token;
};

export default {
  fetchLinkToken,
};
