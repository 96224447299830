import React from 'react';
import styled from 'styled-components';
import {
  BankOutlined,
  CreditCardOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { getPaymentMethodText } from './AutoPaySettings';

export default function AutoPayLabel({ project, currentUser }) {
  const paymentMethod = project.autoPay.payer.payment_methods.find(
    method => method.source_id === project.autoPay.paymentMethod
  );
  const paymentMethodText = getPaymentMethodText(paymentMethod);

  const shouldShowPayer = () => {
    const collaborator = project.collaborators.find(
      collabortor => collabortor.user === project.autoPay.payer.id
    );
    return (
      (collaborator && collaborator.role === 'payer') ||
      currentUser.acc_type === 2
    );
  };

  return (
    <div>
      <MainText>
        <DollarOutlined style={{ marginRight: '5px' }} />
        Auto Pay Enabled
      </MainText>
      {shouldShowPayer() && (
        <PayerText>
          {paymentMethod.type === 'ach' ? (
            <BankOutlined style={{ marginRight: '5px' }} />
          ) : (
            <CreditCardOutlined style={{ marginRight: '5px' }} />
          )}
          {project.autoPay.payer.name}
          {' - '}
          {paymentMethodText}
        </PayerText>
      )}
    </div>
  );
}

const MainText = styled.div`
  color: #28b300;
`;

const PayerText = styled.div``;
