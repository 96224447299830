/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Text, StyleSheet, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginTop: '65px',
    paddingLeft: '10px',
  },
  message: {
    fontSize: 12,
    fontFamily: 'Open Sans',
  },
});

export default function Message({ message }) {
  return (
    <View style={styles.container}>
      <Text style={styles.message}>
        {message || 'Thanks for your business!'}
      </Text>
    </View>
  );
}
