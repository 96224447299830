/* eslint-disable import/no-cycle */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'antd';
import MilestoneDialogCard from './MilestoneDialogCard';
import NoMilestonesMessage from './NoMilestonesMessage';
import milestoneStatuses from '../../../constants/milestoneStatuses';
import {
  showMilestoneActions,
  showUseCredits,
} from '../../../actions/appDialog';

export default function UseCreditsDialogContent({ callback }) {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.appDialog.project);
  const milestones = useSelector((state) => state.milestones);

  const handleSelectMilestone = (milestoneId) => {
    const appDialogData = {
      milestone: milestones[milestoneId],
      project,
      newStatus: milestoneStatuses.paid,
    };

    dispatch(
      showMilestoneActions(appDialogData, () => {
        dispatch(showUseCredits({ project }));
      })
    );
  };

  const eligibleMilestones = Object.values(milestones)
    .filter(
      (milestone) =>
        milestone.project_id === project.id &&
        milestone.status === milestoneStatuses.pending_approval
    )
    .map((id) => milestones[id]);

  return (
    <>
      <span className="app-dialog-header">Pay with Credits</span>
      <MilestoneContainer>
        {eligibleMilestones.length > 0 ? (
          eligibleMilestones.map((milestone, index) => (
            <MilestoneDialogCard
              key={`miestoneDialogCard-${milestone.id}`}
              index={index}
              milestone={milestone}
              selectMilestone={handleSelectMilestone}
            />
          ))
        ) : (
          <NoMilestonesMessage />
        )}
      </MilestoneContainer>

      <CancelButtonContainer>
        <Button onClick={callback}>Cancel</Button>
      </CancelButtonContainer>
    </>
  );
}

const MilestoneContainer = styled.div`
  background-color: #fafafa;
  margin-top: 10px;
  border-radius: 8px;
  padding: 10px;
`;

const CancelButtonContainer = styled.div`
  margin-top: 10px;
  text-align: right;
`;
