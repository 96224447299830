import React from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import { Button } from 'antd';

// import 'antd/dist/antd.css';


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    borderRadius: '6px',
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '100%',
    minWidth: '375px',
    maxWidth: theme.spacing.unit * 100,
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 4,
  },
});

function SimpleModal({ classes, src, type, open, handleClose }) {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
      style={{ borderRadius: '15px' }}
    >
      <div style={getModalStyle()} className={classes.paper}>
        <div style={{ float: 'left' }}>{type}</div>
        <Button
          style={{ float: 'right', marginBottom: '10px', marginTop: '-7px' }}
          onClick={handleClose}
        >
          Close
        </Button>
        <SimpleModalWrapped />
        <iframe
          title={type}
          width="100%"
          height="95%"
          src={src}
          type="application/pdf"
        />
      </div>
    </Modal>
  );
}

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(SimpleModal);

export default SimpleModalWrapped;
