import fetch from 'node-fetch';
import { currencyFormat } from '../utils';

const TEMPLATE_IDS = {
  AUTOPAY_NOTIFICATION: 'auto-pay-notification',
};
const NOTIFICATION_SYSTEM_URL = `https://us-central1-hermes-50f48.cloudfunctions.net/newNotification`;

export const sendAutoPayEmail = (
  payer,
  paymentMethodId,
  milestone,
  project
) => {
  const paymentMethod = payer.payment_methods.find(
    method => method.source_id === paymentMethodId
  );

  const templateData = {
    payer: {
      first: payer.name.split(' ')[0],
    },
    amount: currencyFormat(milestone.amount, 2),
    milestone: {
      name: milestone.name,
      description: milestone.description
        ? milestone.description
        : '(No Description)',
    },
    project: {
      name: project.name,
      id: project.id,
    },
    paymentmethod: {
      issuer:
        paymentMethod.type === 'ach'
          ? paymentMethod.bank_inst
          : paymentMethod.brand,
      type: paymentMethod.type === 'ach' ? 'bank account' : paymentMethod.type,
      last4: paymentMethod.last4,
    },
  };

  return makeRequest(
    payer.email,
    TEMPLATE_IDS.AUTOPAY_NOTIFICATION,
    templateData
  );
};

/**
 * Get emails for a provided project
 * @param {Object} project project to get emails for
 */
export const getPayers = (project, collaborators) => {
  const users = [];
  if (project.collaborators) {
    project.collaborators.forEach(collaborator => {
      if (collaborator.role === 'payer') {
        if (
          collaborators[collaborator.user] &&
          collaborators[collaborator.user].data.email
        ) {
          users.push(collaborators[collaborator.user].data);
        }
      }
    });
  } else if (project.payer) {
    const { payer } = project.payer;
    if (collaborators[payer] && collaborators[payer].data.email) {
      users.push(collaborators[payer].data);
    }
  }
  return users;
};

/**
 * Make a request to aloa notifications/manage api
 * @param {String} path Path to send request to
 * @param {Object} emails Emails that will get notification
 * @param {Object} milestone Milestone to send in request
 * @param {Object} project Project to send in request
 */
const makeRequest = (email, templateId, templateData) =>
  new Promise((resolve, reject) => {
    const postBody = {
      styles: ['EMAIL_V2'],
      item: {
        to: email,
        from: 'Aloa Pay <no-reply@aloa.co>',
        bcc: 'pay@aloa.co',
        templateId,
        templateData,
      },
    };

    fetch(NOTIFICATION_SYSTEM_URL, {
      method: 'POST',
      body: JSON.stringify(postBody),
      mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        resolve('Email sent');
      })
      .catch(error => reject(error));
  });
