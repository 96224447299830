import { db } from '../fire';

export const setVersion = version => {
  return {
    type: 'SET_VERSION',
    version,
  };
};

export const fetchAppVersion = () => (dispatch, getState) =>
  new Promise(() => {
    //   dispatch(loadMilestone(newMilestone));

    const appRef = db.collection('app').doc('info');

    appRef.onSnapshot(function(doc) {
      const data = doc.data();
      const incomingVersion = data.version;
      const currentVersion = getState().app.version;

      if (currentVersion == null) {
        dispatch(setVersion(incomingVersion));
      }

      if (currentVersion != null && currentVersion < incomingVersion) {
        dispatch(setVersion(incomingVersion));
        if (currentVersion != null) {
          window.location.reload();
        }
      }
    });
  });

export default fetchAppVersion;
