/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import '../../styles/Auth.css';
import TextField from '@material-ui/core/TextField';
import { Button, message } from 'antd';
import { auth } from '../../fire';
import { loadUser } from '../../actions/user';
import { Card } from '../Objects';
// images
import aloaSmallLine from '../../images/aloa_small_line.png';

const styles = {
  title: {
    height: 30,
    fontSize: '18em',
    fontWeight: 400,
    paddingTop: '.70em',
    fontFamily: 'Avenir Next',
    // fontWeight: 500,
  },
  smallTitle: {
    paddingTop: '0.4em',
    height: 30,
    fontSize: '1.0em',
    fontWeight: 200,
    color: '#666',
    fontFamily: 'Avenir Next',
  },
  subTitle: {
    paddingTop: '0.2em',
    height: 30,
    fontSize: '1.1em',
    fontWeight: 200,
    color: '#666',
    fontFamily: 'Avenir Next',
  },
  logo: {
    margin: 'auto',
    padding: '0em 0em 0.5em 0em',
    fontFamily: 'Avenir Next',
  },
  paper: {
    width: '300px',
    padding: '2em 3em 3em 3em',
    fontFamily: 'Avenir Next',
  },
  paperMobile: {
    marginLeft: '4%',
    marginRight: '4%',
    padding: '2em 3em 3em 3em',
    fontFamily: 'Avenir Next',
  },
  paperTablet: {
    width: '400px',
    margin: 'auto',
    padding: '2em 3em 3em 3em',
    fontFamily: 'Avenir Next',
  },
  loginButton: {
    marginTop: '3em',
    width: '100%',
  },
};

const mapDispatchToProps = {
  loadUser,
};

function Login({ history }) {
  const [tempEmail, setTempEmail] = useState('');
  const [tempResetEmail, setTempResetEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [forgotPassState, setForgotPassState] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);

    auth
      .signInWithEmailAndPassword(tempEmail, tempPassword)
      .then(() => {
        history.push('/');
      })
      .catch(error => {
        message.error(error.message);
        setLoading(false);
      });
  };

  const handlePassReset = () => {
    setLoading(true);
    auth
      .sendPasswordResetEmail(tempResetEmail)
      .then(() => {
        message.success('Reset Email Sent!');
        forgotPass();
      })
      .catch(error => {
        message.error(error.message);
        setLoading(false);
      });
  };

  const forgotPass = () => {
    setForgotPassState(!forgotPassState);
  };

  const enterPressed = event => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleClick();
    }
  };

  const handleChange = (event, type) => {
    if (type === 'email') {
      setTempEmail(event.target.value);
    }

    if (type === 'reset_email') {
      setTempResetEmail(event.target.value);
    }

    if (type === 'password') {
      setTempPassword(event.target.value);
    }
  };

  return (
    <div>
      <div className="box">
        <Card style={styles.paper}>
          <div style={styles.logo}>
            <img
              src={aloaSmallLine}
              style={{
                height: '45px',
                paddingTop: 10,
                display: 'block',
                margin: 'auto',
              }}
            />
          </div>

          {!forgotPassState && (
            <div style={{ marginTop: 0 }}>
              <TextField
                label="Email"
                placeholder="example@gmail.com"
                style={{ width: '100%' }}
                margin="normal"
                onChange={e => handleChange(e, 'email')}
                value={tempEmail}
                onKeyPress={enterPressed}
              />
              <br />

              <TextField
                label="Password"
                placeholder="Not password123"
                style={{ width: '100%' }}
                margin="normal"
                type="password"
                onChange={e => handleChange(e, 'password')}
                value={tempPassword}
                onKeyPress={enterPressed}
              />
              <br />
              <Button
                variant="contained"
                type="submit"
                primary
                style={{
                  backgroundColor: '#0080fd',
                  color: '#ffffff',
                  width: '100%',
                  marginTop: '20px',
                }}
                loading={loading}
                onClick={handleClick}
              >
                Login
              </Button>

              <div
                style={{
                  paddingTop: '1.5em',
                  textAlign: 'center',
                }}
              >
                <a
                  onClick={forgotPass}
                  style={{
                    color: '#000000',
                    cursor: 'pointer',
                  }}
                >
                  Forgot Password?
                </a>
              </div>
            </div>
          )}

          {forgotPassState && (
            <div style={{ marginTop: 0 }}>
              <div style={{ marginTop: 20 }}>
                {"No worries, we'll send you a link to reset it."}
              </div>

              <TextField
                label="Email"
                placeholder="example@gmail.com"
                style={{ width: '100%' }}
                margin="normal"
                onChange={e => handleChange(e, 'reset_email')}
                value={tempResetEmail}
              />
              <br />

              <Button
                variant="contained"
                type="submit"
                primary
                style={{
                  backgroundColor: '#545def',
                  color: '#ffffff',
                  width: '100%',
                  marginTop: '20px',
                }}
                onClick={handlePassReset}
              >
                Reset Password
              </Button>

              <div
                style={{
                  paddingTop: '1.5em',
                  textAlign: 'center',
                }}
              >
                <a
                  onClick={forgotPass}
                  style={{
                    color: '#000000',
                    cursor: 'pointer',
                  }}
                >
                  Nevermind, I remember
                </a>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}

export default connect(
  null,
  mapDispatchToProps
)(Login);
