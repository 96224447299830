/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import * as moment from 'moment';
import ReactTable from 'react-table';
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
} from 'react-table';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { Card } from '../Objects';

export default function MilestoneTable({
  currentUser,
  milestones,
  collaborators,
  projects,
  validMilestones,
  milestonesPendingApproval,
  arMilestones,
}) {
  if (projects.length > 0) {
    const milestoneRows = [];

    projects.forEach(function (project) {
      Object.values(milestones)
        .filter((milestone) => milestone.project_id === project.id)
        .forEach(function (milestone) {
          if (milestone != null && validMilestones.includes(milestone.id)) {
            milestoneRows.push(
              <Card>
                <span>{project.name}</span>
                <span>{milestone.name}</span>
                <span>{milestone.description}</span>
                {milestone.due_date != null && (
                  <span>
                    {Number(
                      moment(moment(Date())).diff(
                        milestone.due_date.toDate(),
                        'days',
                        true
                      )
                    ).toFixed(1)}{' '}
                    {'Days Overdue'}
                  </span>
                )}
              </Card>
            );
          }
        });
    });

    const compareDates = (rowA, rowB, columnId) => {
      const dateA = rowA.values[columnId];
      const dateB = rowB.values[columnId];

      if (!dateA && !dateB) return 0;
      if (!dateA) return -1; // nulls last, swap values to change to nulls first
      if (!dateB) return 1; // nulls last, swap values to change to nulls first

      const momentA = moment(dateA);
      const momentB = moment(dateB);

      return momentA.diff(momentB);
    };

    const columns = React.useMemo(
      () => [
        {
          Header: 'Project',
          id: 'projectInfoName',
          accessor: (d) => d.projectInfo.name,
        },
        {
          Header: 'Status',
          id: 'status',
          accessor: d => {
            if (d.status === 'pending_approval') {
              return 'Due';
            } else if (d.status === 'declined') {
              return 'Declined';
            }
            return null;
          },
        },
        {
          Header: 'Amount',
          accessor: 'amount',
          Cell: ({ cell: { value } }) => (
            <span>
              {value != null && (
                <span>
                  <NumberFormat
                    value={value}
                    displayType="text"
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    prefix="$"
                  />
                </span>
              )}
            </span>
          ),
        },
        {
          Header: 'Name',
          accessor: (row) => row,
          Cell: ({ cell: { value } }) => (
            <Link to={`/project/${value.projectInfo.id}`}>
              <span>{value.name}</span>
            </Link>
          ),
        },
        {
          Header: 'Invoice Date',
          id: 'due_date',
          accessor: d => {
            if (d.due_date != null) {
              return d.due_date.toDate();
            }
            return null;
          },
          Cell: ({ cell: { value } }) => (
            <span>
              {value != null && (
                <span>
                  {value != null && (
                    <span>{moment(value).format('MM/DD/YYYY')}</span>
                  )}
                </span>
              )}
            </span>
          ),
          sortType: compareDates,
        },
        {
          Header: 'Due Date',
          id: 'net_due_date',
          accessor: d => {
            // Check if net_due_date exists
            if (d.net_due_date) {
              return d.net_due_date.toDate();
            }
            // If net_due_date does not exist, add 14 days to due_date
            else if (d.due_date) {
              return moment(d.due_date.toDate()).add(14, 'days').toDate();
            }
            return null;
          },
          Cell: ({ cell: { value } }) => (
            <span>
              {value != null && (
                <span>
                  {value != null && (
                    <span>{moment(value).format('MM/DD/YYYY')}</span>
                  )}
                </span>
              )}
            </span>
          ),
          sortType: compareDates,
        },
      ],
      []
    );

    const data = React.useMemo(() => arMilestones);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
          initialState: {
            sortBy: [
              {
                id: 'net_due_date',
                desc: true
              }
            ]
          },
        },
        useSortBy
      );

    return (
      <Card>
        <table {...getTableProps()} className="milestoneTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="milestoneRow"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="milestoneColumn"
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="milestoneRow">
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="milestoneColumn">
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }
  return <Card>No data to display</Card>;

  return null;
}
