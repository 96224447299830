import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import successImage from '../../../images/payment_success.png';
import { createPdf } from '../../../actions/appDialog';

export default function PaymentComplete({
  project,
  milestone,
  cancelActive,
  showPdf,
}) {
  const dispatch = useDispatch();

  const createReceipt = () => {
    dispatch(createPdf.receipt(milestone, project));
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <img style={{ marginTop: '20px', width: '40%' }} src={successImage} />
      <div
        className="partner-message"
        style={{ fontSize: '18px', marginBottom: '20px' }}
      >
        Thanks for your payment!
      </div>
      {showPdf && (
        <Button style={{ marginRight: '10px' }} onClick={createReceipt}>
          View Receipt
        </Button>
      )}
      <Button type="primary" onClick={cancelActive}>
        Done
      </Button>
    </div>
  );
}
