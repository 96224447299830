import React, { useState } from 'react';
import PaymentCard from './PaymentCard';
import SettingsCard from '../Objects/SettingsCard';

export default function PaymentMethodCards({
  currentUser,
  showAddPayment,
  verifySource,
  deletePaymentMethod,
}) {
  const [editPaymentMethods, setEditPaymentMethods] = useState(false);

  return (
    <SettingsCard name="Payment Methods">
      <div>
        {currentUser.payment_methods && (
          <div>
            {currentUser.payment_methods.map(method => (
              <div style={{}}>
                <PaymentCard
                  method={method}
                  verifySource={verifySource}
                  deletePaymentMethod={deletePaymentMethod}
                  editPaymentMethods={editPaymentMethods}
                />
              </div>
            ))}
          </div>
        )}

        <div className="clearfix">
          <div
            onClick={() => showAddPayment()}
            style={{
              color: '#1890ff',
              fontFamily: 'Sofia Pro',
              fontSize: '15px',
              cursor: 'pointer',
              float: 'left',
            }}
          >
            Add Payment Method
          </div>
          <div
            onClick={() => setEditPaymentMethods(!editPaymentMethods)}
            style={{
              color: '#1890ff',
              fontFamily: 'Sofia Pro',
              fontSize: '15px',
              fontWeight: '200',
              cursor: 'pointer',
              float: 'right',
            }}
          >
            {!editPaymentMethods ? 'Edit' : 'Cancel'}
          </div>
        </div>
      </div>
    </SettingsCard>
  );
}
