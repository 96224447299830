import React from 'react';
import { Button } from 'antd';
import successImage from '../../../images/payment_success.png';

export default function CreditCardComplete({ cancelActive, complete }) {
  return (
    <div style={{ textAlign: 'center' }}>
      <img style={{ marginTop: '20px', width: '40%' }} src={successImage} />
      <div
        className="partner-message"
        style={{ fontSize: '18px', marginBottom: '20px' }}
      >
        {`Successfully added ${complete}!`}
      </div>
      <Button type="primary" onClick={cancelActive}>
        {/* Add Another */}
        Done
      </Button>
    </div>
  );
}
