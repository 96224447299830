/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Input, Form } from 'antd';
import { currencyFormat } from '../../../utils';

export default function AloaCreditSelect({
  currentUser,
  milestone,
  creditsToUse,
  setCreditsToUse,
}) {
  const [applied, setApplied] = useState(true);
  const [amount, setAmount] = useState(creditsToUse);
  const [inputVisible, setInputVisible] = useState(false);
  const [amountError, setAmountError] = useState('');

  const creditAmount = currentUser.credits;

  useEffect(() => {
    const digitRegex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
    if (amount !== '' && !digitRegex.test(amount)) {
      setAmountError(
        `Please insert a valid amount between 0.01 and ${milestone.amount}`
      );
    } else if (Number(amount) > Number(milestone.amount)) {
      setAmountError(
        `Please specify an amount equal or less than the milestone amount ($${
          milestone.amount
        })`
      );
    } else if (Number(amount) > Number(creditAmount)) {
      setAmountError(
        'Sorry, please choose an amount equal or less than your credits'
      );
    } else if (Number(amount) < 0.01 && amount !== '') {
      setAmountError('Please enter a number greater than 0');
    } else if (
      Number(milestone.amount - amount) > 0 &&
      Number(milestone.amount - amount) < 0.5
    ) {
      setAmountError(
        'Sorry, the minimum non-zero amount that can be charged is $0.50'
      );
    } else if (amountError) {
      setAmountError('');
    }
  }, [amount]);

  useEffect(() => {
    // Reset credit settings when milestone changed
    let recommendedAmount = Number(
      Math.min(creditAmount, Number(milestone.amount))
    );

    const amountToBeCharged = milestone.amount - recommendedAmount;

    // Minimum charge accepted by stripe is $0.50
    if (amountToBeCharged > 0 && amountToBeCharged < 0.5) {
      recommendedAmount -= Number(0.5 - amountToBeCharged);
    }

    setCreditsToUse(recommendedAmount.toFixed(2));
    setAmount(recommendedAmount.toFixed(2));
    setApplied(true);
  }, [milestone.id]);

  return (
    <div className="aloa-credits-container">
      <Checkbox
        checked={applied}
        onChange={e => {
          const { checked } = e.target;
          setApplied(checked);

          if (checked) {
            setCreditsToUse(amount);
          } else {
            setCreditsToUse(0);
          }
        }}
      >
        <span>
          Apply Aloa Credits ({currencyFormat(creditAmount, 2)} Available)
        </span>
      </Checkbox>
      {applied && (
        <>
          <br />
          <span className="aloa-credits-amount-applied">
            {`${currencyFormat(creditsToUse, 2)} applied`}
          </span>
          <span hidden={inputVisible}> - </span>
          <span
            className="aloa-credits-edit-amount"
            hidden={inputVisible}
            onClick={() => setInputVisible(!inputVisible)}
          >
            Edit Amount
          </span>
          {inputVisible && (
            <div style={{ marginTop: '10px' }}>
              <Form style={{ marginBottom: '10px' }}>
                <Form.Item
                  style={{ marginBottom: '-10px' }}
                  validateStatus={amountError ? 'error' : ''}
                  help={amountError}
                >
                  $
                  <Input
                    className="aloa-credits-input"
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                  />
                  <Button
                    type="primary"
                    disabled={amountError}
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      setInputVisible(!inputVisible);
                      setCreditsToUse(amount);
                    }}
                  >
                    {'Apply'}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </>
      )}
    </div>
  );
}
