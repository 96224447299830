import React from 'react';
import { Link } from 'react-router-dom';
import { BankOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Radio, Popover, Space } from 'antd';

const RadioGroup = Radio.Group;

export default function SelectPaymentMethod({
  currentUser,
  paymentMethod,
  setPaymentMethod,
  hideAppDialog,
  creditCardEligible,
  paymentMethodRequired,
}) {
  return (
    <RadioGroup
      value={paymentMethodRequired ? paymentMethod : null}
      onChange={(event) => setPaymentMethod(event.target.value)}
    >
      <Space direction="vertical">
        {currentUser.payment_methods.map((method) => (
          <Radio
            style={{ width: '100%' }}
            key={method.source_id}
            value={method.source_id}
            disabled={
              !method.verified ||
              (method.type === 'card' && !creditCardEligible) ||
              !paymentMethodRequired
            }
          >
            {labelForMethod(method, hideAppDialog, creditCardEligible)}
          </Radio>
        ))}
      </Space>
    </RadioGroup>
  );
}

/* TODO - Very hacky to pass hideAppDialog all the way here
  => think of something better
*/
const labelForMethod = (method, hideAppDialog, creditCardEligible) => {
  const iconStyle = { marginLeft: '10px' };
  if (method.type === 'card') {
    if (!creditCardEligible) {
      return (
        <Popover content={creditCardNotEligibleContent}>
          {`${method.brand} •••• ${method.last4}`}
          <CreditCardOutlined style={iconStyle} />
        </Popover>
      );
    }
    return (
      <>
        {`${method.brand} •••• ${method.last4}`}
        <CreditCardOutlined style={iconStyle} />
      </>
    );
  }

  if (method.type === 'sepa_debit') {
    return (
      <>
        {`${method.brand} •••• ${method.last4}`}
        <CreditCardOutlined style={iconStyle} />
      </>
    );
  }

  if (method.verified) {
    return (
      <>
        {`${method.bank_inst} •••• ${method.last4}`}
        <BankOutlined style={iconStyle} />
      </>
    );
  }
  return (
    <>
      {`${method.bank_inst} •••• ${method.last4} (pending)`}
      <BankOutlined style={iconStyle} />
      <Link
        onClick={hideAppDialog}
        style={{ fontSize: '14px', float: 'right' }}
        to="/account/verify"
      >
        Verify
      </Link>
    </>
  );
};

const creditCardNotEligibleContent = (
  <div>Credit cards are not eligible for payments more than $3,000</div>
);
